import React from 'react'
import { Container, Header, Accordion, Icon, Grid, Table, List } from 'semantic-ui-react'
import Moment from 'react-moment';

export default class Detail extends React.PureComponent {

  constructor(props) {
    super(props)

    this.bookingInfo = this.props.bookingSelected
    this.state = {
      viewParticipantListAs: 'list' // or 'table'
    }
  }

  calculateSubTotal = (promo, total, extra) => {
    let subtotal = total + extra - promo
    return Number(subtotal).toLocaleString('en')
  }

  buildQnaDOM = (qnaItem) => {
    return (
        <List.Item>
          <List.Header>{`- ${qnaItem.questionContent}`}</List.Header><i>{qnaItem.answerContent}</i>
        </List.Item>
    )
  }

  renderQnAEachParticipant = (data) => {
    let qnaLstDomRender = []
    if (data) {
      for (let item in data) {
        qnaLstDomRender.push(this.buildQnaDOM(data[item]))
      }
    }
    return qnaLstDomRender
  }

  renderParticipantsData(lstParticipants) {

    return (
      <Accordion fluid styled>
        {
          lstParticipants.map((item, index) => {
            let releaseFormDate = item.inputReleaseFormDate === null ? '' : <Moment format="MMM DD, YYYY hh:mm a" date={item.inputReleaseFormDate} />
            let qna = this.renderQnAEachParticipant(item.answerQuestionDetails)

            return (
              <React.Fragment key={item.id}>
                <Accordion.Title key={item.id} active={true} index={item.id}>
                  <Icon name='dropdown' />
                  Participant {index + 1}
                </Accordion.Title>
                <Accordion.Content active={true}>
                  <Grid stackable>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Table className='table-list' basic='very' compact='very'>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Full Name
                              </Table.Cell>
                              <Table.Cell>
                                {item.fullName}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Date of Birth
                              </Table.Cell>
                              <Table.Cell>
                                <Moment format="MMM DD, YYYY" date={new Date(item.dob)} />
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Gender
                              </Table.Cell>
                              <Table.Cell>
                                {item.gender}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Passport
                              </Table.Cell>
                              <Table.Cell>
                                {item.passport}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Nationality
                              </Table.Cell>
                              <Table.Cell>
                                {item.nationality}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                WhatSapp
                              </Table.Cell>
                              <Table.Cell>
                                {item.phone}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Email
                              </Table.Cell>
                              <Table.Cell>
                                {item.email}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Other
                              </Table.Cell>
                              <Table.Cell>
                                <List>
                                  {qna}
                                </List>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>

                      </Grid.Column>
                      <Grid.Column>
                        <Table className='table-list' basic='very' compact='very'>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Date input release form
                              </Table.Cell>
                              <Table.Cell>
                                {releaseFormDate}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Emergency Contact Name
                              </Table.Cell>
                              <Table.Cell>
                                {item.emergencyContactName}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Emergency Contact Email
                              </Table.Cell>
                              <Table.Cell>
                                {item.emergencyContactEmail}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Emergency Contact Number
                              </Table.Cell>
                              <Table.Cell>
                                {item.emergencyContactNumber}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Insurance Name
                              </Table.Cell>
                              <Table.Cell>
                                {item.insuranceName}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Insurance Contact Number
                              </Table.Cell>
                              <Table.Cell>
                                {item.insuranceContactNumber}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Insurance Policy Number
                              </Table.Cell>
                              <Table.Cell>
                                {item.insurancePolicyNumber}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Accordion.Content>
              </React.Fragment>
            )
          })
        }
      </Accordion>
    );
  }

  renderAnswerQuestionSelectedList = () => {
    return this.bookingInfo.answerQuestionSelectedList.map((item) => {
      return (
        <Table.Row>
          <Table.Cell className='tablel-row-title'>
            {item.name} x {item.count}
          </Table.Cell>
          <Table.Cell className='text-align-right'>
            {Number(item.total).toLocaleString('en')}
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  render() {
    let QnARowPayment = this.renderAnswerQuestionSelectedList()
    return (
      <div key='detail-booking-participant'>
        <Header as='h3' dividing>
          Booking Information
        </Header>
        <Container>
          <Grid stackable>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Header as='h4'>
                  Tour Information
                </Header>
                <Table className='table-list' basic='very' compact='very'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Title
                      </Table.Cell>
                      <Table.Cell>
                        {this.bookingInfo.tourDetail.tour.title}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Duration
                      </Table.Cell>
                      <Table.Cell>
                        {this.bookingInfo.tourDetail.tour.duration}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Duration Text
                      </Table.Cell>
                      <Table.Cell>
                        {this.bookingInfo.tourDetail.tour.durationText}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Departure Time
                      </Table.Cell>
                      <Table.Cell>
                        <Moment format="hh:mm a" date={this.bookingInfo.tourDetail.tour.departureTime}></Moment>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Return Time
                      </Table.Cell>
                      <Table.Cell>
                        <Moment format="hh:mm a" date={this.bookingInfo.tourDetail.tour.returnTime}></Moment>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Header as='h4'>
                  Booking Information Detail
                </Header>
                <Table className='table-list' basic='very' compact='very'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Booking Number
                      </Table.Cell>
                      <Table.Cell>
                        {this.bookingInfo.bookingNumber}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Customer booking at
                      </Table.Cell>
                      <Table.Cell>
                        <Moment format="MMM DD, YYYY hh:mm a" date={this.bookingInfo.createdDate}></Moment>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Participants
                      </Table.Cell>
                      <Table.Cell>
                        {this.bookingInfo.bookingNumParticipant} guests
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Start Date
                      </Table.Cell>
                      <Table.Cell>
                        <Moment format="MMM DD, YYYY" date={this.bookingInfo.tourDetail.dateBooking} /> <Moment format="hh:mm a" date={this.bookingInfo.tourDetail.tour.departureTime}></Moment>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        End Date
                      </Table.Cell>
                      <Table.Cell>
                        <Moment format="MMM DD, YYYY" date={this.bookingInfo.tourDetail.dateBookingEnd} /> <Moment format="hh:mm a" date={this.bookingInfo.tourDetail.tour.returnTime}></Moment>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Header as='h4'>
                  Payment Information Detail
                </Header>
                <Table className='table-list' basic='very' compact='very'>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        {Number(this.bookingInfo.tourPrice).toLocaleString('en')}  VND/ person x <strong>{this.bookingInfo.bookingNumParticipant}</strong>
                      </Table.Cell>
                      <Table.Cell className='text-align-right'>
                        {Number(this.bookingInfo.bookingPrice).toLocaleString('en')}
                      </Table.Cell>
                    </Table.Row>
                    {QnARowPayment}
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Promo code {this.bookingInfo.promoCode} (VND)
                      </Table.Cell>
                      <Table.Cell className='text-align-right'>
                        {` - ${Number(this.bookingInfo.promoPrice).toLocaleString('en')}`}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Sub-Total (VND)
                      </Table.Cell>
                      <Table.Cell className='text-align-right'>
                        {this.calculateSubTotal(this.bookingInfo.promoPrice, this.bookingInfo.bookingPrice, this.bookingInfo.totalExtraFee)}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        VAT (10%)
                      </Table.Cell>
                      <Table.Cell className='text-align-right'>
                        {Number(this.bookingInfo.vatPrice).toLocaleString('en')}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className='tablel-row-title'>
                        Total Payment (VND)
                      </Table.Cell>
                      <Table.Cell className='text-align-right'>
                        {Number(this.bookingInfo.total).toLocaleString('en')}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Header as='h3' dividing>
          Participants list
        </Header>
        <Container className='list-participants-booking'>
          {this.renderParticipantsData(this.bookingInfo.bookingParticipants)}
        </Container>
      </div>
    )
  }
}
