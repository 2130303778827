import React from "react";
import { Header, Button, Grid, Icon } from "semantic-ui-react";
import { ContentForm } from "../Tour/ContentForm";
import "../Tour/Tour.scss";

export default class FAQv2 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      faqData: null,
    };
  }

  componentDidMount() {
    fetch("api/Content/GetFAQContent")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ faqData: data, loading: false });
      });
  }

  onProvideDateForSave = () => {
    let faqData = this.state.faqData;

    // build object post to server
    var payload = {
      text: JSON.stringify(this.state.faqData.contents),
      type: "FAQ",
    };

    return payload;
  };

  onHandleSave = () => {
    let payload = this.onProvideDateForSave();

    // post data to api for edit
    fetch(`api/Content/${this.state.faqData.id}`, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "same-origin",
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((response) => {
        alert("update content success !");
      })
      .catch((error) => alert(error));
  };

  onUpdateContents = (content) => {
    // TODO: update contents
    let { contents } = this.state.faqData;

    if (!contents) {
      contents.push(content);
    } else {
      // find data in existing tour data
      let updatedItem = contents.findIndex(function (item) {
        return item.id === content.id;
      });

      if (updatedItem === -1) {
        contents.push(content);
      } else {
        contents[updatedItem] = content;
      }
    }

    this.setState(
      {
        faqData: {
          ...this.state.faqData,
          contents: contents,
        },
      }
    );
  };

  removeContents = (contentsUpdated) => {
    // TODO: remove contents
    let { contents } = this.state.faqData;
    contents = contentsUpdated;
    this.setState({
      faqData: {
        ...this.state.faqData,
        contents: contents,
      },
    });
  };

  renderContent = () => {
    return (
      <div className="ui form">
        <Grid divided="vertically">
          <Grid.Row className="control-area-row">
            <Grid.Column>
              <Button
                color="teal"
                icon
                labelPosition="left"
                onClick={this.onHandleSave}
              >
                Save
                <Icon name="save" />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" dividing>
                FAQ
              </Header>              
              <ContentForm
                contents={this.state.faqData.contents}
                onUpdateContents={this.onUpdateContents}
                removeContents={this.removeContents}
              ></ContentForm>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      this.renderContent()
    );
    return <div>{contents}</div>;
  }
}
