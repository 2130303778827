import React from 'react'
import { Redirect } from 'react-router-dom'
import { Grid, Container, Button, Segment, Header, Form, Modal } from "semantic-ui-react";
import BookingParticipant from './BookingParticipant'
import BookingBillingContact from './BookingBillingContact'
import BookingBillingInfo from './BookingBillingInfo'
import _ from 'lodash'
import { bookingStep } from '../../../../data/enum'
import Moment from 'react-moment'
import Utils from '../../../Common/Utils'

export class BookingDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      dataParticipant: this.props.dataParticipant === undefined ? [] : this.props.dataParticipant,
      billingContactInfo: this.props.billingContactInfo === undefined ? null : this.props.billingContactInfo,
      bookingInfo: this.props.bookingInfo === undefined ? null : this.props.bookingInfo,
      promoPrice: this.props.promoPrice === undefined ? 0 : this.props.promoPrice,
      promoCode: this.props.promoCode === undefined ? '' : this.props.promoCode,
      redirect: false,
      url: '',
      loading: false,
      hasErrorAlert: false,
      errorMessage: ''
    }
  }

  openErrorModal = (mess) => this.setState({ ...this.state, hasErrorAlert: true, errorMessage: mess })
  closeErrorModal = () => this.setState({ hasErrorAlert: false, errorMessage: '' })

  setRedirect = (url) => {
    this.setState({
      redirect: true,
      url: url,
      loading: false
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.url} />
    }
  }

  onHandleInputBookingParticipant = (data, qna) => {
    let { dataParticipant } = this.state
    let indexPart = _.findIndex(dataParticipant, ['key', data.key])
    let dropDownQnAHasFee = _.filter(qna, function (o) { return o.isDropdown && (o.fee !== null && o.fee !== undefined && !isNaN(o.fee) && o.fee > 0) })

    if (indexPart === -1) {
      data = { ...data, qna: qna, dropDownQnAHasFee: dropDownQnAHasFee }
      dataParticipant.push(data)
    } else {
      let item = dataParticipant[indexPart]
      item.qna = qna
      item.dropDownQnAHasFee = dropDownQnAHasFee
      item.dateOfBirth = data.dateOfBirth !== '' ? new Date(data.dateOfBirth) : ''
      item.email = data.email
      item.fullName = data.fullName
      item.gender = data.gender
      item.nationality = data.nationality
      item.passport = data.passport
      item.phone = data.phone
    }

    this.setState({
      ...this.state,
      dataParticipant: [...dataParticipant]
    })
  }

  onHandleInputPromoPrice = (data, error) => {
    this.setState({
      promoCode: data.promoCode,
      promoPrice: data.promoPrice,
      hasErrorAlert: error,
      errorMessage: error === true ? 'Invalid promo code!' : ''
    })
  }

  onHandleInputBookingBilling = (data) => {
    this.setState({ billingContactInfo: data })
  }

  validateDataParticipant = () => {
    let { dataParticipant } = this.state

    let error = {
      isValidOK: true,
      errorMess: ''
    }

    dataParticipant.forEach(item => {
      if (item.dateOfBirth === undefined || item.dateOfBirth === '') {
        error = {
          isValidOK: false,
          errorMess: 'Please input date of birth!'
        }
        return error
      }

      else if (item.email === undefined || item.email === '') {
        error = {
          isValidOK: false,
          errorMess: 'Please input email!'
        }
        return error
      }

      else if (item.fullName === undefined || item.fullName === '') {
        error = {
          isValidOK: false,
          errorMess: 'Please input fullname!'
        }
        return error
      }
      else if (item.gender === undefined || item.gender === '') {
        error = {
          isValidOK: false,
          errorMess: 'Please select gender!'
        }
        return error
      }

      else if (item.nationality === undefined || item.nationality === '') {
        error = {
          isValidOK: false,
          errorMess: 'Please select nationality!'
        }
        return error
      }

      else if (item.passport === undefined || item.passport === '') {
        error = {
          isValidOK: false,
          errorMess: 'Please input passport!'
        }
        return error
      }

      else if (Utils.checkOnlyLetterAndNumber(item.passport) === false) {
        error = {
          isValidOK: false,
          errorMess: 'Passport just only alphabet letter and number!'
        }
        return error
      }

      else {
        let filterQnARequired = _.filter(this.props.tourData.tourQuestions, function (o) { return o.isRequired })
        for (let i = 0; i < filterQnARequired.length; i++) {

          let itemQnA = filterQnARequired[i]

          if (itemQnA.inputType === 'Dropdown') {
            if (item.qna[itemQnA.id] === undefined) {
              error = {
                isValidOK: false,
                errorMess: `please select ${itemQnA.questionContent}`
              }

              return error
            }
          }
          else if (itemQnA.inputType === 'Input') {
            if (item.qna[itemQnA.id] === undefined) {
              error = {
                isValidOK: false,
                errorMess: `please input ${itemQnA.questionContent}`
              }

              return error
            }

            if (item.qna[itemQnA.id].value === '' || item.qna[itemQnA.id].value === null) {
              error = {
                isValidOK: false,
                errorMess: `please input ${itemQnA.questionContent}`
              }

              return error
            }
          }
          else if (itemQnA.inputType === 'Checkbox') {
            if (item.qna[itemQnA.id] === undefined) {
              error = {
                isValidOK: false,
                errorMess: `please check on ${itemQnA.questionContent}`
              }

              return error
            }

            if (item.qna[itemQnA.id].value === '' || item.qna[itemQnA.id].value === null || item.qna[itemQnA.id].value === false) {
              error = {
                isValidOK: false,
                errorMess: `please check on ${itemQnA.questionContent}`
              }

              return error
            }
          }
        }
      }
    })

    return error
  }

  onHandleClickBooking = (e) => {
    e.preventDefault()

    let isOKForBooking = this.validateDataParticipant()

    if (isOKForBooking.isValidOK) {

      this.setState({ loading: true })
      this.props.onHandleChangeStep(bookingStep.CONFIRM, this.state)
    } else {
      alert(isOKForBooking.errorMess)
    }
  }

  onHandleBackToBooking = () => {
    this.props.onHandleChangeStep(bookingStep.SELECT_TOUR_DETAIL, this.props.bookingInfo)
  }

  renderBookingBillingInfo = (bookingInfo, tourData) => {

    // create data for booking billing from data participant
    let objectDataExtra = []
    this.state.dataParticipant.forEach(item => {
      objectDataExtra = [...objectDataExtra, ...item.dropDownQnAHasFee]
    })

    let totalExtraFee = 0

    let result = [];
    objectDataExtra.reduce(function (res, value) {
      if (!res[value.value]) {
        res[value.value] = {
          fee: value.fee,
          total: 0,
          questionContent: value.questionContent,
          answerContent: value.name,
          count: 0,
          questionId: value.questionId
        };
        result.push(res[value.value])
      }
      res[value.value].total += value.fee
      res[value.value].count += value.count ? value.count : 1
      return res;
    }, {});

    if (result.length > 0) {
      for (let i = 0; i < result.length; i++) {
        totalExtraFee += result[i].total
      }
    }

    return (<BookingBillingInfo promoCode={this.state.promoCode} promoPrice={this.state.promoPrice} bookingInfo={bookingInfo} tourData={tourData} totalExtraFee={totalExtraFee} extraList={result} onHandleInputPromoPrice={this.onHandleInputPromoPrice}></BookingBillingInfo>)
  }

  render() {
    let { bookingInfo, tourData, billingContactInfo } = this.props
    let startDate = new Date(bookingInfo.selectedTourDetail.dateBooking)
    let endDate = new Date(bookingInfo.selectedTourDetail.dateBookingEnd)
    let startTime = new Date(tourData.departureTime)
    let endTime = new Date(tourData.returnTime)
    let bookingBillingInfoRender = this.renderBookingBillingInfo(this.state.bookingInfo, tourData)
    return (
      <div>
        {this.renderRedirect()}
        <div className='booking-detail-information'>
          <Grid columns={2} stackable>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form onSubmit={this.onHandleClickBooking}>
                  <Segment color='olive' className='summary-area'>
                    <Header as='h4'>{tourData.title}</Header>
                    <Container className='price-area'>
                      <span className='item duration'><strong>Start date: </strong><Moment format="LL">{startDate}</Moment> <Moment format="LT">{startTime}</Moment></span>
                      <span className='item duration'><strong>End date: </strong><Moment format="LL">{endDate}</Moment> <Moment format="LT">{endTime}</Moment></span>
                      <span className='item duration'><strong>Duration: </strong>{tourData.durationText}</span>
                      <button size='mini' className="ui icon left labeled button" onClick={this.onHandleBackToBooking}>
                        <i aria-hidden="true" className="arrow left icon"></i>
                        Edit booking
                      </button>
                    </Container>
                  </Segment>
                  <BookingParticipant {...this.state} tourData={tourData} bookingSelectedDate={this.state.bookingInfo.dateSelected} numParticipants={bookingInfo.numParticipants} onHandleInputBookingParticipant={this.onHandleInputBookingParticipant} openErrorModal={this.openErrorModal}></BookingParticipant>
                  <BookingBillingContact bookingInfo={bookingInfo} billingContactInfo={billingContactInfo} onHandleInputBookingBilling={this.onHandleInputBookingBilling}></BookingBillingContact>
                  <Container className='booking-fixed-area'>
                    <Button positive size='big' className={`full-width-btn ${this.state.loading ? 'loading disabled ' : ''}`} type='submit'>BOOK NOW <br /> Please enter all required fields</Button>
                  </Container>
                </Form>
              </Grid.Column>
              <Grid.Column width={4}>
                {bookingBillingInfoRender}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Modal size={'mini'} open={this.state.hasErrorAlert} onClose={this.closeErrorModal} className='alert-message-area' closeOnDimmerClick={false}>
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <p>{this.state.errorMessage}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={this.closeErrorModal}>OK</Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}
