import React from 'react'
import { Header, Grid, Segment, Form, Button, Divider, Dimmer, Loader } from "semantic-ui-react";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash'

export class Booking extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bookingInfo: this.props.bookingInfo,
      canBook: false,
      loading: false,
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.state.bookingInfo)) {
      let tourPrice = this.props.tourData.price
      this.setState(
        {
          ...this.state,
          bookingInfo: {
            numParticipants: 1,
            totalPrice: tourPrice
          }
        }
      )
    } else {
      let { bookingInfo } = this.state
      let canBook = this.validateBookingInput(bookingInfo.dateSelected, bookingInfo.numParticipants, bookingInfo.isAgreeTermsAndConditions, bookingInfo.availableSlot)
      this.setState({
        canBook: canBook,
      })
    }
  }

  validateBookingInput = (date, numInput, availableSlot) => {
    let isValid = false
    if ((date === null || date === undefined) ||
      (numInput === null || numInput === undefined || isNaN(numInput)) ||
      (availableSlot === null || availableSlot === undefined)) {
      isValid = false
    } else if (date !== null && numInput > 0 && availableSlot !== null) {
      if (numInput > availableSlot) {
        isValid = false
      } else {
        isValid = true
      }
    } else {
      isValid = false
    }

    return isValid
  }

  onCalendarChange = (date) => {
    let { tourDetails } = this.props.tourData
    let dateSelected = new Date(date)
    let availableSlot = 0
    let { bookingInfo } = this.state
    let canBook = false
    let selectedTourDetail = null

    // get tour detail of date selected
    tourDetails.forEach((item) => {
      let dateBooking = new Date(item.dateBooking)
      if (dateSelected.getDate() === dateBooking.getDate() 
        && dateSelected.getMonth() === dateBooking.getMonth() 
        && dateSelected.getFullYear() === dateBooking.getFullYear()) {
        selectedTourDetail = item
      }
    })

    if (selectedTourDetail) {
      this.setState({ loading: true })
      //get current available slot in db
      fetch(`api/Tour/CheckAvailableSlot/${selectedTourDetail.tourId}/Detail/${selectedTourDetail.id}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: 'same-origin'
      })
        .then(response => response.json())
        .then(response => {
          availableSlot = response.data.currentAvailableSlot

          bookingInfo.dateSelected = date
          bookingInfo.availableSlot = availableSlot
          bookingInfo.selectedTourDetail = selectedTourDetail
          bookingInfo = { ...bookingInfo }

          canBook = this.validateBookingInput(date, bookingInfo.numParticipants, availableSlot)

          this.setState({
            bookingInfo: bookingInfo,
            canBook: canBook,
            loading: false
          })
        });
    }
  }

  onHandleChange = (event) => {
    let tourPrice = this.props.tourData.price
    let target = event.target
    let name = target.name
    let value = target.value
    let canBook = false
    let bookingInfo = { ...this.state.bookingInfo }
    let totalPrice = this.state.totalPrice

    if (name === 'numParticipants') {
      totalPrice = name === 'numParticipants' ? tourPrice * value : this.state.totalPrice
    }

    canBook = this.validateBookingInput(bookingInfo.dateSelected, value, bookingInfo.availableSlot)

    if (name === 'numParticipants') {
      if (isNaN(value)) {
        totalPrice = tourPrice
        value = 1
      } else if (value < 1) {
        value = 1
        totalPrice = tourPrice
      } else {
        value = Number(value)
      }
    }

    bookingInfo[name] = value
    bookingInfo = { ...bookingInfo, totalPrice: totalPrice }

    this.setState({
      ...this.state,
      bookingInfo: bookingInfo,
      canBook: canBook
    })

  }

  onHandleSelectBookingInformation = (event) => {
    event.preventDefault()
    this.props.onHandleSelectBookingInformation(this.state.bookingInfo)
  }

  onChangeParticipant = (event, num) => {
    event.preventDefault()

    let tourPrice = this.props.tourData.price
    let totalPrice = this.state.totalPrice
    let bookingInfo = { ...this.state.bookingInfo }
    let currentNumPart = bookingInfo.numParticipants
    let canBook = false

    if (currentNumPart + num >= 1) {
      currentNumPart = currentNumPart + num
      canBook = this.validateBookingInput(bookingInfo.dateSelected, currentNumPart, bookingInfo.availableSlot)

      bookingInfo['numParticipants'] = currentNumPart
      totalPrice = tourPrice * currentNumPart

      bookingInfo = { ...bookingInfo, totalPrice: totalPrice }

      this.setState({
        ...this.state,
        bookingInfo: bookingInfo,
        canBook: canBook
      })
    }
  }

  renderAvalilableSlot = (availableSlot) => {
    if (availableSlot) {
      if (availableSlot < 3) {
        return (`Only ${availableSlot} left`)
      } else {
        return availableSlot
      }
    } else {
      return ''
    }
  }

  renderLoading = () => {
    if (this.state.loading) {
      return (<Dimmer active inverted>
        <Loader content='Loading' />
      </Dimmer>)
    } else {
      return <div></div>
    }
  }

  render() {
    let tourData = this.props.tourData

    // get date booking list 
    let tourDateBookingList = []
    if (tourData.tourDetails && tourData.tourDetails.length > 0) {
      tourData.tourDetails.forEach((item) => {
        tourDateBookingList.push(new Date(item.dateBooking))
      })
    }

    let availableSlotRender = this.renderAvalilableSlot(this.state.bookingInfo.availableSlot)

    return (
      <div className='booking-area'>
        <Header attached='top' className='booking-area-header'>Start Booking Now</Header>
        <Segment className='booking-area-content' attached>
          {this.renderLoading()}
          <div className="ui form">
            <Form.Field required>
              <label>Choose a Date</label>
              <div className="customDatePickerWidth">
                <DatePicker className='react-datepicker-manager'
                  inline
                  selected={this.state.bookingInfo.dateSelected}
                  onChange={this.onCalendarChange}
                  includeDates={tourDateBookingList}
                />
              </div>
            </Form.Field>
            <Form.Field required>
              <label>Enter Number of Participants</label>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <span className='booking-quantity-label'>Quantity (VND)</span>
                    <span className='booking-quantity-price'>{Number(tourData.price).toLocaleString('en')}</span>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <div className="fields participant-input">
                      <div className="field"><Button icon='minus' onClick={(e) => this.onChangeParticipant(e, -1)} /></div>
                      <div className="field"><input name='numParticipants' required ref='numParticipants' onChange={this.onHandleChange} value={this.state.bookingInfo.numParticipants ? this.state.bookingInfo.numParticipants : 1} /></div>
                      <div className="field"><Button icon='plus' onClick={(e) => this.onChangeParticipant(e, 1)} /></div>
                    </div>

                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <Form.Field required>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label className='booking-quantity-label'>Available</label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <span className='booking-total-slot'>{availableSlotRender}</span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <Divider />
            <Form.Field>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label className='booking-total-label'>Total (VND)</label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <span className='booking-total-price'>{isNaN(this.state.bookingInfo.totalPrice) ? 0 : Number(this.state.bookingInfo.totalPrice).toLocaleString('en')}</span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>            
            <Grid stackable>
              <Grid.Column textAlign="center">
                <Button type='submit' primary disabled={!this.state.canBook} onClick={this.onHandleSelectBookingInformation}>CREATE BOOKING & INPUT PARTICIPANTS INFORMATION</Button>
              </Grid.Column>
            </Grid>
          </div>
        </Segment>        
      </div>
    )
  }
}
