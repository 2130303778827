import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { authenticationService } from "./../../_services/authentication.service";

import "./_Login.scss";

class Login extends Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }

    this.state = {
      username: "",
      password: "",
    };
  }

  onHandleInput = (event) => {
    let target = event.target;
    let name = target.name;
    let value = target.value;

    this.setState({
      [name]: value,
    });
  };

  onHandleLogin = (event) => {
    event.preventDefault();

    let { username, password } = this.state;

    authenticationService.login(username, password).then(
      (user) => {
        const { from } = this.props.location.state || {
          from: { pathname: "/Admin/Dashboard" },
        };
        this.props.history.push(from);
      },
      (error) => {
        alert("Login failure!");
      }
    );
  };

  render() {
    return (
      <div className="App login-page">
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              <img
                src={require("../../assets/Images/logo.png")}
                alt="logo"
                className="image"
              />{" "}
            </Header>
            <Form size="large" onSubmit={this.onHandleLogin}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  name="username"
                  required
                  onChange={this.onHandleInput}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  name="password"
                  required
                  onChange={this.onHandleInput}
                />
                <Button color="teal" fluid size="large" type="submit">
                  Login
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Login;
