import React from 'react';
import { NavMenu } from './NavMenu';

import './LayoutAdmin.scss';

export class LayoutAdmin extends React.PureComponent{
  static displayName = LayoutAdmin.name;

  render() {
    return (
      <div className="AppAdmin">
          <NavMenu />
          <div className='adm-content'>
            {this.props.children}
          </div>
      </div>
    );
  }
}
