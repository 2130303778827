import React from 'react'
import ContentInpupt from './ContentInpupt';

export default class PageContentConfig extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      configs: [],
    };
  }

  componentDidMount() {
    let payload = [
      'HotLine', 'Address'
    ]

    fetch(`api/Content/GetConfigContent`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'same-origin',
      body: JSON.stringify(payload)
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 400) {
          this.setState({ loading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          this.setState({ loading: false }, () => {
            this.setState({ loading: false, configs: response.body });
          });
        }
      })
      .catch(error => alert(error));
  }

  renderContent = () => {
    let { configs } = this.state
    return (
      <>
        {
          configs.map(element => {
            return <ContentInpupt key={element.id} id={element.id} type={element.type} title={element.title} description={element.description} content={element.text}></ContentInpupt>
          })
        }
      </>
    )
  }

  render() {
    console.log(this.state.configs)
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderContent();
    return (
      <div>
        {contents}
      </div>
    )
  }
}
