import React, { Component } from 'react'
import { Grid, Form, Select, Header, Segment, Dimmer, Loader, Container } from 'semantic-ui-react'
import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';
import { Booking } from './CreateComponent/Booking'
import { BookingDetail } from './CreateComponent/BookingDetail'
import { BookingConfirm } from './CreateComponent/BookingConfirm'
import { bookingStep } from '../../../../src/data/enum'

export default class Create extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      selectedTourIdFilter: '',
      listTour: [],
      listTourDropdownInput: [],
      bookingInfo: null,
      tourData: null,
      currentBookingStep: bookingStep.SELECT_TOUR_DETAIL,
    }
  }

  componentDidMount() {
    fetch('api/Tour/AmGetToursList', {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(data => {
        let listTour = []
        let listTourDropdownInput = []

        if (data && data.length > 0) {
          data.forEach(element => {
            let tourItem = {
              key: element.id,
              text: element.title,
              value: element.id
            }
            listTour.push(tourItem)
            listTourDropdownInput.push(tourItem)
          });
        }

        this.setState({
          listTour: listTour,
          listTourDropdownInput: listTourDropdownInput,
          loading: false,
        });
      });
  }

  getTourData = (tourId) => {
    fetch(`api/Tour/GetTourById/${tourId}`, {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(respone => {
        this.setState({ tourData: respone.data, loading: false });
      });
  }

  onHandleDropdownSelect = (event, data) => {
    var name = data.name
    var value = data.value

    if (name === 'tourfilter') {

      if (value !== this.state.selectedTourIdFilter) {
        this.setState({
          selectedTourIdFilter: value,
          loading: true,
        })

        //this.resetDataInput()
        this.getTourData(value)
      }
    }
  }

  onHandleSelectBookingInformation = (data) => {
    let { currentBookingStep } = this.state
    if (data) {
      currentBookingStep = bookingStep.INPUT_BOOKING_DETAIL
    }
    this.setState({ bookingInfo: data, currentBookingStep: currentBookingStep })
  }

  onHandleChangeStep = (step, data) => {
    if (data !== undefined && data !== null) {
      if (step === bookingStep.SELECT_TOUR_DETAIL) {
        this.setState({ currentBookingStep: step, bookingInfo: data })
      } else if (step === bookingStep.INPUT_BOOKING_DETAIL) {
        this.setState({ currentBookingStep: step, ...data.bookingInfo.bookingInfo })
      } else if (step === bookingStep.CONFIRM) {
        this.setState({ ...data, currentBookingStep: step })
      }

    } else {
      this.setState({ currentBookingStep: step })
    }
  }

  renderTourInformation = (tourData) => {
    if (this.state.currentBookingStep === bookingStep.SELECT_TOUR_DETAIL) {
      // step 1 view information       
      if (tourData != null) {
        return (
          <Grid.Row columns='3'>
            <Grid.Column>
              <Header as='h3' dividing>Create Booking Information</Header>
              <Booking
                key='tour-detail-booking-information'
                tourData={tourData}
                bookingInfo={this.state.bookingInfo === null ? {} : this.state.bookingInfo}
                onHandleSelectBookingInformation={this.onHandleSelectBookingInformation}>
              </Booking>
            </Grid.Column>
          </Grid.Row>
        )
      }
      else {
        return (<></>)
      }
    } else if (this.state.currentBookingStep === bookingStep.INPUT_BOOKING_DETAIL) {
      // step 2 input detail information for booking tour 
      return (
        <Grid.Row >
          <Grid.Column>
            <Header as='h3' dividing>Create Booking Participants Information</Header>
            <BookingDetail {...this.state} onHandleChangeStep={this.onHandleChangeStep}></BookingDetail>
          </Grid.Column>
        </Grid.Row>
      )
    } else if (this.state.currentBookingStep === bookingStep.CONFIRM) {
      // step 3 confirm booking information
      return (
        <Grid.Row >
          <Grid.Column>
            <Header as='h3' dividing>Confirm booking information</Header>
            <BookingConfirm {...this.state} onHandleChangeStep={this.onHandleChangeStep}></BookingConfirm>
          </Grid.Column>
        </Grid.Row>

      )
    }
    else {
      return ''
    }
  }

  render() {
    let contents = this.state.loading
      ? <Container fluid>
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      </Container>
      : this.renderTourInformation(this.state.tourData)

    return (
      <div key='adm-create-booking-screen' className='create-booking-screen'>
        <Grid divided='vertically'>
          <Grid.Row className='control-area-row'>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='3'>
            <Grid.Column>
              <div className='ui form'>
                <Form.Field>
                  <label>Select tour to create booking</label>
                  <Select
                    key='booking-tourfilter'
                    name='tourfilter'
                    onChange={this.onHandleDropdownSelect}
                    options={this.state.listTour}
                    placeholder='Choose an option'
                    selection
                  />
                </Form.Field>
              </div>
            </Grid.Column>
          </Grid.Row>
          {contents}
        </Grid>

      </div>
    )
  }
}
