import React, { Component } from "react";
import {
  Button,
  Icon,
  Form,
  Header,
  Grid,
  Segment,
  Progress,
  Image,
} from "semantic-ui-react";
import { Editor } from "@tinymce/tinymce-react";
import { Link } from "react-router-dom";
import { Uploader, UploadField } from "@navjobs/upload";
import {
  authHeaderFileUpload,
  authHeader,
} from "../../../_helpers/auth-header";

export default class BlogEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blogPost: this.props.blogPost,
    };
  }

  onHandleChange = (event) => {
    var target = event.target;
    var name = target.name;
    var value = target.value;

    let { blogPost } = this.state;
    blogPost[name] = value;

    this.setState({
      blogPost: blogPost,
    });
  };

  handleEditorChange = (e) => {
    let { blogPost } = this.state;
    blogPost.text = e.target.getContent();
    this.setState({
      blogPost: blogPost,
    });
  };

  onHandleSave = () => {
    this.props.onHandleSave(this.state.blogPost);
  };

  onHandleDeleteImageThumb = () => {
    let id = this.state.blogPost.thumbnailFile.id;

    this.setState({ loading: true });

    fetch(`api/Content/AmDeleteImage/${id}`, {
      method: "DELETE",
      headers: authHeader(),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 400) {
          this.setState({ loading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          let { blogPost } = this.state;
          blogPost.imgUrl = "";
          blogPost.thumbnailFile = null;

          this.setState({ loading: false, blogPost: blogPost });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  renderUploadButton = () => {
    if (this.state.blogPost.thumbnailFile && this.state.blogPost.thumbnailFile !== null) {
      let loadingClass = this.state.loading === true ? "loading" : "";
      return (
        <Segment>
          <Image
            src={encodeURI(this.state.blogPost.imgUrl)}
            alt={encodeURI(this.state.blogPost.imgUrl)}
            size="medium"
            spaced
          />
          <br />
          <br />
          <Button
            color="red"
            size="mini"
            icon
            labelPosition="left"
            onClick={this.onHandleDeleteImageThumb}
            className={loadingClass}
          >
            Remove thumbnail image
            <Icon name="trash alternate" />
          </Button>
        </Segment>
      );
    } else {
      return (
        <Segment>
          <Uploader
            request={{
              fileName: "file",
              url: `api/Content/AmUploadBlogThumbnailFiles`,
              method: "POST",
              headers: authHeaderFileUpload(),
              // use credentials for cross-site requests
              withCredentials: false,
            }}
            onComplete={({ response, status }) => {
              let { blogPost } = this.state;
              blogPost.imgUrl = encodeURI(response.url);
              blogPost.thumbnailFile = response;
              blogPost.thumbnailId = response.id;

              this.setState({
                blogPost: blogPost,
              });
            }}
            uploadOnSelection={true}
          >
            {({ onFiles, progress, complete }) => (
              <div>
                <UploadField
                  onFiles={onFiles}
                  containerProps={{
                    className: "upload-image-area",
                  }}
                  uploadProps={{
                    accept: ".jpg, jpeg, .png, .svg, .bmp",
                  }}
                >
                  <div className={`ui icon left labeled button teal `}>
                    <i aria-hidden="true" className="upload icon"></i>
                    Click here to select a file!
                  </div>
                </UploadField>
                {progress ? (
                  <Progress
                    className="upload-file-progess-panel"
                    percent={progress}
                    size="tiny"
                    {...(complete ? "success" : null)}
                  >
                    {`${progress}%`}
                  </Progress>
                ) : null}
                {complete ? "Complete!" : null}
              </div>
            )}
          </Uploader>
        </Segment>
      );
    }
  };

  render() {
    let contentText = this.state.blogPost ? this.state.blogPost.text : "";
    let btnLoadingClass = this.props.btnLoading
      ? this.props.btnLoading === true
        ? "loading"
        : ""
      : "";
    return (
      <Grid divided="vertically" columns="equal">
        <Grid.Row className="control-area-row">
          <Grid.Column>
            <Button
              color="teal"
              icon
              labelPosition="left"
              onClick={this.onHandleSave}
              className={btnLoadingClass}
            >
              Save
              <Icon name="save" />
            </Button>
            <Link to="/Admin/Blogs" className="ui button">
              Back to list
            </Link>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" dividing>
              Blog Post
            </Header>
            <Form>
              <Grid columns="equal" divided relaxed>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Input
                      name="title"
                      label="Title"
                      placeholder="Title"
                      required
                      onChange={this.onHandleChange}
                      value={this.state.blogPost.title}
                    />
                    <Form.TextArea
                      name="description"
                      label="Description"
                      placeholder="Description"
                      required
                      onChange={this.onHandleChange}
                      value={this.state.blogPost.description}
                      rows={7}
                    />
                    <Form.Input
                      name="imgUrl"
                      label="Thumbnail image"
                      placeholder="thumbnail image url"
                      onChange={this.onHandleChange}
                      value={this.state.blogPost.imgUrl}
                      disabled
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Header as="h4" dividing>
                      Upload thumbnail image
                    </Header>
                    <Segment inverted secondary color="teal" size="tiny">
                      Lưu ý: hình ảnh khi upload cần phải đảm bảo các tiêu chí
                      sau để đảm bảo tốc độ load trang:
                      <ul>
                        <li>
                          độ phân giải không vượt quá 1600px (cả chiều ngang và
                          chiều dọc).
                        </li>
                        <li>kích thước file ảnh không được vượt quá 500kb.</li>
                        <li>tên file cần phải không có khoảng trắng.</li>
                      </ul>
                      Có thể vào trang web{" "}
                      <a href="https://www.iloveimg.com/">
                        https://www.iloveimg.com/
                      </a>{" "}
                      để resize và nén hình ảnh cho đúng kích thước (làm online,
                      miễn phí)
                    </Segment>
                    {this.renderUploadButton()}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <div className="required field">
                      <label>Content</label>
                      <Editor
                        name="blogcontent"
                        key="blogcontent"
                        apiKey="s41n8a0vsndmme1hzfn7gocq4xxecw3t5a15nffk8jw7537n"
                        cloudChannel="dev"
                        initialValue={contentText}
                        init={{
                          menubar: false,                          
                          toolbar: [
                            "insertfile undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pageembed table tabledelete | link image | code",
                            "preview media | fontsizeselect fontselect forecolor backcolor emoticons",
                          ],
                          plugins:
                            "link lists image imagetools charmap preview hr anchor code media table emoticons paste textcolor colorpicker textpattern",
                          file_picker_types: "image media",
                          extended_valid_elements: 'img[class=img-responsive|loading=lazy|!src|border:0|alt|title|width|height|style]',
                          image_caption: true,
                          image_title: true,
                          image_advtab: true,
                          a11y_advanced_options: true,
                          min_height: 600,
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");

                            input.addEventListener("change", (e) => {
                              const file = e.target.files[0];
                              const fsize = file.size;
                              const fileSize = Math.round(fsize / 1024);
                              // The size of the file.
                              if (fileSize >= 500) {
                                alert(
                                  "File too Big, please select a file less than 500kb"
                                );
                                return;
                              }

                              var url = `api/Content/AmUploadBlogThumbnailFiles`;
                              var xhr = new XMLHttpRequest();
                              var fd = new FormData();
                              xhr.open('POST', url, true);

                              xhr.onload = function () {
                                if (xhr.readyState === 4 && xhr.status === 200) {
                                  // File uploaded successfully
                                  var response = JSON.parse(xhr.responseText);
              
                                  var url = encodeURI(response.url); 
                                  
                                  cb(url, { alt: response.fileName, title: response.fileName, classes: "test" });
              
                                }
                              };

                              const reader = new FileReader();

                              reader.onload = function () {
                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(',')[1];
              
                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);
                          
                                fd.append('file', blobInfo.blob(), file.name);
              
                                xhr.send(fd);
              
                              };

                              reader.readAsDataURL(file);

                            });

                            input.click();
                          },                          
                        }}
                        onChange={this.handleEditorChange}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
