const createGuid = function () {
  var chars = '0123456789abcdef'.split('')

  var uuid = []
  var rnd = Math.random
  var r
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
  uuid[14] = '4'

  for (var i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | rnd() * 16
      uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r & 0xf]
    }
  }

  return uuid.join('')
}

const checkOnlyLetterAndNumber = function (str) {
  var letterNumber = /^[0-9a-zA-Z]+$/;
  if (str.match(letterNumber)) {
    return true;
  }
  else {
    return false;
  }
}

const Utils = { 
  createGuid,
  checkOnlyLetterAndNumber
}

export default Utils