import React, { PureComponent } from "react";
import { Button, Icon, Form } from "semantic-ui-react";

import { ContentItem } from "./ContentItem";

export class ContentForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      contents: !this.props.contents ? [] : this.props.contents,
    };
  }

  onUpdateContents = (content) => {
    this.props.onUpdateContents(content);
  };

  renderContentList = (contents) => {
    if (!contents || contents.length <= 0) {
      // no content to render
    } else {
      //render content component
      return contents.map((item, index) => {
        let key = `content-item-${item.id}`;
        return (
          <ContentItem
            id={item.id}
            key={key}
            onUpdateContents={this.onUpdateContents}
            removeContent={this.removeContent}
            contentData={item}
          ></ContentItem>
        );
      });
    }
  };

  removeContent = (id) => {
    let newContents = this.state.contents.filter((content) => content.id !== id);
    this.setState({ contents: newContents },  () => {
      this.props.removeContents(this.state.contents)
    });
  };

  addMoreContent = (event) => {
    event.preventDefault();
    let newId = this.state.contents[this.state.contents.length-1].id + 1;
    let newContent = {
      id: newId,
      title: "Tiêu đề ...",
      description: "",
      isCollapse: false,
    };

    this.setState({
      contents: [...this.state.contents, newContent],
    });
  };

  render() {
    const contentList = this.renderContentList(this.state.contents);
    return (
      <>
        <Form.Group>
          <Button onClick={this.addMoreContent} primary>
            <Icon name="add square" />
            Thêm thông tin
          </Button>
        </Form.Group>
        {contentList}
      </>
    );
  }
}

export default ContentForm;
