import React from 'react'
import { Table, Message } from 'semantic-ui-react'
import CollaboratorItem from '../Collaborator/CollaboratorItem'

export default class List extends React.PureComponent {

  onHandleEditData = (id) => {
    this.props.onHandleEditData(id)
  }

  onToggleCollaborator = (id) => {
    this.props.onToggleCollaborator(id)
  }

  openConfirm = (collaboratorId) => {
    this.props.openConfirm(collaboratorId)
  }

  renderDataRow(item, index) {
    return <CollaboratorItem
      key={item.id}
      id={item.id}
      index={index}
      collaborator={item}
      onHandleEditData={this.onHandleEditData}
      onToggleCollaborator={this.onToggleCollaborator}
      openConfirm={this.openConfirm} />
  }

  renderDataTable(data) {

    if (data !== undefined && data.length > 0) {
      return (
        <Table key='collaborator-list-data' celled striped compact className='custom-table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='wid-per-2'>No.</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.HeaderCell>Ref Code</Table.HeaderCell>
              <Table.HeaderCell>Discount type</Table.HeaderCell>
              <Table.HeaderCell>Discount Percent</Table.HeaderCell>
              <Table.HeaderCell>Discount Price</Table.HeaderCell>
              <Table.HeaderCell>Bank Account</Table.HeaderCell>
              <Table.HeaderCell>Note</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-2'>Status</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5' textAlign='center'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              data.map((item, index) => {
                return this.renderDataRow(item, index)
              })
            }
          </Table.Body>
        </Table>
      )
    } else {
      return (
        <Message
          icon='address card'
          header='There is no collaborator in your data.'
          content='You can create new collaborator by click button [Add New].'
        />
      )
    }


  }

  render() {
    // let contents = this.props.loading
    //   ? <p><em>Loading...</em></p>
    //   : this.renderDataTable(this.props.collaboratorData);

    let contents = this.renderDataTable(this.props.collaboratorData);

    return (
      <div className='collaborator-list-table'>
        {contents}
      </div>
    );
  }
}
