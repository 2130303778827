import React from "react";
import { Container, Menu, Dropdown, Icon } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { authenticationService } from "./../../_services/authentication.service";
import { history } from "./../../_helpers/history";

export class NavMenu extends React.PureComponent {
  state = {
    dropdownMenuStyle: {
      display: "none",
    },
  };

  handleToggleDropdownMenu = () => {
    let newState = Object.assign({}, this.state);
    if (newState.dropdownMenuStyle.display === "none") {
      newState.dropdownMenuStyle = { display: "flex" };
    } else {
      newState.dropdownMenuStyle = { display: "none" };
    }

    this.setState(newState);
  };

  handleLogout = () => {
    authenticationService.logout();
    history.push("/Admin/Login");
  };

  render() {
    const { activeItem } = this.state;

    return (
      <div className="admin-menu">
        <Container fluid>
          <Menu borderless inverted>
            <Menu.Item header as={Link} to="/">
              <img src={require("../../assets/Images/logo.png")} alt="itrek" />
            </Menu.Item>
            <Menu.Item
              name="Dashboard"
              active={activeItem === "Dashboard"}
              as={NavLink}
              exact
              to="/admin/dashboard"
            />
            <Menu.Menu>
              <Dropdown item text="Tour">
                <Dropdown.Menu>
                  <Dropdown.Item
                    name="TourManagement"
                    active={activeItem === "Tour"}
                    as={NavLink}
                    exact
                    to="/admin/tour"
                  >
                    Tour management
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="Tour QuestionAndAnswer"
                    active={activeItem === "QnA"}
                    as={NavLink}
                    exact
                    to="/admin/QnA"
                  >
                    Tour Question & Answer
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>            
            <Menu.Menu>
              <Dropdown item text="Collaborator">
                <Dropdown.Menu>
                  <Dropdown.Item
                    name="Collaborator"
                    active={activeItem === "Collaborator"}
                    as={NavLink}
                    exact
                    to="/admin/Collaborator"
                  >
                    Collaborator management
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="Commission"
                    active={activeItem === "Commission"}
                    as={NavLink}
                    exact
                    to="/admin/Commission"
                  >
                    Commission payment management
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>

            <Menu.Menu>
              <Dropdown item text="Content">
                <Dropdown.Menu>
                  <Dropdown.Item
                    name="FAQ"
                    active={activeItem === "FAQ"}
                    as={NavLink}
                    exact
                    to="/admin/faq"
                  >
                    FAQ
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="AboutUs"
                    active={activeItem === "AboutUs"}
                    as={NavLink}
                    exact
                    to="/admin/AboutUs"
                  >
                    About Us
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="BookingConditions"
                    active={activeItem === "BookingConditions"}
                    as={NavLink}
                    exact
                    to="/admin/Conditions"
                  >
                    Booking Conditions
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="Blogs"
                    active={activeItem === "Blogs"}
                    as={NavLink}
                    exact
                    to="/admin/Blogs"
                  >
                    Blogs
                  </Dropdown.Item>
                  <Dropdown.Item
                    name="ImageManagement"
                    active={activeItem === "ImageManagement"}
                    as={NavLink}
                    exact
                    to="/admin/ImageManagement"
                  >
                    Image Management
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
            <Menu.Menu>
              <Menu.Item
                name="Config"
                active={activeItem === "Config"}
                as={NavLink}
                exact
                to="/admin/Config"
              />
            </Menu.Menu>
            <Menu.Menu position="right">
              <Menu.Item
                name="logout"
                active={activeItem === "logout"}
                onClick={this.handleLogout}
              >
                <Icon name="log out" />
                Sign Out
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Container>
      </div>
    );
  }
}
