import React from "react";
import { Grid, Button, Icon, Header, Modal } from "semantic-ui-react";
import List from "./List";
import Detail from "./Detail";
import { formProcess } from "../../../data/enum";
import FormModal from "../../Common/FormModal";
import "./Tour.scss";
import { authHeader } from "../../../_helpers/auth-header";
import { handleResponse } from "../../../_helpers/handle-response";
export default class Index extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tours: [],
      loading: true,
      deleteConfirmOpen: false,
      deleteIdSelected: "",
      confirmProcessing: false,
    };
  }

  openConfirm = (id) =>
    this.setState({
      deleteConfirmOpen: true,
      deleteIdSelected: id,
      confirmProcessing: false,
    });
  closeConfirm = () =>
    this.setState({
      deleteConfirmOpen: false,
      deleteIdSelected: "",
      confirmProcessing: false,
    });

  componentDidMount() {
    this.getTourData();
  }

  getTourData = () => {
    fetch("api/Tour/AmGetTours", {
      method: "GET",
      headers: authHeader(),
    })
      .then(handleResponse)
      .then((data) => {
        this.setState({ tours: data, loading: false });
      });
  };

  provideDataForQuery = () => {
    // TODO add code process for seach feature
    let payloadResource = {
      tourId: "",
      type: null,
      status: null,
    };

    return payloadResource;
  };

  onHandleEditData = (id) => {
    this.setState({ loading: true });
    //get detail data from db
    fetch(`api/Tour/AmGet/${id}`, {
      headers: authHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        let { data } = response;
        let dataInit = {
          title: data.title,
          summary: data.summary,
          description: data.description,
          durationText: data.durationText,
          endHoursBooking: data.endHoursBooking,
          departureTime: data.departureTime,
          returnTime: data.returnTime,
          price: data.price,
          status: data.status,
          order: data.order,
          tourDetails: data.tourDetails,
          contents: data.contents,
          departureInfo: data.departureInfo,
          priceIncluded: data.priceIncluded,
          priceExcluded: data.priceExcluded,
          pricePromo: data.pricePromo,
          reviews: data.reviews,
        };
        this.setState({
          openModal: true,
          loading: false,
          formProcess: formProcess.EDIT,
          tourSelected: { id: id, data: dataInit },
        });
      });
  };

  onToggleTour = (id) => {
    let payload = {
      TourId: id,
    };

    fetch(`api/Tour/ToggleTourStatus`, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(payload),
    })
      .then(handleResponse)
      .then((data) => {
        this.getTourData(data);
      })
      .catch((error) => {
        alert(`error: ${error} - Something went wrong! Please try again`);
      });
  };

  onDeleteTour = () => {
    this.setState({ confirmProcessing: true });

    let payload = {
      TourId: this.state.deleteIdSelected,
    };

    fetch(`api/Tour/DeleteTour`, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(payload),
    })
      .then(handleResponse)
      .then((data) => {
        this.setState(
          {
            deleteConfirmOpen: false,
            deleteIdSelected: "",
            confirmProcessing: false,
          },
          () => {
            this.getTourData(data);
          }
        );
      })
      .catch((error) => {
        alert(`error: ${error} - Something went wrong! Please try again`);
      });
  };

  onHandleAddNew = () => {
    this.setState({
      openModal: true,
      formProcess: formProcess.NEW,
      tourSelected: {
        id: "",
        data: {
          tourDetails: [],
          contents: []
        },
      },
    });
  };

  onHandleUpdate = (data) => {
    let tours = [...this.state.tours];

    // find data in existing tour data
    let updatedItem = tours.findIndex(function (item) {
      return item.id === data.id;
    });

    if (updatedItem === -1) {
      tours.push(data);
    } else {
      tours[updatedItem] = data;
    }

    this.onHandleCloseModal(true, tours);
  };

  onHandleCloseModal = (isUpdateData, data) => {
    if (isUpdateData) {
      let updatedTour = [...this.state.tours];
      updatedTour = data;
      this.setState({
        openModal: false,
        formProcess: formProcess.NEW,
        tourSelected: {
          id: "",
          data: {},
        },
        tours: updatedTour,
      });
      this.forceUpdate();
    } else {
      this.setState({
        openModal: false,
        formProcess: formProcess.NEW,
        tourSelected: {
          id: "",
          data: {},
        },
      });
    }
  };

  onCancel = () => {
    this.onHandleCloseModal();
  };

  render() {
    var { tours, loading } = this.state;
    return (
      <Grid divided="vertically">
        <Grid.Row className="control-area-row">
          <Grid.Column>
            <Button
              color="teal"
              icon
              labelPosition="left"
              onClick={this.onHandleAddNew}
            >
              Add new
              <Icon name="add" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as="h3" dividing>
              List Tours
            </Header>
            <List
              key="tourList"
              className="tour-list"
              loading={loading}
              toursData={tours}
              onHandleEditData={this.onHandleEditData}
              onToggleTour={this.onToggleTour}
              openConfirm={this.openConfirm}
            ></List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <FormModal
            open={this.state.openModal}
            size="fullscreen"
            closeOnDimmerClick={false}
            header="Add/Update"
            onClose={this.onHandleCloseModal}
          >
            <Detail
              process={this.state.formProcess}
              tourSelected={this.state.tourSelected}
              onHandleUpdate={this.onHandleUpdate}
              onCancel={this.onCancel}
            />
          </FormModal>

          <Modal
            size="mini"
            open={this.state.deleteConfirmOpen}
            onClose={this.closeConfirm}
            className="alert-message-area"
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Delete Your Tour</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to delete ?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                className={
                  this.state.confirmProcessing === true
                    ? "loading disabled"
                    : ""
                }
                negative
                onClick={this.closeConfirm}
              >
                No
              </Button>
              <Button
                className={
                  this.state.confirmProcessing === true
                    ? "loading disabled"
                    : ""
                }
                positive
                onClick={this.onDeleteTour}
              >
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }
}
