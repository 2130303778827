import React from 'react'
import { Header, Segment, Form, Container, Message, Select } from "semantic-ui-react";
import { nationality } from '../../../../data/nationality'

export default class BookingBillingContact extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      billingContactInfo: this.props.billingContactInfo === undefined ? {
        billingContactFirstName: '',
        billingContactLastName: '',
        billingContactPhone: '',
        billingContactEmail: '',
        conutry: ''
      } : this.props.billingContactInfo
    }
  }

  onHandleChange = (event) => {
    var target = event.target
    var name = target.name
    var value = target.value

    let { billingContactInfo } = this.state

    billingContactInfo[name] = value

    this.setState({
      billingContactInfo: billingContactInfo,
    })

    this.props.onHandleInputBookingBilling(billingContactInfo)
  }

  onHandleDropdownSelect = (event, data) => {
    var name = data.name
    var value = data.value

    let { billingContactInfo } = this.state
    billingContactInfo[name] = value

    this.setState({
      billingContactInfo: billingContactInfo,
    })

    this.props.onHandleInputBookingBilling(billingContactInfo)
  }

  render() {
    let { billingContactInfo } = this.state
    return (
      <div>
        <Container className='booking-detail-billing-information'>
          <Header as='h3' dividing>Billing & Contact
              <Header.Subheader>Step 2 of 2</Header.Subheader>
          </Header>
          <Segment vertical>
            <Container className='booking-detail-billing-form'>
              <Header as='h4' attached='top'>Confirmation and Billing Statements</Header>
              <Segment attached>
                <div key={`billing-contact-form`} className='billing-contact-form ui form'>
                  <Message info>
                    <Message.Header>Note:</Message.Header>
                    <p>The confirmation email will be sent to this email address.</p>
                  </Message>
                  <Form.Field required>
                    <label>First Name</label>
                    <input required name='billingContactFirstName' onChange={this.onHandleChange} value={billingContactInfo.billingContactFirstName} />
                  </Form.Field>
                  <Form.Field required>
                    <label>Last Name</label>
                    <input required name='billingContactLastName' onChange={this.onHandleChange} value={billingContactInfo.billingContactLastName} />
                  </Form.Field>
                  <Form.Field>
                    <label>WhatsApp number</label>
                    <input name='billingContactPhone' onChange={this.onHandleChange} value={billingContactInfo.billingContactPhone} />
                  </Form.Field>
                  <Form.Field required>
                    <label>Email</label>
                    <input required type='email' name='billingContactEmail' onChange={this.onHandleChange} value={billingContactInfo.billingContactEmail} />
                  </Form.Field>
                  <Form.Field required>
                    <label>Country</label>
                    <Select
                      name='conutry'
                      onChange={this.onHandleDropdownSelect}
                      options={nationality.list}
                      placeholder='Choose an option'
                      selection
                      value={billingContactInfo.conutry}
                      required
                    />
                  </Form.Field>
                </div>
              </Segment>
            </Container>
          </Segment>
        </Container>
      </div>
    )
  }
}
