import React, { Component } from 'react'
import _ from 'lodash'
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom'
import { Grid, Container, Button, Header, Message, Segment, Table, List, Icon } from "semantic-ui-react";
import { authHeader } from '../../../../_helpers/auth-header';
import { handleResponse } from '../../../../_helpers/handle-response';
import { bookingStep } from '../../../../data/enum'

export class BookingConfirm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      ...props,
      isActive: true,
    }
  }

  scrollToTop = () => window.scrollTo(0, 450)

  componentDidMount() {
    this.scrollToTop()
  }

  onHandleClickBooking = () => {
    let payload = this.onProvideDateForSave()
    this.setState({ loading: true })

    // post data to api 
    fetch('api/Booking/AdPost', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(data => {
        this.setRedirect(`/booking/${data.bookingNumber}`)
      })
      .catch(error => {
        alert(error)
        this.setState({ loading: false })
      })
  }

  onProvideDateForSave = () => {
    let { tourData, bookingInfo, dataParticipant, billingContactInfo } = this.state

    //build object booking participant
    let bookingParticipants = []

    dataParticipant.forEach((item) => {

      let bookingParticipantAnswerQuestions = []

      for (var key in item.qna) {
        // skip loop if the property is from prototype
        if (!item.qna.hasOwnProperty(key)) continue;

        var obj = item.qna[key];

        bookingParticipantAnswerQuestions.push({
          QuestionId: key,
          AnswerId: obj.isDropdown === true ? obj.value : '',
          AnswerByInputText: obj.isDropdown === false ? obj.value : '',
        })
      }

      bookingParticipants.push(
        {
          DOB: new Date(item.dateOfBirth),
          FullName: item.fullName,
          Gender: item.gender,
          Passport: item.passport,
          Nationality: item.nationality,
          Phone: item.phone,
          Email: item.email,
          BookingParticipantAnswerQuestions: bookingParticipantAnswerQuestions,
          Order: item.order
        }
      )
    })

    // build object post to server
    var payload = {
      TourDetailId: bookingInfo.selectedTourDetail.id,
      TourPrice: tourData.price,
      BookingDate: new Date(bookingInfo.dateSelected),
      BookingNumParticipant: bookingInfo.numParticipants,
      BookingPrice: bookingInfo.totalPrice,
      PromoCode: this.state.promoCode, // TODO: add promocode to payload object
      PromoPercent: '', // TODO: add promocode to payload object
      PromoPrice: this.state.promoPrice, // TODO: add promocode to payload object
      Total: bookingInfo.totalPrice, // TODO: add promocode to payload object
      BillingContactFirstName: billingContactInfo.billingContactFirstName,
      BillingContactLastName: billingContactInfo.billingContactLastName,
      BillingContactEmail: billingContactInfo.billingContactEmail,
      BillingContactPhone: billingContactInfo.billingContactPhone,
      Conutry: billingContactInfo.conutry,
      BookingParticipants: bookingParticipants
    }

    return payload
  }

  onHandleBackToBooking = () => {
    this.props.onHandleChangeStep(bookingStep.INPUT_BOOKING_DETAIL, this.props)
  }

  renderExtraItem = (item, index) => {
    return (
      <Grid.Row key={`${item.questionId}__${index}`} columns={2} className='lighgrey-color-row'>
        <Grid.Column width={11}>{`${item.answerContent} x ${item.count}`}</Grid.Column>
        <Grid.Column width={5} className='text-align-right'>{Number(item.total).toLocaleString('en')}</Grid.Column>
      </Grid.Row>
    )
  }

  renderDataExtraFee = (extraList) => {
    if (extraList.length > 0) {
      return (
        extraList.map((item, index) => {
          return this.renderExtraItem(item, index)
        })
      )
    }
    return (
      <div className='hidden'></div>
    )
  }

  renderDiscountRow = () => {

    let isDisplay = this.props.promoPrice
    if (isDisplay) {
      return (
        <Grid.Row columns={2} className='discount-row lighgrey-color-row'>
          <Grid.Column>Discount (VND)</Grid.Column>
          <Grid.Column className='text-align-right'>{`- ${Number(this.props.promoPrice).toLocaleString('en')}`}</Grid.Column>
        </Grid.Row>
      )
    } else {
      return ''
    }
  }

  renderSubTotalRow = (price, extrafee) => {

    let subTotal = Number(price) + Number(extrafee)
    if (this.props.promoPrice && !isNaN(this.props.promoPrice)) {
      subTotal = subTotal - Number(this.props.promoPrice)
    }
    return (
      <Grid.Row columns={2} className='subtotal-row'>
        <Grid.Column width={11}>SubTotal (VND)</Grid.Column>
        <Grid.Column width={5} className='text-align-right'>{Number(subTotal).toLocaleString('en')}</Grid.Column>
      </Grid.Row>
    )
  }

  calculateTotalPriceToPay = (totalPrice, totalExtra) => {

    let subTotal = Number(totalPrice) + Number(totalExtra)
    let promoPrice = isNaN(this.props.promoPrice) ? 0 : Number(this.props.promoPrice)
    let total = (subTotal) - promoPrice

    return Number(total).toLocaleString('en')
  }

  toggleContent = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  renderTourInformation = () => {

    let { bookingInfo, tourData } = this.props

    let startDate = new Date(bookingInfo.selectedTourDetail.dateBooking)
    let endDate = new Date(bookingInfo.selectedTourDetail.dateBookingEnd)
    let startTime = new Date(tourData.departureTime)
    let endTime = new Date(tourData.returnTime)

    return (
      <Container>
        <Header as='h3' dividing> Tour Detail</Header>
        <Segment vertical>
          <Table basic='very'>
            <Table.Body>
              <Table.Row>
                <Table.Cell><strong>Tour:</strong></Table.Cell>
                <Table.Cell>{tourData.title}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Start date: </strong></Table.Cell>
                <Table.Cell><Moment format="LL">{startDate}</Moment> <Moment format="LT">{startTime}</Moment></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>End date: </strong></Table.Cell>
                <Table.Cell><Moment format="LL">{endDate}</Moment> <Moment format="LT">{endTime}</Moment></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Duration: </strong></Table.Cell>
                <Table.Cell>{tourData.durationText}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <button size='mini' className="ui icon left labeled button" onClick={this.onHandleBackToBooking}>
            <i aria-hidden="true" className="arrow left icon"></i>
            Edit booking
          </button>
        </Segment>
      </Container>
    )
  }

  buildQnaDOM = (qnaItem) => {
    return (
      <List.Item>
        <List.Header>{`- ${qnaItem.questionContent}`}</List.Header><i>{qnaItem.isDropdown === true ? qnaItem.name : qnaItem.value.toString()}</i>
      </List.Item>
    )
  }

  renderQnA = (qna) => {
    if (qna) {
      let qnaLstDomRender = []
      for (let item in qna) {
        if (qna[item].isDropdown === true) {
          qnaLstDomRender.push(this.buildQnaDOM(qna[item]))
        } else {
          let objQna = _.find(this.props.tourData.tourQuestions, function (o) { return o.id === item })
          if (objQna) {
            qna[item].questionContent = objQna.questionContent
            qnaLstDomRender.push(this.buildQnaDOM(qna[item]))
          }
        }
      }
      return qnaLstDomRender
    }
  }

  renderBookingParticipantItem = (item, index) => {
    let qna = this.renderQnA(item.qna)
    return (
      <Container className='booking-detail-participant-form' key={`cont__part__${index + 1}`}>
        <Header as='h4' attached='top' onClick={this.toggleContent}>
          {`Participant ${index + 1}`}
          <Icon name='triangle down' />
        </Header>
        <Segment attached className={this.state.isActive ? 'active' : 'hidden'}>
          <Table basic='very' key={`part__${index + 1}`}>
            <Table.Body>
              <Table.Row>
                <Table.Cell><strong>Full Name (per passport)</strong></Table.Cell>
                <Table.Cell>{item.fullName}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Date of Birth</strong></Table.Cell>
                <Table.Cell><Moment format="MMM DD, YYYY" date={item.dateOfBirth} /></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Passport</strong></Table.Cell>
                <Table.Cell>{item.passport}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Nationality</strong></Table.Cell>
                <Table.Cell>{item.nationality}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Gender</strong></Table.Cell>
                <Table.Cell>{item.gender}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>WhatsApp number</strong></Table.Cell>
                <Table.Cell>{item.phone}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Email</strong></Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell><strong>Other</strong></Table.Cell>
                <Table.Cell>
                  <List>
                    {qna}
                  </List>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Container>
    )
  }

  renderBookingParticipantsList = () => {
    let { dataParticipant } = this.props
    return (
      <Container className='booking-detail-participant-information'>
        <Header as='h3' dividing>List of participants in your group
        </Header>
        <Segment vertical>
          {
            dataParticipant.map((item, index) => {
              return this.renderBookingParticipantItem(item, index)
            })
          }
        </Segment>
      </Container>
    )
  }

  renderBookingBillingInformation = () => {

    let { tourData, bookingInfo, dataParticipant } = this.props

    let startDate = new Date(bookingInfo.selectedTourDetail.dateBooking)
    let endDate = new Date(bookingInfo.selectedTourDetail.dateBookingEnd)
    let startTime = new Date(tourData.departureTime)
    let endTime = new Date(tourData.returnTime)
    let totalPrice = bookingInfo.totalPrice

    // create data for booking billing from data participant
    let objectDataExtra = []

    dataParticipant.forEach(item => {
      objectDataExtra = [...objectDataExtra, ...item.dropDownQnAHasFee]
    })

    let totalExtraFee = 0

    let result = [];
    objectDataExtra.reduce(function (res, value) {
      if (!res[value.value]) {
        res[value.value] = {
          fee: value.fee,
          total: 0,
          questionContent: value.questionContent,
          answerContent: value.name,
          count: 0,
          questionId: value.questionId
        };
        result.push(res[value.value])
      }
      res[value.value].total += value.fee
      res[value.value].count += value.count ? value.count : 1
      return res;
    }, {});

    if (result.length > 0) {
      for (let i = 0; i < result.length; i++) {
        totalExtraFee += result[i].total
      }
    }

    let dataExtraFee = this.renderDataExtraFee(result)

    return (
      <div className='sticky-area'>
        <Container className='booking-confirm-payment-summary'>
          <Header as='h3' attached='top'>
            Payment Summary
          </Header>
          <Segment attached>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={11}>
                  <strong>{tourData.title} </strong><br />
                  <strong>Date:</strong> <Moment format="LL">{startDate}</Moment> <Moment format="hh:mm a">{startTime}</Moment><br />
                  <strong>Ends:</strong> <Moment format="LL">{endDate}</Moment> <Moment format="hh:mm a">{endTime}</Moment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={11}>
                  {Number(tourData.price).toLocaleString('en')} VND/ person x <strong>{bookingInfo.numParticipants} </strong>
                </Grid.Column>
                <Grid.Column className='text-align-right' width={5}>{Number(bookingInfo.totalPrice).toLocaleString('en')}</Grid.Column>
              </Grid.Row>
              {dataExtraFee}

              {this.renderDiscountRow()}
              {this.renderSubTotalRow(totalPrice, totalExtraFee)}             
              <Grid.Row columns={2} className='total-row'>
                <Grid.Column width={11}>Total to pay (VND)</Grid.Column>
                <Grid.Column width={5} className='text-align-right'>{this.calculateTotalPriceToPay(totalPrice, totalExtraFee)}</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Button positive size='big' className={`full-width-btn ${this.state.loading ? 'loading disabled ' : ''} `} onClick={this.onHandleClickBooking}> CONFIRM BOOKING </Button>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }

  renderBookingBillingContact = () => {
    let { billingContactInfo } = this.props
    return (
      <Container className='booking-detail-billing-information'>
        <Header as='h3' dividing>Billing & Contact</Header>
        <Segment vertical>
          <div key={`billing-contact-form`} className='billing-contact-form ui form'>
            <Message info>
              <Message.Header>Note:</Message.Header>
              <p>The confirmation email will be sent to this email address.</p>
            </Message>
            <Table basic='very'>
              <Table.Body>
                <Table.Row>
                  <Table.Cell><strong>First Name</strong></Table.Cell>
                  <Table.Cell>{billingContactInfo.billingContactFirstName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><strong>Last Name</strong></Table.Cell>
                  <Table.Cell>{billingContactInfo.billingContactLastName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><strong>WhatsApp number</strong></Table.Cell>
                  <Table.Cell>{billingContactInfo.billingContactPhone}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><strong>Email</strong></Table.Cell>
                  <Table.Cell>{billingContactInfo.billingContactEmail}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><strong>Country</strong></Table.Cell>
                  <Table.Cell>{billingContactInfo.conutry}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </Segment>
      </Container>
    )
  }

  setRedirect = (url) => {
    this.setState({
      redirect: true,
      url: url,
      loading: false
    })
  }

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.url} />
    }
  }

  render() {

    let tourInformation = this.renderTourInformation()
    let participantsList = this.renderBookingParticipantsList()
    let billingInfo = this.renderBookingBillingInformation()
    let billingContact = this.renderBookingBillingContact()
    return (
      <div>
        {this.renderRedirect()}
        <div className='booking-confirmation'>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8} className='booking-confirmation-left-col' >
                <Message positive icon='exclamation circle'>
                  Please check all your participants information, your tour date, and total prices before you finish booking.
                </Message>
                <Header as='h2' attached='top'>
                  Booking Confirmation
                </Header>
                <Segment attached>
                  {tourInformation}
                  {billingContact}
                  {participantsList}
                </Segment>
              </Grid.Column>
              <Grid.Column width={6} >
                {billingInfo}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  }
}
