import React from 'react'
import { Table, Button, Label, List } from 'semantic-ui-react'

export default class CollaboratorItem extends React.PureComponent {

  onEditData = (id) => {
    this.props.onHandleEditData(id)
  }

  onToggleCollaborator = (id) => {
    this.props.onToggleCollaborator(id)
  }

  openConfirm = (event, collaboratorId) => {
    event.preventDefault()
    this.props.openConfirm(collaboratorId)
  }

  render() {

    let { id, collaborator, index } = this.props
    return (
      <Table.Row key={id}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>{collaborator.name}</Table.Cell>        
        <Table.Cell>{collaborator.email}</Table.Cell>
        <Table.Cell>{collaborator.phone}</Table.Cell>
        <Table.Cell>{collaborator.refCode}</Table.Cell>
        <Table.Cell>{collaborator.discountType === true ? <Label basic color='blue'>Percent (%)</Label> : <Label basic color='orange'>Currency ($)</Label>}</Table.Cell>
        <Table.Cell>{collaborator.discountPercent === null ? '' : `${collaborator.discountPercent} %`}</Table.Cell>
        <Table.Cell>{collaborator.discountPrice === null ? '' : `${Number(collaborator.discountPrice).toLocaleString('en')} $`}</Table.Cell>
        <Table.Cell>
          <List key={`list-item-bank-account-${id}`}>
          <List.Item>
              <List.Icon name='credit card' />
              <List.Content>{collaborator.accountName}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='credit card' />
              <List.Content>{collaborator.bankNumber}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='building outline' />
              <List.Content>
                {collaborator.bankName}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='building outline' />
              <List.Content>
              {collaborator.bankDepartment}
              </List.Content>
            </List.Item>
          </List>
          </Table.Cell>
        <Table.Cell>{collaborator.note}</Table.Cell>
        <Table.Cell textAlign='center'>{collaborator.status === 'Active' ? <Label basic color='blue'>{collaborator.status}</Label> : <Label basic color='grey'>{collaborator.status}</Label>}</Table.Cell>
        <Table.Cell className='wid-per-5' textAlign='center'>
          <Button.Group basic size='small'>
            <Button icon='edit outline' id={id} data-tooltip="Edit"  onClick={() => this.onEditData(id)} />
            <Button icon={collaborator.status === 'Active' ? 'eye' : 'eye slash'} data-tooltip="Active/Deactive" onClick={() => this.onToggleCollaborator(id)}/>
            <Button key={`delete-${id}`} icon='trash alternate outline' data-tooltip="Delete collaborator" id={`delete-${id}`} onClick={(e) => this.openConfirm(e, id)} />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    )
  }
}
