import React from "react";
import { Route, Switch } from "react-router-dom";

import AdTour from "./components/Admin/Tour/Index";
import AdFaq from "./components/Admin/FAQv2/Index";
import AdAboutUs from "./components/Admin/AboutUs/Index";
import AdDashboard from "./components/Admin/Dashboard/Index";
import AdCollaborator from "./components/Admin/Collaborator/Index";
import AdBooking from "./components/Admin/Booking/Index";
import AdBooking2 from "./components/Admin/BookingList/Index";
import AdCreateBooking from "./components/Admin/Booking/Create";
import AdEditBooking from "./components/Admin/Booking/Edit";
import AdBookingConditions from "./components/Admin/Conditions/Index";
import ImageManagement from "./components/Admin/ImageManagement/Index";
import CollaboratorPayment from "./components/Admin/CollaboratorPayment/Index";
import AdPageContentConfig from "./components/Admin/PageContentConfig/Index";
import QnA from "./components/Admin/QnA/Index";
import AdBlogs from "./components/Admin/Blogs/Index";
import AdEditBlog from "./components/Admin/Blogs/Edit";
import AdNewBlog from "./components/Admin/Blogs/New";
import LoginPage from "./components/Login/Index";


import { authenticationService } from "./_services/authentication.service";
import { Role } from "./_helpers/role";
import { PrivateRoute } from "./components/Common/PrivateRoute";

export default class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false,
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({
        currentUser: x,
        isAdmin: x && x.role === Role.Admin,
      })
    );
  }

  render() {
    return (
      <Switch>
        <Route path="/Admin/Login" component={LoginPage} />
        <PrivateRoute exact path="/" component={AdDashboard} />
        <PrivateRoute exact path="/Admin/Tour" component={AdTour} />
        <PrivateRoute exact path="/Admin/FAQ" component={AdFaq} />
        <PrivateRoute exact path="/Admin/AboutUs" component={AdAboutUs} />
        <PrivateRoute
          exact
          path="/Admin/Conditions"
          component={AdBookingConditions}
        />
        <PrivateRoute
          exact
          path="/Admin/Collaborator"
          component={AdCollaborator}
        />
        <PrivateRoute
          exact
          path="/Admin/Commission"
          component={CollaboratorPayment}
        />
        <PrivateRoute exact path="/Admin/Booking" component={AdBooking} />
        <PrivateRoute
          exact
          path="/Admin/Booking/Create"
          component={AdCreateBooking}
        />
        <PrivateRoute
          exact
          path="/Admin/BookingTourParticipants"
          component={AdBooking2}
        />
        <PrivateRoute
          exact
          path="/Admin/ImageManagement"
          component={ImageManagement}
        />
        <PrivateRoute exact path="/Admin/QnA" component={QnA} />
        <PrivateRoute exact path="/Admin/Dashboard" component={AdDashboard} />
        <PrivateRoute exact path="/Admin/Blogs" component={AdBlogs} />
        <PrivateRoute exact path="/Admin/Blogs/New" component={AdNewBlog} />
        <PrivateRoute path="/Admin/Blogs/Edit/:blogId" component={AdEditBlog} />
        <PrivateRoute
          path="/Admin/Booking/Edit/:bookingId"
          component={AdEditBooking}
        />
        <PrivateRoute exact path="/Admin/Config" component={AdPageContentConfig} />
      </Switch>
    );
  }
}
