const nationality = {
  list: [
    {
      "key": "AFG",
      "text": "Afghanistan",
      "value": "Afghanistan"
    },
    {
      "key": "ALA",
      "text": "Åland Islands",
      "value": "Åland Islands"
    },
    {
      "key": "ALB",
      "text": "Albania",
      "value": "Albania"
    },
    {
      "key": "DZA",
      "text": "Algeria",
      "value": "Algeria"
    },
    {
      "key": "ASM",
      "text": "American Samoa",
      "value": "American Samoa"
    },
    {
      "key": "AND",
      "text": "Andorra",
      "value": "Andorra"
    },
    {
      "key": "AGO",
      "text": "Angola",
      "value": "Angola"
    },
    {
      "key": "AIA",
      "text": "Anguilla",
      "value": "Anguilla"
    },
    {
      "key": "ATA",
      "text": "Antarctica",
      "value": "Antarctica"
    },
    {
      "key": "ATG",
      "text": "Antigua and Barbuda",
      "value": "Antigua and Barbuda"
    },
    {
      "key": "ARG",
      "text": "Argentina",
      "value": "Argentina"
    },
    {
      "key": "ARM",
      "text": "Armenia",
      "value": "Armenia"
    },
    {
      "key": "ABW",
      "text": "Aruba",
      "value": "Aruba"
    },
    {
      "key": "AUS",
      "text": "Australia",
      "value": "Australia"
    },
    {
      "key": "AUT",
      "text": "Austria",
      "value": "Austria"
    },
    {
      "key": "AZE",
      "text": "Azerbaijan",
      "value": "Azerbaijan"
    },
    {
      "key": "BHS",
      "text": "Bahamas",
      "value": "Bahamas"
    },
    {
      "key": "BHR",
      "text": "Bahrain",
      "value": "Bahrain"
    },
    {
      "key": "BGD",
      "text": "Bangladesh",
      "value": "Bangladesh"
    },
    {
      "key": "BRB",
      "text": "Barbados",
      "value": "Barbados"
    },
    {
      "key": "BLR",
      "text": "Belarus",
      "value": "Belarus"
    },
    {
      "key": "BEL",
      "text": "Belgium",
      "value": "Belgium"
    },
    {
      "key": "BLZ",
      "text": "Belize",
      "value": "Belize"
    },
    {
      "key": "BEN",
      "text": "Benin",
      "value": "Benin"
    },
    {
      "key": "BMU",
      "text": "Bermuda",
      "value": "Bermuda"
    },
    {
      "key": "BTN",
      "text": "Bhutan",
      "value": "Bhutan"
    },
    {
      "key": "BOL",
      "text": "Bolivia (Plurinational State of)",
      "value": "Bolivia (Plurinational State of)"
    },
    {
      "key": "BES",
      "text": "Bonaire, Sint Eustatius and Saba",
      "value": "Bonaire, Sint Eustatius and Saba"
    },
    {
      "key": "BIH",
      "text": "Bosnia and Herzegovina",
      "value": "Bosnia and Herzegovina"
    },
    {
      "key": "BWA",
      "text": "Botswana",
      "value": "Botswana"
    },
    {
      "key": "BVT",
      "text": "Bouvet Island",
      "value": "Bouvet Island"
    },
    {
      "key": "BRA",
      "text": "Brazil",
      "value": "Brazil"
    },
    {
      "key": "IOT",
      "text": "British Indian Ocean Territory",
      "value": "British Indian Ocean Territory"
    },
    {
      "key": "BRN",
      "text": "Brunei Darussalam",
      "value": "Brunei Darussalam"
    },
    {
      "key": "BGR",
      "text": "Bulgaria",
      "value": "Bulgaria"
    },
    {
      "key": "BFA",
      "text": "Burkina Faso",
      "value": "Burkina Faso"
    },
    {
      "key": "BDI",
      "text": "Burundi",
      "value": "Burundi"
    },
    {
      "key": "CPV",
      "text": "Cabo Verde",
      "value": "Cabo Verde"
    },
    {
      "key": "KHM",
      "text": "Cambodia",
      "value": "Cambodia"
    },
    {
      "key": "CMR",
      "text": "Cameroon",
      "value": "Cameroon"
    },
    {
      "key": "CAN",
      "text": "Canada",
      "value": "Canada"
    },
    {
      "key": "CYM",
      "text": "Cayman Islands",
      "value": "Cayman Islands"
    },
    {
      "key": "CAF",
      "text": "Central African Republic",
      "value": "Central African Republic"
    },
    {
      "key": "TCD",
      "text": "Chad",
      "value": "Chad"
    },
    {
      "key": "CHL",
      "text": "Chile",
      "value": "Chile"
    },
    {
      "key": "CHN",
      "text": "China",
      "value": "China"
    },
    {
      "key": "CXR",
      "text": "Christmas Island",
      "value": "Christmas Island"
    },
    {
      "key": "CCK",
      "text": "Cocos (Keeling) Islands",
      "value": "Cocos (Keeling) Islands"
    },
    {
      "key": "COL",
      "text": "Colombia",
      "value": "Colombia"
    },
    {
      "key": "COM",
      "text": "Comoros",
      "value": "Comoros"
    },
    {
      "key": "COG",
      "text": "Congo (Republic of the)",
      "value": "Congo (Republic of the)"
    },
    {
      "key": "COD",
      "text": "Congo (Democratic Republic of the)",
      "value": "Congo (Democratic Republic of the)"
    },
    {
      "key": "COK",
      "text": "Cook Islands",
      "value": "Cook Islands"
    },
    {
      "key": "CRI",
      "text": "Costa Rica",
      "value": "Costa Rica"
    },
    {
      "key": "CIV",
      "text": "Côte d'Ivoire",
      "value": "Côte d'Ivoire"
    },
    {
      "key": "HRV",
      "text": "Croatia",
      "value": "Croatia"
    },
    {
      "key": "CUB",
      "text": "Cuba",
      "value": "Cuba"
    },
    {
      "key": "CUW",
      "text": "Curaçao",
      "value": "Curaçao"
    },
    {
      "key": "CYP",
      "text": "Cyprus",
      "value": "Cyprus"
    },
    {
      "key": "CZE",
      "text": "Czech Republic",
      "value": "Czech Republic"
    },
    {
      "key": "DNK",
      "text": "Denmark",
      "value": "Denmark"
    },
    {
      "key": "DJI",
      "text": "Djibouti",
      "value": "Djibouti"
    },
    {
      "key": "DMA",
      "text": "Dominica",
      "value": "Dominica"
    },
    {
      "key": "DOM",
      "text": "Dominican Republic",
      "value": "Dominican Republic"
    },
    {
      "key": "ECU",
      "text": "Ecuador",
      "value": "Ecuador"
    },
    {
      "key": "EGY",
      "text": "Egypt",
      "value": "Egypt"
    },
    {
      "key": "SLV",
      "text": "El Salvador",
      "value": "El Salvador"
    },
    {
      "key": "GNQ",
      "text": "Equatorial Guinea",
      "value": "Equatorial Guinea"
    },
    {
      "key": "ERI",
      "text": "Eritrea",
      "value": "Eritrea"
    },
    {
      "key": "EST",
      "text": "Estonia",
      "value": "Estonia"
    },
    {
      "key": "ETH",
      "text": "Ethiopia",
      "value": "Ethiopia"
    },
    {
      "key": "FLK",
      "text": "Falkland Islands (Malvinas)",
      "value": "Falkland Islands (Malvinas)"
    },
    {
      "key": "FRO",
      "text": "Faroe Islands",
      "value": "Faroe Islands"
    },
    {
      "key": "FJI",
      "text": "Fiji",
      "value": "Fiji"
    },
    {
      "key": "FIN",
      "text": "Finland",
      "value": "Finland"
    },
    {
      "key": "FRA",
      "text": "France",
      "value": "France"
    },
    {
      "key": "GUF",
      "text": "French Guiana",
      "value": "French Guiana"
    },
    {
      "key": "PYF",
      "text": "French Polynesia",
      "value": "French Polynesia"
    },
    {
      "key": "ATF",
      "text": "French Southern Territories",
      "value": "French Southern Territories"
    },
    {
      "key": "GAB",
      "text": "Gabon",
      "value": "Gabon"
    },
    {
      "key": "GMB",
      "text": "Gambia",
      "value": "Gambia"
    },
    {
      "key": "GEO",
      "text": "Georgia",
      "value": "Georgia"
    },
    {
      "key": "DEU",
      "text": "Germany",
      "value": "Germany"
    },
    {
      "key": "GHA",
      "text": "Ghana",
      "value": "Ghana"
    },
    {
      "key": "GIB",
      "text": "Gibraltar",
      "value": "Gibraltar"
    },
    {
      "key": "GRC",
      "text": "Greece",
      "value": "Greece"
    },
    {
      "key": "GRL",
      "text": "Greenland",
      "value": "Greenland"
    },
    {
      "key": "GRD",
      "text": "Grenada",
      "value": "Grenada"
    },
    {
      "key": "GLP",
      "text": "Guadeloupe",
      "value": "Guadeloupe"
    },
    {
      "key": "GUM",
      "text": "Guam",
      "value": "Guam"
    },
    {
      "key": "GTM",
      "text": "Guatemala",
      "value": "Guatemala"
    },
    {
      "key": "GGY",
      "text": "Guernsey",
      "value": "Guernsey"
    },
    {
      "key": "GIN",
      "text": "Guinea",
      "value": "Guinea"
    },
    {
      "key": "GNB",
      "text": "Guinea-Bissau",
      "value": "Guinea-Bissau"
    },
    {
      "key": "GUY",
      "text": "Guyana",
      "value": "Guyana"
    },
    {
      "key": "HTI",
      "text": "Haiti",
      "value": "Haiti"
    },
    {
      "key": "HMD",
      "text": "Heard Island and McDonald Islands",
      "value": "Heard Island and McDonald Islands"
    },
    {
      "key": "VAT",
      "text": "Vatican City State",
      "value": "Vatican City State"
    },
    {
      "key": "HND",
      "text": "Honduras",
      "value": "Honduras"
    },
    {
      "key": "HKG",
      "text": "Hong Kong",
      "value": "Hong Kong"
    },
    {
      "key": "HUN",
      "text": "Hungary",
      "value": "Hungary"
    },
    {
      "key": "ISL",
      "text": "Iceland",
      "value": "Iceland"
    },
    {
      "key": "IND",
      "text": "India",
      "value": "India"
    },
    {
      "key": "IDN",
      "text": "Indonesia",
      "value": "Indonesia"
    },
    {
      "key": "IRN",
      "text": "Iran",
      "value": "Iran"
    },
    {
      "key": "IRQ",
      "text": "Iraq",
      "value": "Iraq"
    },
    {
      "key": "IRL",
      "text": "Ireland",
      "value": "Ireland"
    },
    {
      "key": "IMN",
      "text": "Isle of Man",
      "value": "Isle of Man"
    },
    {
      "key": "ISR",
      "text": "Israel",
      "value": "Israel"
    },
    {
      "key": "ITA",
      "text": "Italy",
      "value": "Italy"
    },
    {
      "key": "JAM",
      "text": "Jamaica",
      "value": "Jamaica"
    },
    {
      "key": "JPN",
      "text": "Japan",
      "value": "Japan"
    },
    {
      "key": "JEY",
      "text": "Jersey",
      "value": "Jersey"
    },
    {
      "key": "JOR",
      "text": "Jordan",
      "value": "Jordan"
    },
    {
      "key": "KAZ",
      "text": "Kazakhstan",
      "value": "Kazakhstan"
    },
    {
      "key": "KEN",
      "text": "Kenya",
      "value": "Kenya"
    },
    {
      "key": "KIR",
      "text": "Kiribati",
      "value": "Kiribati"
    },
    {
      "key": "PRK",
      "text": "Korea (Democratic People's Republic of)",
      "value": "Korea (Democratic People's Republic of)"
    },
    {
      "key": "KOR",
      "text": "Korea (Republic of)",
      "value": "Korea (Republic of)"
    },
    {
      "key": "KWT",
      "text": "Kuwait",
      "value": "Kuwait"
    },
    {
      "key": "KGZ",
      "text": "Kyrgyzstan",
      "value": "Kyrgyzstan"
    },
    {
      "key": "LAO",
      "text": "Lao People's Democratic Republic",
      "value": "Lao People's Democratic Republic"
    },
    {
      "key": "LVA",
      "text": "Latvia",
      "value": "Latvia"
    },
    {
      "key": "LBN",
      "text": "Lebanon",
      "value": "Lebanon"
    },
    {
      "key": "LSO",
      "text": "Lesotho",
      "value": "Lesotho"
    },
    {
      "key": "LBR",
      "text": "Liberia",
      "value": "Liberia"
    },
    {
      "key": "LBY",
      "text": "Libya",
      "value": "Libya"
    },
    {
      "key": "LIE",
      "text": "Liechtenstein",
      "value": "Liechtenstein"
    },
    {
      "key": "LTU",
      "text": "Lithuania",
      "value": "Lithuania"
    },
    {
      "key": "LUX",
      "text": "Luxembourg",
      "value": "Luxembourg"
    },
    {
      "key": "MAC",
      "text": "Macao",
      "value": "Macao"
    },
    {
      "key": "MKD",
      "text": "Macedonia (the former Yugoslav Republic of)",
      "value": "Macedonia (the former Yugoslav Republic of)"
    },
    {
      "key": "MDG",
      "text": "Madagascar",
      "value": "Madagascar"
    },
    {
      "key": "MWI",
      "text": "Malawi",
      "value": "Malawi"
    },
    {
      "key": "MYS",
      "text": "Malaysia",
      "value": "Malaysia"
    },
    {
      "key": "MDV",
      "text": "Maldives",
      "value": "Maldives"
    },
    {
      "key": "MLI",
      "text": "Mali",
      "value": "Mali"
    },
    {
      "key": "MLT",
      "text": "Malta",
      "value": "Malta"
    },
    {
      "key": "MHL",
      "text": "Marshall Islands",
      "value": "Marshall Islands"
    },
    {
      "key": "MTQ",
      "text": "Martinique",
      "value": "Martinique"
    },
    {
      "key": "MRT",
      "text": "Mauritania",
      "value": "Mauritania"
    },
    {
      "key": "MUS",
      "text": "Mauritius",
      "value": "Mauritius"
    },
    {
      "key": "MYT",
      "text": "Mayotte",
      "value": "Mayotte"
    },
    {
      "key": "MEX",
      "text": "Mexico",
      "value": "Mexico"
    },
    {
      "key": "FSM",
      "text": "Micronesia (Federated States of)",
      "value": "Micronesia (Federated States of)"
    },
    {
      "key": "MDA",
      "text": "Moldova (Republic of)",
      "value": "Moldova (Republic of)"
    },
    {
      "key": "MCO",
      "text": "Monaco",
      "value": "Monaco"
    },
    {
      "key": "MNG",
      "text": "Mongolia",
      "value": "Mongolia"
    },
    {
      "key": "MNE",
      "text": "Montenegro",
      "value": "Montenegro"
    },
    {
      "key": "MSR",
      "text": "Montserrat",
      "value": "Montserrat"
    },
    {
      "key": "MAR",
      "text": "Morocco",
      "value": "Morocco"
    },
    {
      "key": "MOZ",
      "text": "Mozambique",
      "value": "Mozambique"
    },
    {
      "key": "MMR",
      "text": "Myanmar",
      "value": "Myanmar"
    },
    {
      "key": "NAM",
      "text": "Namibia",
      "value": "Namibia"
    },
    {
      "key": "NRU",
      "text": "Nauru",
      "value": "Nauru"
    },
    {
      "key": "NPL",
      "text": "Nepal",
      "value": "Nepal"
    },
    {
      "key": "NLD",
      "text": "Netherlands",
      "value": "Netherlands"
    },
    {
      "key": "NCL",
      "text": "New Caledonia",
      "value": "New Caledonia"
    },
    {
      "key": "NZL",
      "text": "New Zealand",
      "value": "New Zealand"
    },
    {
      "key": "NIC",
      "text": "Nicaragua",
      "value": "Nicaragua"
    },
    {
      "key": "NER",
      "text": "Niger",
      "value": "Niger"
    },
    {
      "key": "NGA",
      "text": "Nigeria",
      "value": "Nigeria"
    },
    {
      "key": "NIU",
      "text": "Niue",
      "value": "Niue"
    },
    {
      "key": "NFK",
      "text": "Norfolk Island",
      "value": "Norfolk Island"
    },
    {
      "key": "MNP",
      "text": "Northern Mariana Islands",
      "value": "Northern Mariana Islands"
    },
    {
      "key": "NOR",
      "text": "Norway",
      "value": "Norway"
    },
    {
      "key": "OMN",
      "text": "Oman",
      "value": "Oman"
    },
    {
      "key": "PAK",
      "text": "Pakistan",
      "value": "Pakistan"
    },
    {
      "key": "PLW",
      "text": "Palau",
      "value": "Palau"
    },
    {
      "key": "PSE",
      "text": "Palestine, State of",
      "value": "Palestine, State of"
    },
    {
      "key": "PAN",
      "text": "Panama",
      "value": "Panama"
    },
    {
      "key": "PNG",
      "text": "Papua New Guinea",
      "value": "Papua New Guinea"
    },
    {
      "key": "PRY",
      "text": "Paraguay",
      "value": "Paraguay"
    },
    {
      "key": "PER",
      "text": "Peru",
      "value": "Peru"
    },
    {
      "key": "PHL",
      "text": "Philippines",
      "value": "Philippines"
    },
    {
      "key": "PCN",
      "text": "Pitcairn",
      "value": "Pitcairn"
    },
    {
      "key": "POL",
      "text": "Poland",
      "value": "Poland"
    },
    {
      "key": "PRT",
      "text": "Portugal",
      "value": "Portugal"
    },
    {
      "key": "PRI",
      "text": "Puerto Rico",
      "value": "Puerto Rico"
    },
    {
      "key": "QAT",
      "text": "Qatar",
      "value": "Qatar"
    },
    {
      "key": "REU",
      "text": "Réunion",
      "value": "Réunion"
    },
    {
      "key": "ROU",
      "text": "Romania",
      "value": "Romania"
    },
    {
      "key": "RUS",
      "text": "Russian Federation",
      "value": "Russian Federation"
    },
    {
      "key": "RWA",
      "text": "Rwanda",
      "value": "Rwanda"
    },
    {
      "key": "BLM",
      "text": "Saint Barthélemy",
      "value": "Saint Barthélemy"
    },
    {
      "key": "SHN",
      "text": "Saint Helena, Ascension and Tristan da Cunha",
      "value": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
      "key": "KNA",
      "text": "Saint Kitts and Nevis",
      "value": "Saint Kitts and Nevis"
    },
    {
      "key": "LCA",
      "text": "Saint Lucia",
      "value": "Saint Lucia"
    },
    {
      "key": "MAF",
      "text": "Saint Martin (French part)",
      "value": "Saint Martin (French part)"
    },
    {
      "key": "SPM",
      "text": "Saint Pierre and Miquelon",
      "value": "Saint Pierre and Miquelon"
    },
    {
      "key": "VCT",
      "text": "Saint Vincent and the Grenadines",
      "value": "Saint Vincent and the Grenadines"
    },
    {
      "key": "WSM",
      "text": "Samoa",
      "value": "Samoa"
    },
    {
      "key": "SMR",
      "text": "San Marino",
      "value": "San Marino"
    },
    {
      "key": "STP",
      "text": "Sao Tome and Principe",
      "value": "Sao Tome and Principe"
    },
    {
      "key": "SAU",
      "text": "Saudi Arabia",
      "value": "Saudi Arabia"
    },
    {
      "key": "SEN",
      "text": "Senegal",
      "value": "Senegal"
    },
    {
      "key": "SRB",
      "text": "Serbia",
      "value": "Serbia"
    },
    {
      "key": "SYC",
      "text": "Seychelles",
      "value": "Seychelles"
    },
    {
      "key": "SLE",
      "text": "Sierra Leone",
      "value": "Sierra Leone"
    },
    {
      "key": "SGP",
      "text": "Singapore",
      "value": "Singapore"
    },
    {
      "key": "SXM",
      "text": "Sint Maarten (Dutch part)",
      "value": "Sint Maarten (Dutch part)"
    },
    {
      "key": "SVK",
      "text": "Slovakia",
      "value": "Slovakia"
    },
    {
      "key": "SVN",
      "text": "Slovenia",
      "value": "Slovenia"
    },
    {
      "key": "SLB",
      "text": "Solomon Islands",
      "value": "Solomon Islands"
    },
    {
      "key": "SOM",
      "text": "Somalia",
      "value": "Somalia"
    },
    {
      "key": "ZAF",
      "text": "South Africa",
      "value": "South Africa"
    },
    {
      "key": "SGS",
      "text": "South Georgia and the South Sandwich Islands",
      "value": "South Georgia and the South Sandwich Islands"
    },
    {
      "key": "SSD",
      "text": "South Sudan",
      "value": "South Sudan"
    },
    {
      "key": "ESP",
      "text": "Spain",
      "value": "Spain"
    },
    {
      "key": "LKA",
      "text": "Sri Lanka",
      "value": "Sri Lanka"
    },
    {
      "key": "SDN",
      "text": "Sudan",
      "value": "Sudan"
    },
    {
      "key": "SUR",
      "text": "Suriname",
      "value": "Suriname"
    },
    {
      "key": "SJM",
      "text": "Svalbard and Jan Mayen",
      "value": "Svalbard and Jan Mayen"
    },
    {
      "key": "SWZ",
      "text": "Swaziland",
      "value": "Swaziland"
    },
    {
      "key": "SWE",
      "text": "Sweden",
      "value": "Sweden"
    },
    {
      "key": "CHE",
      "text": "Switzerland",
      "value": "Switzerland"
    },
    {
      "key": "SYR",
      "text": "Syrian Arab Republic",
      "value": "Syrian Arab Republic"
    },
    {
      "key": "TWN",
      "text": "Taiwan",
      "value": "Taiwan"
    },
    {
      "key": "TJK",
      "text": "Tajikistan",
      "value": "Tajikistan"
    },
    {
      "key": "TZA",
      "text": "Tanzania, United Republic of",
      "value": "Tanzania, United Republic of"
    },
    {
      "key": "THA",
      "text": "Thailand",
      "value": "Thailand"
    },
    {
      "key": "TLS",
      "text": "Timor-Leste",
      "value": "Timor-Leste"
    },
    {
      "key": "TGO",
      "text": "Togo",
      "value": "Togo"
    },
    {
      "key": "TKL",
      "text": "Tokelau",
      "value": "Tokelau"
    },
    {
      "key": "TON",
      "text": "Tonga",
      "value": "Tonga"
    },
    {
      "key": "TTO",
      "text": "Trinidad and Tobago",
      "value": "Trinidad and Tobago"
    },
    {
      "key": "TUN",
      "text": "Tunisia",
      "value": "Tunisia"
    },
    {
      "key": "TUR",
      "text": "Turkey",
      "value": "Turkey"
    },
    {
      "key": "TKM",
      "text": "Turkmenistan",
      "value": "Turkmenistan"
    },
    {
      "key": "TCA",
      "text": "Turks and Caicos Islands",
      "value": "Turks and Caicos Islands"
    },
    {
      "key": "TUV",
      "text": "Tuvalu",
      "value": "Tuvalu"
    },
    {
      "key": "UGA",
      "text": "Uganda",
      "value": "Uganda"
    },
    {
      "key": "UKR",
      "text": "Ukraine",
      "value": "Ukraine"
    },
    {
      "key": "ARE",
      "text": "United Arab Emirates",
      "value": "United Arab Emirates"
    },
    {
      "key": "GBR",
      "text": "United Kingdom",
      "value": "United Kingdom"
    },
    {
      "key": "UMI",
      "text": "United States Minor Outlying Islands",
      "value": "United States Minor Outlying Islands"
    },
    {
      "key": "USA",
      "text": "United States of America",
      "value": "United States of America"
    },
    {
      "key": "URY",
      "text": "Uruguay",
      "value": "Uruguay"
    },
    {
      "key": "UZB",
      "text": "Uzbekistan",
      "value": "Uzbekistan"
    },
    {
      "key": "VUT",
      "text": "Vanuatu",
      "value": "Vanuatu"
    },
    {
      "key": "VEN",
      "text": "Venezuela (Bolivarian Republic of)",
      "value": "Venezuela (Bolivarian Republic of)"
    },
    {
      "key": "VNM",
      "text": "Vietnam",
      "value": "Vietnam"
    },
    {
      "key": "VGB",
      "text": "Virgin Islands (British)",
      "value": "Virgin Islands (British)"
    },
    {
      "key": "VIR",
      "text": "Virgin Islands (U.S.)",
      "value": "Virgin Islands (U.S.)"
    },
    {
      "key": "WLF",
      "text": "Wallis and Futuna",
      "value": "Wallis and Futuna"
    },
    {
      "key": "ESH",
      "text": "Western Sahara",
      "value": "Western Sahara"
    },
    {
      "key": "YEM",
      "text": "Yemen",
      "value": "Yemen"
    },
    {
      "key": "ZMB",
      "text": "Zambia",
      "value": "Zambia"
    },
    {
      "key": "ZWE",
      "text": "Zimbabwe",
      "value": "Zimbabwe"
    }
  ]
}

module.exports = {
  nationality
}