import React, { PureComponent } from "react";
import { Editor } from "@tinymce/tinymce-react";

export class CustomEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      text: this.props.initialValue ?? this.props.text,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: this.props.initialValue ?? this.props.text,
      });
    }
  }

  handleEditorChange = (e) => {
    let text = e.target.getContent();
    this.setState(
      {
        text: text,
      },
      () => {
        this.props.onUpdateEditor(this.state);
      }
    );
  };

  render() {
    return (
      <>
        <Editor
          name={this.props.name}
          apiKey="s41n8a0vsndmme1hzfn7gocq4xxecw3t5a15nffk8jw7537n"
          cloudChannel="dev"
          initialValue={this.props.text}
          value={this.state.value}
          key={this.props.name}
          init={{
            plugins:
              "link lists image charmap hr anchor imagetools code media mediaembed table paste textcolor colorpicker textpattern",
            toolbar: [
              "undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table tabledelete | link image media | code",
            ],
            min_height: 300,
          }}
          onChange={this.handleEditorChange}
        />
      </>
    );
  }
}

export default CustomEditor;
