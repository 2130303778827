import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from 'semantic-ui-react'

import BlogEditor from "./BlogEditor"

export default class New extends Component {

  constructor(props) {
    
    super(props);

    this.state = {
      loading: false,
      confirmOpen: false,
      confirmProcessing: false,
      blogPost: {
        title: '',
        text: '',
        description: '',
        imgUrl: ''
      }
    };
  }

  openConfirm = (id) => this.setState({ confirmOpen: true, confirmProcessing: false })
  closeConfirm = () => this.setState({ confirmOpen: false, confirmProcessing: false })

  onProvideDataForSave = (data) => {
    
    // build object post to server
    var payload = {
      Text: data.text,
      Title: data.title,
      Description: data.description,
      type: 'Blog',
      ImgUrl: data.imgUrl
    }

    return payload
  }

  onContinueCreate = () => {
    let blogPost = {
      title: '',
      text: '',
      desciption: '',
      imgUrl: ''
    }
    this.setState({
      blogPost: blogPost,
      confirmOpen: false, 
      confirmProcessing: false
    })
  }
  
  onHandleSave = (data) =>{
    let payload = this.onProvideDataForSave(data)

    fetch(`api/Content`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'same-origin',
      body: JSON.stringify(payload)
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 400) {
          this.setState({ loading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          this.setState({ loading: false }, () => {
            this.openConfirm()
          });
        }
      })
      .catch(error => alert(error));
  }

  renderContent() {    
    return (
      <BlogEditor blogPost={this.state.blogPost} onHandleSave={this.onHandleSave}></BlogEditor>
    )
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderContent();
    return (
      <div>
        {contents}
        <Modal size='tiny' open={this.state.confirmOpen}
            onClose={this.closeConfirm}
            className='alert-message-area'
            closeOnEscape={false}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Blog post created</Modal.Header>
            <Modal.Content>
              <p>Do you want to continue to create another post ?</p>
            </Modal.Content>
            <Modal.Actions>
              <Link to="/Admin/Blogs" className="ui button">Back to list</Link>              
              <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} positive onClick={this.onContinueCreate}>Create another post</Button>
            </Modal.Actions>
          </Modal>
      </div>
    )
  }
}
