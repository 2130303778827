import React from "react";
import { Table, Message, Dimmer, Loader, Segment } from "semantic-ui-react";
import TourItem from "../Tour/TourItem";

export default class List extends React.PureComponent {
  onHandleEditData = (id) => {
    this.props.onHandleEditData(id);
  };

  onToggleTour = (id) => {
    this.props.onToggleTour(id);
  };

  renderDataRow(item, index) {
    return (
      <TourItem
        key={item.id}
        id={item.id}
        index={index}
        tour={item}
        onHandleEditData={this.onHandleEditData}
        onToggleTour={this.onToggleTour}
        openConfirm={this.openConfirm}
      />
    );
  }

  openConfirm = (id) => {
    this.props.openConfirm(id);
  };

  renderDataTable(toursData) {
    if (toursData !== undefined && toursData.length > 0) {
      return (
        <Table celled striped compact className="custom-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="wid-per-2">No.</Table.HeaderCell>
              <Table.HeaderCell className="wid-per-20">Title</Table.HeaderCell>
              <Table.HeaderCell className="wid-per-2">Order</Table.HeaderCell>
              <Table.HeaderCell>Summary</Table.HeaderCell>
              <Table.HeaderCell className="wid-per-2">
                Duration
              </Table.HeaderCell>
              <Table.HeaderCell className="wid-per-2">
                End Booking Before (hours)
              </Table.HeaderCell>
              <Table.HeaderCell className="wid-per-5">Price</Table.HeaderCell>
              <Table.HeaderCell className="wid-per-2">Status</Table.HeaderCell>
              <Table.HeaderCell
                className="wid-per-5"
                textAlign="center"
              ></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {toursData.map((item, index) => {
              return this.renderDataRow(item, index);
            })}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <Message
          icon="address card"
          header="There is no Tour in your data."
          content="You can create new Tour by click button [Add New]."
        />
      );
    }
  }

  render() {
    // let contents = this.props.loading
    //   ? <p><em>Loading...</em></p>
    //   : this.renderDataTable(this.props.toursData);

    let loading = this.props.loading;
    let contents = this.renderDataTable(this.props.toursData);

    return (
      <div className="tour-list-table">
        <Dimmer.Dimmable as={Segment} dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          {contents}
        </Dimmer.Dimmable>
      </div>
    );
  }
}
