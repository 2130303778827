import React from 'react'
import { Table, Button, Label } from 'semantic-ui-react'

export default class TourItem extends React.PureComponent{
  
  onEditData = (id) => {
    this.props.onHandleEditData(id)
  }

  onToggleTour = (id) => {
    this.props.onToggleTour(id)
  }

  openConfirm = (id) => {
    this.props.openConfirm(id)
  }

  onViewCalendar = () => {
    alert('this feature is underconstructor')
  }

  render() {
    
    var { id, tour, index } = this.props
    return (
      <Table.Row key={id}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>{tour.title}</Table.Cell>
        <Table.Cell>
            {tour.order}
          </Table.Cell>
        <Table.Cell className='wid-per-40'>{tour.summary}</Table.Cell>
        <Table.Cell className='wid-per-10' >{tour.durationText}</Table.Cell>
        <Table.Cell className='wid-per-10' >{tour.endHoursBooking}</Table.Cell>
        <Table.Cell textAlign='right'>{Number(tour.price).toLocaleString('en')}</Table.Cell>
        <Table.Cell textAlign='center'>{tour.status === 'Active' ? <Label basic color='blue'>{tour.status}</Label> : <Label basic color='grey'>{tour.status}</Label>}</Table.Cell>
        <Table.Cell className='wid-per-5' textAlign='center'>
          <Button.Group basic size='small'>
            <Button icon='calendar alternate outline' data-tooltip="View tour detail calendar" onClick={() => this.onViewCalendar()}/>
            <Button icon='edit outline' id={id} onClick={() => this.onEditData(id)} data-tooltip="Edit tour"/>
            <Button icon={tour.status === 'Active' ? 'eye' : 'eye slash'} data-tooltip="Show/Hide" onClick={() => this.onToggleTour(id)}/>
            <Button icon='trash alternate outline' data-tooltip="Delete tour"  onClick={() => this.openConfirm(id)}/>
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    )
  }
}
