import React from 'react'
import { Header, Segment, Form, Select, Icon } from "semantic-ui-react";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import _ from 'lodash'

import { nationality } from '../../../../data/nationality'

const options = [
  { key: 'm', text: 'Male', value: 'Male' },
  { key: 'f', text: 'Female', value: 'Female' },
]

export default class BookingParticipantForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tourQnA: this.props.tourQnA,
      partIndex: this.props.partIndex,
      isActive: true,
      participantInfo: this.props.participantInfo === undefined ? {
        key: this.props.partKey,
        id: '',
        dob: '',
        fullName: '',
        gender: '',
        passport: '',
        nationality: '',
        phone: '',
        email: '',
        order: '',
      } : this.props.participantInfo,
      answerQnA: this.props.participantInfo === undefined ? {} : this.props.participantInfo.qna,
    }
  }

  toggleContent = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  onHandleChange = (event) => {
    var target = event.target
    var name = target.name
    var value = target.value

    let { participantInfo } = this.state
    participantInfo[name] = value

    this.setState({
      participantInfo: participantInfo,
    }, () => { this.props.onHandleInputBookingParticipant(participantInfo, this.state.answerQnA) })


  }

  onHandleDropdownSelect = (event, data) => {
    var name = data.name
    var value = data.value

    let { participantInfo } = this.state
    participantInfo[name] = value

    this.setState({
      participantInfo: participantInfo,
    })

    this.props.onHandleInputBookingParticipant(participantInfo, this.state.answerQnA)
  }

  onHandleSetDateOfBirth = (date) => {
    if (this.calculateAge(date) < 16) {
      this.props.openErrorModal('Sorry! Guest must be over 16 in order to take part in any tour.')
    } else {
      this.setState({
        participantInfo: {
          ...this.state.participantInfo,
          dob: new Date(date)
        }
      })

      this.props.onHandleInputBookingParticipant(this.state.participantInfo, this.state.answerQnA)
    }
  }

  calculateAge = (birthday) => { // birthday is a date
    let dateSelected = new Date(this.props.bookingSelectedDate)
    let ageDifMs = dateSelected.getTime() - birthday.getTime()
    let ageDate = new Date(ageDifMs) // miliseconds from epoch
    return ageDate.getUTCFullYear() - 1970
  }

  onHandleDropdownQnASelect = (event, data) => {
    let name = data.name
    let value = data.value

    let questionSelect = _.find(this.state.tourQnA, function (question) {
      return question.id === data.name
    })

    let answerSelected = _.find(questionSelect.tourAnswers, function (item) {
      return item.id === data.value
    })

    if (answerSelected && answerSelected.haveFee) {
      let objAnswer = {
        questionId: data.name,
        questionContent: questionSelect.questionContent,
        isDropdown: true,
        name: answerSelected.answerContent,
        value: value,
        fee: answerSelected.fee
      }
      this.setState({
        answerQnA: {
          ...this.state.answerQnA,
          [name]: objAnswer
        }
      }, () => {
        this.props.onHandleInputBookingParticipant(this.state.participantInfo, this.state.answerQnA)
      })
    }
    else {
      let objAnswer = { questionId: data.name, questionContent: questionSelect.questionContent, isDropdown: true, name: answerSelected.answerContent, value: value, fee: 0 }
      this.setState({
        answerQnA: {
          ...this.state.answerQnA,
          [name]: objAnswer
        }
      }, () => {
        this.props.onHandleInputBookingParticipant(this.state.participantInfo, this.state.answerQnA)
      })
    }
  }

  onHandleQnAChange = (event) => {
    let target = event.target
    let name = target.name
    let value = target.value
    let label = target.label

    let objAnswer = { questionId: name, questionContent: label, isDropdown: false, value: value }
    this.setState({
      answerQnA: {
        ...this.state.answerQnA,
        [name]: objAnswer
      }
    }, () => { this.props.onHandleInputBookingParticipant(this.state.participantInfo, this.state.answerQnA) })


  }

  onCheckedQnAChange = (event, { checked }) => {
    let target = event.currentTarget.firstElementChild
    let name = target.name
    let label = target.label

    let objAnswer = { questionId: name, questionContent: label, isDropdown: false, value: checked }
    this.setState({
      answerQnA: {
        ...this.state.answerQnA,
        [name]: objAnswer
      }
    })

    this.props.onHandleInputBookingParticipant(this.state.participantInfo, this.state.answerQnA)
  }

  renderQnAItem = (item, index) => {

    if (item.inputType === 'Checkbox') {
      return (
        <Form.Checkbox
          key={item.id}
          name={item.id}
          inline
          label={item.questionContent}
          required
          onChange={this.onCheckedQnAChange}
          checked={this.state.answerQnA[item.id] === undefined ? false : this.state.answerQnA[item.id].value}
        />
      )
    } else if (item.inputType === 'Input') {
      return (
        <Form.TextArea
          name={item.id}
          key={item.id}
          label={item.questionContent}
          placeholder='Tell us more'
          onChange={this.onHandleQnAChange}
          value={this.state.answerQnA[item.id] === undefined ? '' : this.state.answerQnA[item.id].value}
        />
      )
    } else if (item.inputType === 'Dropdown') {
      let optionArr = []
      item.tourAnswers.forEach((ans) => {
        let optItem = {
          key: ans.id,
          text: ans.answerContent,
          value: ans.id
        }
        optionArr.push(optItem)
      })
      return (
        <Form.Field required key={`question-dropdown-${item.id}`}>
          <label>{item.questionContent}</label>
          <Select
            key={item.id}
            name={item.id}
            onChange={this.onHandleDropdownQnASelect}
            options={optionArr}
            placeholder='Choose an option'
            selection
            value={this.state.answerQnA[item.id] === undefined ? '' : this.state.answerQnA[item.id].value}
          />
        </Form.Field>
      )
    }
  }

  renderQnA = () => {
    if (this.props.tourQnA) {
      return (
        this.props.tourQnA.map((item, index) => {
          return (
            this.renderQnAItem(item, index)
          )
        })
      )
    }
  }

  render() {
    let i = this.props.partIndex
    let renderQnAArea = this.renderQnA()
    let { participantInfo } = this.state
    return (
      <div className='booking-detail-participant-form'>
        <Header as='h4' attached='top' onClick={this.toggleContent}>
          {`Participant ${i + 1}`}
          <Icon name='triangle down' />
        </Header>
        <Segment attached className={this.state.isActive ? 'active' : 'hidden'}>
          <div key={`part-form-${i}`} className='part-form ui form'>

            <Form.Field required>
              <label>Full Name (per passport)</label>
              <input required size='small' name={`fullName`} onChange={this.onHandleChange} value={participantInfo.fullName} autoComplete='off' />
            </Form.Field>
            <Form.Field required>
              <label>Date of birth</label>
              <DatePicker
                key='dob'
                name='dob'
                selected={participantInfo.dob === '' ? '' : new Date(participantInfo.dob)}
                onChange={(date) => this.onHandleSetDateOfBirth(date)}
                dateFormat="MMM dd, yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                size='small'
                autoComplete='off'
                placeholderText="Click to select a date"
              />
            </Form.Field>
            <Form.Field required>
              <label>Passport</label>
              <input required size='small' name={`passport`} onChange={this.onHandleChange} value={participantInfo.passport} autoComplete='off' />
            </Form.Field>
            <Form.Field required>
              <label>Nationality</label>
              <Select
                size='small'
                name='nationality'
                onChange={this.onHandleDropdownSelect}
                options={nationality.list}
                placeholder='Choose an option'
                selection
                value={participantInfo.nationality}
              />
            </Form.Field>
            <Form.Field required>
              <label>Gender</label>
              <Select
                size='small'
                name='gender'
                onChange={this.onHandleDropdownSelect}
                options={options}
                placeholder='Choose an option'
                selection
                value={participantInfo.gender}
              />
            </Form.Field>
            <Form.Field>
              <label>WhatsApp number</label>
              <input size='small' name={`phone`} onChange={this.onHandleChange} value={participantInfo.phone} autoComplete='off' />
            </Form.Field>
            <Form.Field required>
              <label>Email</label>
              <input required type='email' size='small' name={`email`} onChange={this.onHandleChange} value={participantInfo.email} autoComplete='off' />
            </Form.Field>
            <Form.Field >
                <label>Emergency contact name</label>
                <input size='small' name={`emergencyContactName`} onChange={this.onHandleChange} autoComplete='off'/>
              </Form.Field>
              <Form.Field >
                <label>Emergency contact email</label>
                <input type='email' size='small' name={`emergencyContactEmail`} onChange={this.onHandleChange} autoComplete='off'/>
              </Form.Field>
              <Form.Field >
                <label>Emergency contact number</label>
                <input size='small' name={`emergencyContactNumber`} onChange={this.onHandleChange} autoComplete='off'/>
              </Form.Field>
              <Form.Field >
                <label>Insurance Name</label>
                <input size='small' name={`insuranceName`} onChange={this.onHandleChange} autoComplete='off'/>
              </Form.Field>
              <Form.Field >
                <label>Insurance Contact Number</label>
                <input size='small' name={`insuranceContactNumber`} onChange={this.onHandleChange} autoComplete='off'/>
              </Form.Field>
              <Form.Field >
                <label>Insurance Policy Number</label>
                <input size='small' name={`insurancePolicyNumber`} onChange={this.onHandleChange} autoComplete='off'/>
              </Form.Field>
            {renderQnAArea}
          </div>
        </Segment>
      </div>
    )
  }
}
