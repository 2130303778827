import React from 'react'
import { Header, Button, Grid, Icon } from "semantic-ui-react"
import { Editor } from '@tinymce/tinymce-react'

export default class AboutUs extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      content: null
    }
  }

  componentDidMount() {
    fetch('api/Content/GetAboutUsContent')
      .then(response => response.json())
      .then(data => {
        this.setState({ content: data, loading: false });
      });
  }

  handleEditorChange = (e) => {
    let { content } = this.state
    content.text = e.target.getContent()
    this.setState({
      content: content
    })
  }

  onProvideDateForSave = () => {
    let content = this.state.content

    // build object post to server
    var payload = {
      Text: content.text,
      type: 'AboutUs',
    }

    return payload
  }

  onHandleSave = () => {

    let payload = this.onProvideDateForSave()

    // post data to api for edit
    fetch(`api/Content/${this.state.content.id}`, {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'same-origin',
      body: JSON.stringify(payload)
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 400) {
          this.setState({ loading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          this.setState({ loading: false }, () => {
            alert("update content success !");
          });
        }
      })
      .catch(error => alert(error));
  }

  renderContent = () => {
    let contentText = this.state.content ? this.state.content.text : ''
    return (
      <div>
        <Grid divided='vertically'>
          <Grid.Row className='control-area-row'>
            <Grid.Column>
              <Button color='teal' icon labelPosition='left' onClick={this.onHandleSave} >
                Save
                <Icon name='save' />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h3' dividing>
                About Us
              </Header>
              <Editor
                name='aboutuscontent'
                key='aboutuscontent'
                apiKey='s41n8a0vsndmme1hzfn7gocq4xxecw3t5a15nffk8jw7537n'
                cloudChannel='dev'
                initialValue={contentText}
                init={{
                  plugins: 'link lists image charmap preview hr anchor imagetools code media mediaembed table emoticons paste textcolor colorpicker textpattern',
                  toolbar: ['insertfile undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pageembed table tabledelete | link image | code', 'preview media | fontsizeselect fontselect forecolor backcolor emoticons'],
                  min_height: 600
                }}
                onChange={this.handleEditorChange}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderContent();
    return (
      <div>
        {contents}
      </div>
    )
  }
}