import React, { Component } from "react";
import { Grid, Button, Header, Modal, Dimmer, Table, Message, Segment, Loader } from "semantic-ui-react";
import { Link } from 'react-router-dom'
import BlogItem from "./BlogItem";
import { handleResponse } from "../../../_helpers/handle-response";
import { authHeader } from "../../../_helpers/auth-header";


export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blogs: [],
      loading: true,
      deleteConfirmOpen: false,
      deleteIdSelected: "",
      confirmProcessing: false,
    };
  }

  openConfirm = (id) =>
    this.setState({
      deleteConfirmOpen: true,
      deleteIdSelected: id,
      confirmProcessing: false,
    });
  closeConfirm = () =>
    this.setState({
      deleteConfirmOpen: false,
      deleteIdSelected: "",
      confirmProcessing: false,
    });

  componentDidMount() {
    this.getBlogs();
  }

  getBlogs() {
    fetch("api/Content/GetBlogList", {
      method: "GET",
    })
      .then(handleResponse)
      .then((data) => {
        this.setState({ blogs: data, loading: false });
      });
  }

  onDeleteBlog = () => {
    this.setState({ confirmProcessing: true });

    let payload = {
      blogId: this.state.deleteIdSelected,
    };

    fetch(`api/Content/${this.state.deleteIdSelected}`, {
      method: "DELETE",
      headers: authHeader(),
      body: JSON.stringify(payload),
    })
      .then(handleResponse)
      .then((data) => {
        this.setState(
          {
            deleteConfirmOpen: false,
            deleteIdSelected: "",
            confirmProcessing: false,
          },
          () => {
            this.getBlogs(data);
          }
        );
      })
      .catch((error) => {
        alert(`error: ${error} - Something went wrong! Please try again`);
      });
  }

  renderDataRow(item, index) {
    return (
      <BlogItem
        key={item.id}
        id={item.id}
        index={index}
        blog={item}
        onHandleEditData={this.onHandleEditData}
        openConfirm={this.openConfirm}
      />
    );
  }

  renderBlogs(blogs) {
    if (blogs !== undefined && blogs.length > 0) {
      return (
        <Table celled striped compact className="custom-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="wid-per-2">No.</Table.HeaderCell>
              <Table.HeaderCell className="wid-per-20">Title</Table.HeaderCell>
              <Table.HeaderCell className="wid-per-20">Description</Table.HeaderCell>
              <Table.HeaderCell className="wid-per-2">
                Created date
              </Table.HeaderCell>
              <Table.HeaderCell className="wid-per-2">
                Modified date
              </Table.HeaderCell>
              <Table.HeaderCell
                className="wid-per-5"
                textAlign="center"
              ></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {blogs.map((item, index) => {
              return this.renderDataRow(item, index);
            })}
          </Table.Body>
        </Table>
      );
    } else {
      return (
        <Message
          icon="address card"
          header="There is no blog post in your data."
          content="You can create new Post by click button [Add New]."
        />
      );
    }
  }

  renderDeleteConfirmModal() {
    return (
      <Modal
        size="mini"
        open={this.state.deleteConfirmOpen}
        onClose={this.closeConfirm}
        className="alert-message-area"
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Delete Your Bblog Post</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={
              this.state.confirmProcessing === true ? "loading disabled" : ""
            }
            negative
            onClick={this.closeConfirm}
          >
            No
          </Button>
          <Button
            className={
              this.state.confirmProcessing === true ? "loading disabled" : ""
            }
            positive
            onClick={this.onDeleteBlog}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    let { blogs, loading } = this.state;
    const blog = this.renderBlogs(blogs);
    const modalDel = this.renderDeleteConfirmModal();
    return (
      <Dimmer.Dimmable as={Segment} dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Grid divided="vertically">
          <Grid.Row className="control-area-row">
            <Grid.Column>
              <Link to="/Admin/Blogs/New" className="ui teal icon left labeled button">New Post <i aria-hidden="true" className="add icon"></i></Link>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" dividing>
                List Tours
              </Header>
              {blog}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>{modalDel}</Grid.Row>
        </Grid>
      </Dimmer.Dimmable>
    );
  }
}
