import React from 'react'
import { Modal } from 'semantic-ui-react'
import PropTypes from 'prop-types'

class FormModal extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { open, header, content, onClose, customClassName, overideClassName, trigger, size, description } = this.props
    const className = overideClassName || `no-bottom mini-modal detail-modal ${customClassName}`
    return (
      <Modal
        size={size || null}
        open={open}
        trigger={trigger}
        className={className}
        style={this.props.customStyle}
        dimmer={this.props.dimmer}
        closeOnDocumentClick={this.props.closeOnDocumentClick}
        closeOnDimmerClick={this.props.closeOnDimmerClick}
        onClose={onClose}
      >
        <Modal.Header>
          <span className='headerText'>{header}</span>
        </Modal.Header>
        <Modal.Content>
          {description ? <p className='description'>{description}</p> : null}
          {this.props.children || content}
        </Modal.Content>
      </Modal>
    )
  }
}

FormModal.propTypes = {
  open: PropTypes.bool,
  header: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  customStyle: PropTypes.node,
  customClassName: PropTypes.string,
  overideClassName: PropTypes.string,
  closeOnDocumentClick: PropTypes.bool,
  dimmer: PropTypes.bool,
  closeOnDimmerClick: PropTypes.bool,
  trigger: PropTypes.node
}

FormModal.defaultProps = {
  closeOnDocumentClick: false,
  closeOnDimmerClick: true,
  dimmer: true
}

export default FormModal
