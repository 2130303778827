import React, { Component } from 'react'
import { Grid, Button, Segment, Modal, TextArea, Table, Message, Dimmer, Loader, Form, Select, Header } from 'semantic-ui-react'
import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CollaboratorCommissionItem from '../CollaboratorPayment/CollaboratorCommissionItem'

const CollaboratorCommissionStatus = [
  { key: 'all', text: 'All', value: 'All' },
  { key: 'pe', text: 'Pending', value: 'Pending' },
  { key: 'pa', text: 'Paid', value: 'Paid' },
  { key: 'ca', text: 'Cancel', value: 'Cancel' },
]

export default class Index extends Component {

  constructor(props) {
    super(props)

    this.state = {
      collaboratorComissionData: null,
      selectedTourIdFilter: '',
      fromDateFilter: '',
      toDateFilter: '',
      statusFilter: '',
      loading: true,
      markPaymentConfirmOpen: false,
      markPaymentConfirmIdSelected: '',
      confirmProcessing: false,
      confirmNote: '',
      confirmNoteError: false,
    }
  }

  openConfirm = (id) => this.setState({ markPaymentConfirmOpen: true, markPaymentConfirmIdSelected: id, confirmProcessing: false, confirmNote: '', confirmNoteError: false })
  closeConfirm = () => this.setState({ markPaymentConfirmOpen: false, markPaymentConfirmIdSelected: '', confirmProcessing: false, confirmNote: '', confirmNoteError: false })

  componentDidMount() {
    // may be add later for filter
    this.getCollaboratorCommissionData(this.state.selectedTourIdFilter, this.state.fromDateFilter, this.state.toDateFilter, this.state.statusFilter)
  }

  onProvideDataForFilter = () => {
    let payload = {
      TourId: this.state.selectedTourIdFilter,
      From: this.state.fromDateFilter,
      To: this.state.toDateFilter,
      Status: this.state.statusFilter
    }

    return payload
  }

  getCollaboratorCommissionData = (selectedTourIdFilter, fromDateFilter, toDateFilter, statusFilter) => {
    // get data from fillter
    this.setState({
      loading: true
    })

    let payload = this.onProvideDataForFilter()

    fetch(`api/Collaborator/FindCollaboratorCommissions`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ collaboratorComissionData: data, loading: false });
      })
      .catch((error) => {
        alert(error)
        this.setState({ loading: false })
      })
  }

  findData = () => {
    this.getCollaboratorCommissionData(this.state.selectedTourIdFilter, this.state.fromDateFilter, this.state.toDateFilter, this.state.statusFilter)
  }

  onHandleSetFromDateFilter = (date) => {
    this.setState({
      fromDateFilter: date
    })
  }

  onHandleSetToDateFilter = (date) => {
    this.setState({
      toDateFilter: date
    })
  }

  onHandleSetStatusFilter = (event, data) => {
    var name = data.name
    var value = data.value

    this.setState({ [name]: value })
  }

  onMarkPaymentStatusPaid = () => {
    this.setState({ confirmProcessing: true })

    if (this.state.confirmNote === '' || this.state.confirmNote === null) {
      this.setState({ ...this.state, confirmNoteError: true })
    } else {
      let payload = {
        CollaboratorBookingId: this.state.markPaymentConfirmIdSelected,
        ConfirmNote: this.state.confirmNote
      }

      fetch(`api/Collaborator/MarkPaidCollaboratorAsync`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
      })
        .then(handleResponse)
        .then(data => {
          this.setState({ markPaymentConfirmOpen: false, markPaymentConfirmIdSelected: '', confirmProcessing: false, confirmNote: '', confirmNoteError: false }, () => {
            this.findData()
          })

        })
        .catch(error => {
          alert(`error: ${error} - Something went wrong! Please try again`)
          this.setState({ markPaymentConfirmOpen: false, markPaymentConfirmIdSelected: '', confirmProcessing: false, confirmNote: '', confirmNoteError: false })
        })
    }
  }

  onHandleChange = (event) => {
    var target = event.target
    var name = target.name
    var value = target.value

    this.setState({
      ...this.state,
      [name]: value
    })
  }

  renderDataRow(item, index) {
    return <CollaboratorCommissionItem key={item.id} id={item.id} index={index}
      dataItem={item}
      openConfirm={this.openConfirm} />
  }

  renderDataList = (lstData) => {
    if (lstData !== undefined && lstData.length > 0) {
      return (
        <Table key='CollaboratorCommission-list' celled compact className='custom-table max-content'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='wid-per-2'>No.</Table.HeaderCell>
              <Table.HeaderCell>Collaborator Name</Table.HeaderCell>
              <Table.HeaderCell>Collaborator Email</Table.HeaderCell>
              <Table.HeaderCell>Collaborator Phone</Table.HeaderCell>
              <Table.HeaderCell>Account Name</Table.HeaderCell>
              <Table.HeaderCell>Account No</Table.HeaderCell>
              <Table.HeaderCell>Bank Name</Table.HeaderCell>
              <Table.HeaderCell>Bank Department</Table.HeaderCell>
              <Table.HeaderCell>Commission</Table.HeaderCell>
              <Table.HeaderCell>Payment Date</Table.HeaderCell>
              <Table.HeaderCell>Payment Note</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              lstData.map((item, index) => {
                return this.renderDataRow(item, index)
              })
            }
          </Table.Body>
        </Table>
      )
    }
    else {
      return (
        <Message
          icon='cogs'
          header='There is no Commission data'
        />
      )
    }
  }

  render() {

    let contents = this.state.loading
      ? <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
      : this.renderDataList(this.state.collaboratorComissionData)

    return (
      <div key='adm-collaborator-booking-screen' className='collaborator-booking-screen'>
        <Grid divided='vertically'>
          <Grid.Row className='control-area-row'>
            {/* <Grid.Column width={3}>
              <div className='ui form'>
                <Form.Field>
                  <label>Select tour to view collaborator below</label>
                  <Select
                    key='booking-tourfilter'
                    name='tourfilter'
                    onChange={this.onHandleDropdownSelect}
                    options={this.state.listTour}
                    placeholder='Choose an option'
                    selection
                    size='mini'
                  />
                </Form.Field>
              </div>
            </Grid.Column> */}
            <Grid.Column width={2}>
              <div className='ui form'>
                <Form.Field>
                  <label>From</label>
                  <DatePicker
                    key='fromDateFilter'
                    name='fromDateFilter'
                    selected={this.state.fromDateFilter}
                    onChange={(date) => this.onHandleSetFromDateFilter(date)}
                    dateFormat="MMM dd, yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    size='small'
                    autoComplete='off'
                  />
                </Form.Field>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <div className='ui form'>
                <Form.Field>
                  <label>To</label>
                  <DatePicker
                    key='toDateFilter'
                    name='toDateFilter'
                    selected={this.state.toDateFilter}
                    onChange={(date) => this.onHandleSetToDateFilter(date)}
                    dateFormat="MMM dd, yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    size='small'
                    autoComplete='off'
                  />
                </Form.Field>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <div className='ui form'>
                <Form.Field>
                  <label>Payment Status</label>
                  <Select
                    key='statusFilter'
                    name='statusFilter'
                    onChange={this.onHandleSetStatusFilter}
                    options={CollaboratorCommissionStatus}
                    placeholder='Choose an option'
                    selection
                    value={this.state.statusFilter}
                    size='mini'
                  />
                </Form.Field>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <div className='ui form'>
                <Form.Field className='pad-top-23'>
                  <Button positive onClick={this.findData}>Find</Button>
                </Form.Field>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h3' dividing>List collaborator has booking </Header>
              {contents}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Modal size='mini' open={this.state.markPaymentConfirmOpen}
              onClose={this.closeConfirm}
              className='alert-message-area'
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>Mark payment status to PAID</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to mark this data from PENDING to PAID ? If [YES] please input note to textbox below</p>
                <div className={`ui form ${this.state.confirmNoteError === false ? '' : 'error'}`}>
                  <Form.Field>
                    <Message
                      error
                      header='Action Forbidden'
                      content='You must input note to confirm payment for this collaborator.'
                    />
                    <label>Note</label>
                    <TextArea name='confirmNote' placeholder='Note about payment information ...' onChange={this.onHandleChange} value={this.state.confirmNote} />
                  </Form.Field>
                </div>
              </Modal.Content>
              <Modal.Actions>
                <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} negative onClick={this.closeConfirm}>No</Button>
                <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} positive onClick={this.onMarkPaymentStatusPaid}>Yes</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
        </Grid>

      </div>
    )
  }
}
