import React from 'react'
import { Grid, Table, Message, Segment, Dimmer, Loader, Form, Select, Header, Modal, Button, Checkbox } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';
import Detail from '../Booking/Detail'
import FormModal from '../../Common/FormModal'
import BookingItem from '../Booking/BookingItem'

import './booking.scss'
export default class Index extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      selectedTourIdFilter: '',
      fromDateFilter: '',
      toDateFilter: '',
      isGetAll: false,
      listTour: [],
      listTourDropdownInput: [],
      bookingData: [],
      loading: true,
      selectedBookingParticipantData: null,
      deleteConfirmOpen: false,
      deleteIdSelected: '',
      confirmProcessing: false,
    }
  }

  openConfirm = (id) => this.setState({ deleteConfirmOpen: true, deleteIdSelected: id, confirmProcessing: false })
  closeConfirm = () => this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false })

  componentDidMount() {
    fetch('api/Tour/AmGetToursList', {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(data => {
        let listTour = []
        let listTourDropdownInput = []
        let selectedTourIdFilter = ''
        // add 2 option to select all value & general value
        listTour.push({
          key: '5bb2e8ba-912f-4a57-bec3-9218c0e78b43',
          text: 'All',
          value: '5bb2e8ba-912f-4a57-bec3-9218c0e78b43'
        })

        if (data && data.length > 0) {
          data.forEach(element => {
            let tourItem = {
              key: element.id,
              text: element.title,
              value: element.id
            }
            listTour.push(tourItem)
            listTourDropdownInput.push(tourItem)
            selectedTourIdFilter = '5bb2e8ba-912f-4a57-bec3-9218c0e78b43'
          });
        }
        this.setState({
          listTour: listTour,
          listTourDropdownInput: listTourDropdownInput,
          selectedTourIdFilter: selectedTourIdFilter
        }, () => {
          this.getBookingData()
        });
      });
  }

  onHandleDropdownSelect = (event, data) => {
    var name = data.name
    var value = data.value

    if (name === 'tourfilter') {

      if (value !== this.state.selectedTourIdFilter) {
        this.setState({
          selectedTourIdFilter: value,
        })
      }
    }
  }

  getBookingData = () => {

    this.setState({
      loading: true
    })

    let payload = this.onProvideDataForSave()

    fetch(`api/Booking/FindBookings`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ bookingData: data, loading: false });
      })
      .catch((error) => {
        alert(error)
        this.setState({ loading: false })
      });
  }

  onProvideDataForSave = () => {
    let payload = {
      TourId: this.state.selectedTourIdFilter,
      From: this.state.fromDateFilter,
      To: this.state.toDateFilter,
      IsGetAll: this.state.isGetAll,
    }

    return payload
  }

  findBooking = () => {
    this.getBookingData(this.state.selectedTourIdFilter, this.state.fromDateFilter, this.state.toDateFilter)
  }

  onHandleViewParticipant = (bookingId) => {
    // get list participant by bookingId

    fetch(`api/Booking/GetBookingParticipantDetail/${bookingId}`, {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ ...this.state, openModal: true, selectedBookingParticipantData: data })
      });

  }

  onDeleteBooking = () => {

    this.setState({ confirmProcessing: true })

    let payload = {
      BookingId: this.state.deleteIdSelected
    }

    fetch(`api/Booking/DeleteBookingAsync`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false }, () => {
          this.getBookingData(this.state.selectedTourIdFilter)
        })

      })
      .catch(error => {
        alert(`error: ${error} - Something went wrong! Please try again`)
        this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false })
      });
  }

  onHandleCloseModal = () => {
    this.setState({
      openModal: false
    })
  }

  onCancelBooking = (bookingId) => {

    this.setState({ loading: true })

    let payload = {
      BookingId: bookingId
    }

    fetch(`api/Booking/CancelBookingAsync`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(data => {
        this.getBookingData(this.state.selectedTourIdFilter)
      })
      .catch(error => {
        alert(`error: ${error} - Something went wrong! Please try again`)
        this.setState({ loading: false })
      });
  }

  onEditBooking = (bookingId) => {

  }

  onHandleSetFromDateFilter = (date) => {
    this.setState({
      fromDateFilter: date
    })
  }

  onHandleSetToDateFilter = (date) => {
    this.setState({
      toDateFilter: date
    })
  }

  onCheckedChange = (event, { checked }) => {
    this.setState({
      isGetAll: checked
    })
  }

  onCancel = () => {
    this.onHandleCloseModal()
  }

  renderDataRow(item, index) {
    return <BookingItem key={item.id} id={item.id} index={index}
      bookingItem={item}
      onHandleViewParticipant={this.onHandleViewParticipant}
      openConfirm={this.openConfirm}
      onCancelBooking={this.onCancelBooking}
      onEditBooking={this.onEditBooking} 
      matchValue={this.props.match}/>
  }

  renderBookingList = (listBooking) => {
    if (listBooking !== undefined && listBooking.length > 0) {
      return (
        <Table key='participant-list' celled compact className='custom-table max-content'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='wid-per-2'>No.</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-15'>Tour name</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Booking number.</Table.HeaderCell>
              <Table.HeaderCell>Created date</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Total extra fee</Table.HeaderCell>
              <Table.HeaderCell>Discount</Table.HeaderCell>
              <Table.HeaderCell>Sub Total</Table.HeaderCell>
              <Table.HeaderCell>VAT</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Billing Contact</Table.HeaderCell>
              <Table.HeaderCell>Participants</Table.HeaderCell>
              <Table.HeaderCell>Release Input</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Create by Itrek</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              listBooking.map((item, index) => {
                return this.renderDataRow(item, index)
              })
            }
          </Table.Body>
        </Table>
      )
    }
    else {
      return (
        <Message
          icon='cogs'
          header='There is no Bookink data in selected tour.'
          content='You can create new by click button [Create new].'
        />
      )
    }
  }

  render() {
    let contents = this.state.loading
      ? <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
      : this.renderBookingList(this.state.bookingData)

    return (
      <div key='adm-booking-screen' className='Booking-screen'>
        <Grid divided='vertically'>
          <Grid.Row className='control-area-row'>
            <Grid.Column width={3}>
              <div className='ui form'>
                <Form.Field>
                  <label>Select tour to view booking below</label>
                  <Select
                    key='booking-tourfilter'
                    name='tourfilter'
                    onChange={this.onHandleDropdownSelect}
                    options={this.state.listTour}
                    placeholder='Choose an option'
                    selection
                    size='mini'
                  />
                </Form.Field>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <div className='ui form'>
              <Form.Field>
                <label>From</label>
                <DatePicker
                  key='fromDateFilter'
                  name='fromDateFilter'
                  selected={this.state.fromDateFilter}
                  onChange={(date) => this.onHandleSetFromDateFilter(date)}
                  dateFormat="MMM dd, yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  size='small'
                  autoComplete='off'
                />
              </Form.Field>
            </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <div className='ui form'>
                <Form.Field>
                  <label>To</label>
                  <DatePicker
                    key='toDateFilter'
                    name='toDateFilter'
                    selected={this.state.toDateFilter}
                    onChange={(date) => this.onHandleSetToDateFilter(date)}
                    dateFormat="MMM dd, yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    size='small'
                    autoComplete='off'
                  />
                </Form.Field>
              </div>
            </Grid.Column>
            <Grid.Column width={2}>
              <div className='ui form'>
                <Form.Field className='pad-top-23'>
                  <Checkbox
                    label='Included booking in the past'
                    name='isGetAll'
                    ref='isGetAll'
                    checked={this.state.isGetAll}
                    onChange={this.onCheckedChange}
                  />
                </Form.Field>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className='ui form'>
                <Form.Field className='pad-top-23'>
                  <Button positive onClick={this.findBooking}>Find</Button>
                </Form.Field>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as='h3' dividing>List Booking <Link to="/Admin/Booking/Create" target="_blank" className='ui primary button float left'>Create new booking</Link></Header>
              {contents}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>

            <FormModal
              key='participant-modal'
              name='participant-modal'
              open={this.state.openModal}
              size='large'
              closeOnDimmerClick={false}
              header='List detail of participants'
              onClose={this.onHandleCloseModal}
            >
              <Detail key='booking-participant-detail' bookingSelected={this.state.selectedBookingParticipantData} onCancel={this.onCancel} />
            </FormModal>

            <Modal size='mini' open={this.state.deleteConfirmOpen}
              onClose={this.closeConfirm}
              className='alert-message-area'
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>Delete Your Booking</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to delete ? This function will <strong>remove all data of this booking in system</strong>, and you can't find it anymore <br />
                  If you just want to CANCEL this booking, please click button <strong>CANCEL</strong>
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} negative onClick={this.closeConfirm}>No</Button>
                <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} positive onClick={this.onDeleteBooking}>Yes</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
        </Grid>

      </div>
    )
  }
}
