import React, { Component } from 'react'
import { Segment, Dimmer, Loader, Grid, Statistic, Header } from 'semantic-ui-react'
import TableBookingData from './TableBookingData'
import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';

export default class Index extends Component {

  constructor(props) {
    super(props)

    this.state = {
      dashboardData: null,
      loading: true,
    }
  }

  componentDidMount() {
    fetch(`api/Dashboard/Get`, {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ dashboardData: data, loading: false });
      })
      .catch(error => alert(error));
  }

  renderStartInFuture = (data) => {
    return (
      <TableBookingData keyName='start-in-future' bookingData={data} header='Upcoming tour'></TableBookingData>
    )
  }

  renderDepartureTodayList = (data) => {
    return (
      <TableBookingData keyName='departure-today-list' bookingData={data} header='Departure today'></TableBookingData>
    )
  }

  renderReturnTodayList = (data) => {
    return (
      <TableBookingData keyName='return-today-list' bookingData={data} header='Return today'></TableBookingData>
    )
  }

  renderOverviewInfo = (data) => {
    return (
      <>
        <Header as='h2' attached='top'>
          Overview
        </Header>
        <Segment attached>
          <Grid columns={4} stackable textAlign='center' divided>
            <Grid.Row verticalAlign='middle'>
              <Grid.Column>
                <Statistic size='tiny'>
                  <Statistic.Value>{Number(data.totalTour).toLocaleString('en')}</Statistic.Value>
                  <Statistic.Label>Tours</Statistic.Label>
                </Statistic>
              </Grid.Column>
              <Grid.Column>
                <Statistic size='tiny'>
                  <Statistic.Value>{Number(data.totalBooking).toLocaleString('en')}</Statistic.Value>
                  <Statistic.Label>Bookings</Statistic.Label>
                </Statistic>
              </Grid.Column>
              <Grid.Column>
                <Statistic size='tiny'>
                  <Statistic.Value>{Number(data.totalCollaborator).toLocaleString('en')}</Statistic.Value>
                  <Statistic.Label>Collaborators</Statistic.Label>
                </Statistic>
              </Grid.Column>
              <Grid.Column>
                <Statistic size='tiny'>
                  <Statistic.Value>{Number(data.totalSale).toLocaleString('en')}</Statistic.Value>
                  <Statistic.Label>Sales</Statistic.Label>
                </Statistic>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }

  renderDashboard = (data) => {
    return (
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            {this.renderStartInFuture(data.tourBookingGroupByDateInFuture)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            {this.renderDepartureTodayList(data.tourBookingDepartureToday)}
          </Grid.Column>
          <Grid.Column>
            {this.renderReturnTodayList(data.tourBookingReturnToday)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {this.renderOverviewInfo(data)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  render() {
    let contents = this.state.loading
      ? <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
      : this.renderDashboard(this.state.dashboardData)
    return (
      <div>
        {contents}
      </div>
    )
  }
}
