import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { Link } from 'react-router-dom'

export default class BlogItem extends Component {
  onEditData = (id) => {
    this.props.onHandleEditData(id);
  };

  openConfirm = (id) => {
    this.props.openConfirm(id);
  };

  render() {
    var { id, blog, index } = this.props;

    return (
      <Table.Row key={id}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>{blog.title}</Table.Cell>
        <Table.Cell>{blog.description}</Table.Cell>
        <Table.Cell className="wid-per-10">{blog.createdDate}</Table.Cell>
        <Table.Cell className="wid-per-10">{blog.modifiedDate}</Table.Cell>
        <Table.Cell className="wid-per-5" textAlign="center">
          <Button.Group basic size="small">
            <Link
              to={{
                pathname: `/Admin/Blogs/Edit/${id}`,
                state: { fromBlogs: true },
              }}
              className="ui icon button"
              data-tooltip="Edit"
            >
              <i aria-hidden="true" className="edit outline icon"></i>
            </Link>
            <Button
              icon="trash alternate outline"
              data-tooltip="Delete"
              onClick={() => this.openConfirm(id)}
            />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    );
  }
}
