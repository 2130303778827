import React, { PureComponent } from 'react'
import { Form, Select, Segment, Dimmer, Loader, Grid, Table, Message, Label, Button, Icon, Header, Checkbox, Modal } from 'semantic-ui-react'
import { FAQStatus, formProcess, ObjectState, FAQType } from '../../../data/enum'
import Utils from '../../Common/Utils'
import _ from 'lodash'
import './QnA.scss'
import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';

const QnAInputType = [
  { key: 'input', text: 'Input type', value: 'Input' },
  { key: 'dropdown', text: 'Dropdown type', value: 'Dropdown' },
  { key: 'checkbox', text: 'Checkbox type', value: 'Checkbox' },
]

const QnAQuestionType = [
  { key: 'General', text: 'General (apply for all tour)', value: 'General' },
  { key: 'Specification', text: 'Specification (just apply for selected tour)', value: 'Specification' },
]

export default class Index extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      selectedTourIdFilter: '',
      selectedTourId: '',
      listTour: [],
      listTourDropdownInput: [],
      listQnA: [],
      loading: true,
      selectedQnA: {
        questionContent: '',
        order: '',
        inputType: null,
        questionType: null,
        status: FAQStatus.INACTIVE,
        isRequired: false,
        listAnswers: []
      },
      formProcess: formProcess.NEW,
      answerInput: {
        answerContent: '',
        haveFee: false,
        fee: 0,
        order: ''
      },
      selectedAnswerList: [],
      selectingQuestionType: null,
      deleteConfirmOpen: false,
      deleteIdSelected: '',
      confirmProcessing: false,
    }
  }

  openConfirm = (id) => this.setState({ deleteConfirmOpen: true, deleteIdSelected: id, confirmProcessing: false })
  closeConfirm = () => this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false })

  componentDidMount() {
    fetch('api/Tour/AmGetToursList', {
      method: 'GET',
      headers: authHeader()
    })
      .then(response => response.json())
      .then(data => {
        let listTour = []
        let listTourDropdownInput = []
        let selectedTourIdFilter = ''

        // add 2 option to select all value & general value
        listTour.push({
          key: '5bb2e8ba-912f-4a57-bec3-9218c0e78b43',
          text: 'All',
          value: '5bb2e8ba-912f-4a57-bec3-9218c0e78b43'
        })

        listTour.push({
          key: '50d9f87c-f628-4ecb-9330-1f2ce6c29e83',
          text: 'General',
          value: '50d9f87c-f628-4ecb-9330-1f2ce6c29e83'
        })
        if (data && data.length > 0) {
          data.forEach(element => {
            let tourItem = {
              key: element.id,
              text: element.title,
              value: element.id
            }
            listTour.push(tourItem)
            listTourDropdownInput.push(tourItem)
            selectedTourIdFilter = '5bb2e8ba-912f-4a57-bec3-9218c0e78b43'
          });
        }
        this.setState({
          listTour: listTour,
          listTourDropdownInput: listTourDropdownInput,
          loading: false,
          selectedTourIdFilter: selectedTourIdFilter
        }, () => {
          this.getQnAData(selectedTourIdFilter)
        });
      });
  }

  onHandleDropdownSelect = (event, data) => {
    var name = data.name
    var value = data.value

    if (name === 'tourfilter') {

      if (value !== this.state.selectedTourIdFilter) {
        this.setState({
          selectedTourIdFilter: value,
          loading: true,
          formProcess: formProcess.NEW,
          selectedQnA: {
            ...this.state.selectedQnA,
            questionContent: '',
            inputType: null,
            questionType: null,
            order: '',
            status: FAQStatus.INACTIVE,
            listAnswers: []
          },
          answerInput: {
            ...this.state.answerInput,
            answerContent: '',
            haveFee: false,
            fee: 0,
            order: '',
          },
          selectingQuestionType: null,
          selectedTourId: ''
        })

        //this.resetDataInput()
        this.getQnAData(value)
      }


    } else if (name === 'questionType') {
      this.setState({
        selectedQnA: {
          ...this.state.selectedQnA,
          [name]: value,
        },
        selectingQuestionType: value,
      })

    } else if (name === 'inputType') {

      if (value === 'Dropdown') {
        this.setState({
          selectedQnA: {
            ...this.state.selectedQnA,
            [name]: value,
            listAnswers: []
          }
        })
      }
      else {
        this.setState({
          selectedQnA: {
            ...this.state.selectedQnA,
            [name]: value,
          }
        })
      }

    } else if (name === 'tourSelected') {
      this.setState({
        selectedTourId: value,
      })
    }
  }

  onHandleChange = (event) => {
    var target = event.target
    var name = target.name
    var value = target.value

    this.setState({
      selectedQnA: {
        ...this.state.selectedQnA,
        [name]: value,
      }
    })
  }

  onHandleCheckbox = (event, data) => {
    var name = data.name
    var value = data.checked

    this.setState({
      selectedQnA: {
        ...this.state.selectedQnA,
        [name]: value === true ? FAQStatus.ACTIVE : FAQStatus.INACTIVE,
      }
    })
  }

  onHandleCheckboxMandatory = (event, data) => {
    var name = data.name
    var value = data.checked

    this.setState({
      selectedQnA: {
        ...this.state.selectedQnA,
        [name]: value,
      }
    })
  }

  onDeleteAnswer = (event, id) => {
    event.preventDefault()

    let listAnswers = [...this.state.selectedQnA.listAnswers]
    let existItem = _.find(listAnswers, function (item) {
      return item.id === id
    });

    if (existItem) {
      existItem.objectState = ObjectState.DELETE

      this.setState({ ...this.state.selectedQnA, listAnswers: listAnswers })
    }
  }

  onDeleteQuestion = (event, id) => {
    event.preventDefault()

    this.openConfirm(id)
  }

  deleteQuestion = () => {

    this.setState({ confirmProcessing: true })

    let id = this.state.deleteIdSelected

    fetch(`api/Tour/AmDeleteQuestion/${id}`, {
      method: 'DELETE',
      headers: authHeader(),
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false }, () => {
          this.getQnAData(this.state.selectedTourIdFilter)
        })
      })
      .catch(error => alert(error))
  }

  getQnAData = (tourId) => {
    if (tourId === '' || tourId === undefined) {
      this.setState({ listQnA: [], loading: false });
    } else {
      fetch(`api/Tour/AmGetQnAList/${tourId}`, {
        headers: authHeader(),
      })
        .then(response => response.json())
        .then(data => {
          this.setState({ listQnA: data, loading: false });
        })
        .catch(error => alert(error));
    }
  }

  changeStatusValueToBoolean = (value) => {
    return value === FAQStatus.ACTIVE ? true : false
  }

  onHandleAddNew = (event) => {

    event.preventDefault()

    let payload = this.onProvideDataForSave()

    if (this.state.formProcess === formProcess.NEW) {
      // post data to api for add new data
      fetch('api/Tour/PostQuestion', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 400) {
            alert(`error: ${response.status} - ${response.body.title}`)
          } else {
            alert('Create new question success!')
            this.onHandleUpdate(response.body)
          }
        })
        .catch(error => alert(error));
    } else if (this.state.formProcess === formProcess.EDIT) {
      fetch(`api/Tour/PutQuestion/${this.state.selectedQnA.id}`, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 400) {
            alert(`error: ${response.status} - ${response.body.title}`)
          } else {
            alert('Update success!')
            this.onHandleUpdate(response.body)
          }
        })
        .catch(error => alert(error));
    }
  }

  onEditData = (id) => {
    let indexItem = _.findIndex(this.state.listQnA, function (o) { return o.id === id; });
    let itemQnA = { ...this.state.listQnA[indexItem] }
    let selectedQnA = {
      id: itemQnA.id,
      questionContent: itemQnA.questionContent,
      inputType: itemQnA.inputType,
      questionType: itemQnA.questionType,
      status: itemQnA.status,
      isRequired: itemQnA.isRequired,
      order: itemQnA.order,
      listAnswers: itemQnA.tourAnswers
    }

    this.setState({
      formProcess: formProcess.EDIT,
      selectedQnA: selectedQnA,
      selectingQuestionType: itemQnA.questionType,
      selectedTourId: itemQnA.tourId
    })
  }

  onHandleUpdate = (data) => {
    this.resetDataInput()
    this.getQnAData(this.state.selectedTourIdFilter)
  }

  onHandleInputFeeCheckbox = (event, data) => {
    var name = data.name
    var value = data.checked

    this.setState({
      answerInput: {
        ...this.state.answerInput,
        [name]: value,
      }
    })
  }

  onHandleInputAnswerChange = (event) => {
    var target = event.target
    var name = target.name
    var value = target.value

    this.setState({
      answerInput: {
        ...this.state.answerInput,
        [name]: value,
      }
    })
  }

  onProvideDataForSave = () => {
    let { selectedQnA } = this.state

    // build object post to server
    var payload = {
      TourId: this.state.selectedTourId,
      QuestionContent: selectedQnA.questionContent,
      QuestionType: selectedQnA.questionType,
      IsRequired: selectedQnA.isRequired,
      InputType: selectedQnA.inputType,
      Order: selectedQnA.order,
      Status: selectedQnA.status,
      TourAnswers: selectedQnA.listAnswers
    }
    return payload
  }

  resetDataInput = () => {
    let { selectedQnA, answerInput } = this.state
    selectedQnA.id = ''
    selectedQnA.questionContent = ''
    selectedQnA.inputType = null
    selectedQnA.questionType = null
    selectedQnA.order = ''
    selectedQnA.status = FAQStatus.INACTIVE
    selectedQnA.isRequired = false
    selectedQnA.listAnswers = []

    answerInput.answerContent = ''
    answerInput.fee = ''
    answerInput.order = ''
    answerInput.haveFee = false

    this.setState({
      ...this.state,
      selectedQnA: selectedQnA,
      answerInput: answerInput,
      formProcess: formProcess.NEW,
      selectedTourId: '',
      selectingQuestionType: null
    })
  }

  onHandleAddNewAnswer = () => {
    let listAnswers = [...this.state.selectedQnA.listAnswers]

    listAnswers.push({ ...this.state.answerInput, objectState: ObjectState.ADDED, id: Utils.createGuid() })

    this.setState({
      selectedQnA: {
        ...this.state.selectedQnA,
        listAnswers: listAnswers,
      },
      answerInput: {
        answerContent: '',
        haveFee: false,
        fee: 0,
        order: ''
      }
    })
  }

  onReOrderAnswer = (event, id, step) => {

    event.preventDefault()

    let listAnswers = [...this.state.selectedQnA.listAnswers]

    let existItem = _.find(listAnswers, function (item) {
      return item.id === id
    });

    if (existItem) {
      let orderStep = existItem.order + step
      if (orderStep > 0) {
        existItem.order = orderStep
        existItem.objectState = ObjectState.MODIFIED
      }
    }

    this.setState({ ...this.state.selectedQnA, listAnswers: listAnswers })
  }

  renderLabelAnswer = (tourAnswers) => {
    if (tourAnswers) {
      return (
        <Label color='teal'>
          <Icon name='list alternate outline' /> {tourAnswers.length}
          <Label.Detail>options</Label.Detail>
        </Label>
      )
    }
    else {
      return (
        <Label>
          <Icon name='list alternate outline' /> 0
          <Label.Detail>options</Label.Detail>
        </Label>
      )
    }
  }

  renderQnAListRow = (row, index) => {
    let labelAnswer = row.inputType === 'Dropdown' ? this.renderLabelAnswer(row.tourAnswers) : ''
    if (row.objectState !== ObjectState.DELETE) {
      return (
        <Table.Row key={row.id}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>{row.questionContent}</Table.Cell>
          <Table.Cell>{row.questionType === FAQType.GENERAL ? <Label basic color='green'>{row.questionType}</Label> : <Label basic color='pink'>{row.questionType}</Label>}</Table.Cell>
          <Table.Cell>{row.tour ? row.tour.title : ''}</Table.Cell>
          <Table.Cell>{row.inputType}</Table.Cell>
          <Table.Cell>
            {row.order}
          </Table.Cell>
          <Table.Cell>{row.status === 'Active' ? <Label basic color='blue'>{row.status}</Label> : <Label basic color='grey'>{row.status}</Label>}</Table.Cell>
          <Table.Cell>{row.isRequired === true ? <Label basic color='blue'>Mandatory</Label> : <Label basic color='grey'>Not mandatory</Label>}</Table.Cell>
          <Table.Cell>{labelAnswer}</Table.Cell>
          <Table.Cell className='wid-per-5' textAlign='center'>
            <Button.Group basic size='small'>
              <Button key={`edit-${row.id}`} icon='edit outline' data-tooltip="Edit question" id={`edit-${row.id}`} onClick={() => this.onEditData(row.id)} />
              <Button key={`delete-${row.id}`} icon='trash alternate outline' data-tooltip="Delete question" id={`delete-${row.id}`} onClick={(e) => this.onDeleteQuestion(e, row.id)} />
            </Button.Group>
          </Table.Cell>
        </Table.Row >
      )
    }
  }

  renderAnswerRow = (row, index) => {
    if (row.objectState !== ObjectState.DELETE) {
      return (
        <Table.Row key={`answ-${index}`}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>{row.answerContent}</Table.Cell>
          <Table.Cell>{row.haveFee === true ? <Label> <Icon name='dollar' /> Extras fee </Label> : ''}</Table.Cell>
          <Table.Cell>{row.fee}</Table.Cell>
          <Table.Cell>
            {row.order}
            <Button.Group size='mini'>
              {index === 0 ? '' : <Button key={`answ-ord-up-${index}`} color='teal' icon='chevron up' data-tooltip='order up' id={`answ-ord-${index}`} onClick={(e) => this.onReOrderAnswer(e, row.id, -1)} />}
              {index === this.state.selectedQnA.listAnswers.length - 1 ? '' : <Button key={`answ-ord-down-${index}`} color='teal' icon='chevron down' data-tooltip='order down' id={`answ-ord-${index}`} onClick={(e) => this.onReOrderAnswer(e, row.id, 1)} />}
            </Button.Group>
          </Table.Cell>
          <Table.Cell className='wid-per-5' textAlign='center'>
            <Button.Group basic size='mini'>
              <Button key={`answ-${index}`} icon='trash alternate outline' data-tooltip="delete this answer" id={`answ-${index}`} onClick={(e) => this.onDeleteAnswer(e, row.id)} />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      )
    }
  }

  renderAnswerList = () => {
    let sortedArray = _.sortBy(this.state.selectedQnA.listAnswers, [function (item) { return item.order }])
    return (
      <Table compact collapsing className='custom-table'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>No.</Table.HeaderCell>
            <Table.HeaderCell>Answer Content</Table.HeaderCell>
            <Table.HeaderCell>Have Fee</Table.HeaderCell>
            <Table.HeaderCell>Fee</Table.HeaderCell>
            <Table.HeaderCell>Order</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            sortedArray.map((item, index) => {
              return this.renderAnswerRow(item, index)
            })
          }
        </Table.Body>
      </Table>
    )
  }

  renderQnAList = (listQnA) => {
    if (listQnA !== undefined && listQnA.length > 0) {
      return (
        <Table celled striped compact className='custom-table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='wid-per-2'>No.</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-20'>Question Title</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5'>Question Type</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-15'>Tour</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5'>Input Type</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-2'>Order</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5'>Status</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5'>Is Required</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5'>List Answers <br />(only type dropdown has)</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5' textAlign='center'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              listQnA.map((item, index) => {
                return this.renderQnAListRow(item, index)
              })
            }
          </Table.Body>
        </Table>
      )
    }
    else {
      return (
        <Message
          icon='cogs'
          header='There is no Questions and Answers data in selected tour.'
          content='You can create new by click button [Create new].'
        />
      )
    }
  }

  renderDropdownAnswerAreaControl = () => {
    return (
      <div className='answer-form'>
        <Form.Group inline>
          <Form.Input required name='answerContent' size='mini' value={this.state.answerInput.answerContent} fluid label='Answer Content' placeholder='Answer Content' onChange={this.onHandleInputAnswerChange} width={3} />
          <Form.Input key='input-answer-order' type='number' required name='order' size='mini' value={this.state.answerInput.order} fluid label='Order' placeholder='Order' onChange={this.onHandleInputAnswerChange} width={3} />
          <Form.Field width={2}>
            <Checkbox name='haveFee' size='mini' toggle label='Have Extra Fee' onChange={this.onHandleInputFeeCheckbox} checked={this.state.answerInput.haveFee} />
          </Form.Field>
          <Form.Input required name='fee' size='mini' value={this.state.answerInput.fee} disabled={!this.state.answerInput.haveFee} fluid label='Extra Fee' placeholder='Extra Fee' onChange={this.onHandleInputAnswerChange} width={3} />
          <Button color='violet' size='mini' key='btnAddNewAnswer' icon labelPosition='left' onClick={this.onHandleAddNewAnswer}>Add answer <Icon name='chevron right' /></Button>
        </Form.Group>
        {this.renderAnswerList()}
      </div>
    )
  }

  onHandleCancel = () => {
    this.setState({
      answerInput: {
        answerContent: '',
        haveFee: false,
        fee: 0,
        order: '',
      },
      formProcess: formProcess.NEW,
      selectedQnA: {
        questionContent: '',
        order: '',
        inputType: null,
        questionType: null,
        status: FAQStatus.INACTIVE,
        isRequired: false,
        listAnswers: []
      },
      selectedTourId: '',
      selectingQuestionType: null
    })
  }

  renderDropdownSelectTourArea = () => {
    return (
      <Form.Field required width={4}>
        <label>For Tour</label>
        <Select
          name='tourSelected'
          onChange={this.onHandleDropdownSelect}
          options={this.state.listTourDropdownInput}
          placeholder='Choose an option'
          selection
          value={this.state.selectedTourId}
        />
      </Form.Field>
    )

  }

  renderInsertQuestionForm = () => {
    let buttonText = this.state.formProcess === formProcess.EDIT ? 'Update' : 'Add new Question'
    let statusValue = this.changeStatusValueToBoolean(this.state.selectedQnA.status)
    let inputDropdownArea = this.state.selectedQnA.inputType === 'Dropdown' ? this.renderDropdownAnswerAreaControl() : <div></div>
    let selectTourInsertArea = this.state.selectingQuestionType === FAQType.SPECIFICATION ? this.renderDropdownSelectTourArea() : <div></div>
    return (
      <Grid.Row className={this.state.formProcess === formProcess.EDIT ? 'edit-form' : ''}>
        <Grid.Column>
          <Header as='h3' dividing>{this.state.formProcess === formProcess.EDIT ? 'Update Question' : 'Insert new Question'}</Header>
          <div className="ui form">
            <Form.Group>
              <Form.Input required name='questionContent' value={this.state.selectedQnA.questionContent} fluid label='Question Content' placeholder='Question Content' onChange={this.onHandleChange} width={4} />
              <Form.Field required width={4}>
                <label>Question Type</label>
                <Select
                  name='questionType'
                  onChange={this.onHandleDropdownSelect}
                  options={QnAQuestionType}
                  placeholder='Choose an option'
                  selection
                  value={this.state.selectedQnA.questionType}
                />
              </Form.Field>
              <Form.Field required width={4}>
                <label>Input Type</label>
                <Select
                  name='inputType'
                  onChange={this.onHandleDropdownSelect}
                  options={QnAInputType}
                  placeholder='Choose an option'
                  selection
                  value={this.state.selectedQnA.inputType}
                />
              </Form.Field>
              {selectTourInsertArea}
              <Form.Input key='input-question-order' type='number' required name='order' value={this.state.selectedQnA.order} fluid label='Order' placeholder='Order' onChange={this.onHandleChange} width={3} />
              <Form.Field width={4}>
                <Checkbox name='status' toggle label='Status' onChange={this.onHandleCheckbox} checked={statusValue} />
                <Checkbox name='isRequired' toggle label='Mandatory' onChange={this.onHandleCheckboxMandatory} checked={this.state.selectedQnA.isRequired} />
              </Form.Field>
            </Form.Group>
            {inputDropdownArea}
            <Form.Group inline>
              <Button color='teal' key='btnAddNew' icon labelPosition='left' onClick={this.onHandleAddNew}>{buttonText} <Icon name='chevron right' /></Button>
              <Button key='btnCancel' onClick={this.onHandleCancel}>Cancel</Button>
            </Form.Group>
          </div>
        </Grid.Column>
      </Grid.Row>

    )
  }

  render() {
    let contents = this.state.loading
      ? <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
      : this.renderQnAList(this.state.listQnA)
    let insertQuestionFormRendered = this.state.selectedTourIdFilter ? this.renderInsertQuestionForm() : <div></div>
    return (
      <div className='QnA-screen'>
        <Grid divided='vertically'>
          <Grid.Row className='control-area-row'>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='3'>
            <Grid.Column>
              <div className='ui form'>
                <Form.Field>
                  <label>Select tour to view question below</label>
                  <Select
                    key='qna-tourfilter'
                    name='tourfilter'
                    onChange={this.onHandleDropdownSelect}
                    options={this.state.listTour}
                    placeholder='Choose an option'
                    selection
                  />
                </Form.Field>
              </div>
            </Grid.Column>
          </Grid.Row>
          {insertQuestionFormRendered}
          <Grid.Row>
            <Grid.Column>
              <Header as='h3' dividing>List Q&A</Header>
              {contents}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal size='mini' open={this.state.deleteConfirmOpen}
          onClose={this.closeConfirm} className='alert-message-area'
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Delete Your Question</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete ?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} negative onClick={this.closeConfirm}>No</Button>
            <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} positive onClick={this.deleteQuestion}>Yes</Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}
