import React, { PureComponent } from "react";
import {
  Form,
  Select,
  Segment,
  Dimmer,
  Loader,
  Grid,
  Card,
  Message,
  Image,
  Button,
  Icon,
  Progress,
  Confirm,
} from "semantic-ui-react";
import { Uploader, UploadField } from "@navjobs/upload";
import "./ImageManagement.scss";
import {
  authHeader,
  authHeaderFileUpload,
} from "../../../_helpers/auth-header";

export default class Index extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      listTour: [],
      listImage: [],
      uploadedImage: {},
      loading: true,
      selectedTourId: "",
      isChanged: false,
      open: false,
      selectedIdImage: "",
    };
  }

  open = (id) => this.setState({ open: true, selectedIdImage: id });
  close = () => this.setState({ open: false });

  componentDidMount() {
    fetch("api/Tour/AmGetToursList", {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => response.json())
      .then((data) => {
        let listTour = [];
        if (data && data.length > 0) {
          data.forEach((element) => {
            let tourItem = {
              key: element.id,
              text: element.title,
              value: element.id,
            };
            listTour.push(tourItem);
          });
        }
        this.setState({ listTour: listTour, loading: false });
      });
  }

  onHandleDropdownSelect = (event, data) => {
    var value = data.value;

    let { selectedTourId } = this.state;
    selectedTourId = value;

    this.setState({
      selectedTourId: selectedTourId,
      loading: true,
    });

    this.getImageData(selectedTourId);
  };

  getImageData = (tourId) => {
    fetch(`api/Tour/AmGetToursFiles/${tourId}`, {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ listImage: data, loading: false, isChanged: false });
      })
      .catch((error) => alert(error));
  };

  onDeleteImageConfirmed = () => {
    let id = this.state.selectedIdImage;

    this.setState({ loading: true });
    fetch(`api/Tour/AmDeleteImage/${id}`, {
      method: "DELETE",
      headers: authHeader(),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 400) {
          this.setState({ loading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          this.setState({ loading: false }, () => {
            this.getImageData(this.state.selectedTourId);
          });
        }
        this.close();
      })
      .catch((error) => {
        alert(error);
        this.close();
      });
  };

  onDeleteImage = (id) => {
    this.open(id);
  };

  onSetImageAsThumbnail = (id) => {
    this.setState({ loading: true });
    let payload = {
      tourId: this.state.selectedTourId,
      fileId: id,
    };

    fetch(`api/Tour/AmSetImageAsThumbnail`, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(payload),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 400) {
          this.setState({ loading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          this.setState({ loading: false }, () => {
            this.getImageData(this.state.selectedTourId);
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false }, () => {
          alert(`error: ${error}`);

        })
      });
  };

  renderImageItem = (item) => {
    if (item.isThumbnail === true) {
      return (
        <Image
          src={item.url}
          wrapped
          ui={false}
          label={{ as: "a", corner: "left", color: "red", icon: "heart" }}
        />
      );
    } else {
      return <Image src={item.url} wrapped ui={false} />;
    }
  };

  renderImageList = (listImageData) => {
    if (listImageData !== undefined && listImageData.length > 0) {
      return (
        <Card.Group itemsPerRow={5}>
          {listImageData.map((item, index) => {
            let image = this.renderImageItem(item);
            return (
              <Card key={`tour-img-${index}`}>
                {image}
                <Card.Content extra>
                  <Button
                    size="tiny"
                    onClick={() => this.onDeleteImage(item.id)}
                  >
                    <Icon name="delete" />
                    Delete
                  </Button>
                  <Button
                    size="tiny"
                    onClick={() => this.onSetImageAsThumbnail(item.id)}
                  >
                    <Icon name="star" />
                    Show as Thumbnail
                  </Button>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      );
    } else {
      return (
        <Message
          icon="images"
          header="There is no Image in selected tour."
          content="You can upload new image for this tour."
        />
      );
    }
  };

  renderUploadButton = (selectedTourId) => {
    return (
      <Uploader
        request={{
          fileName: "file",
          url: `api/Tour/AmUploadFiles/${selectedTourId}`,
          method: "POST",
          fields: {
            //extra fields to pass with the request
            tourId: selectedTourId,
          },
          headers: authHeaderFileUpload(),
          // use credentials for cross-site requests
          withCredentials: false,
        }}
        onComplete={({ response, status }) => {
          //reload image list after upload complete
          if (
            selectedTourId &&
            selectedTourId !== undefined &&
            selectedTourId !== ""
          ) {
            this.getImageData(selectedTourId);
          }
        }}
        uploadOnSelection={true}
      >
        {({ onFiles, progress, complete }) => (
          <div>
            <UploadField
              onFiles={onFiles}
              containerProps={{
                className: "upload-image-area",
              }}
              uploadProps={{
                accept: ".jpg, jpeg, .png, .svg, .bmp",
              }}
            >
              <div className={`ui icon left labeled button teal `}>
                <i aria-hidden="true" className="upload icon"></i>
                Click here to select a file!
              </div>
            </UploadField>
            {progress ? (
              <Progress
                className="upload-file-progess-panel"
                percent={progress}
                size="tiny"
                {...(complete ? "success" : null)}
              >
                {`${progress}%`}
              </Progress>
            ) : null}
            {complete ? "Complete!" : null}
          </div>
        )}
      </Uploader>
    );
  };

  render() {
    let contents = this.state.loading ? (
      <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
    ) : (
      this.renderImageList(this.state.listImage)
    );
    let selectedTourId = this.state.selectedTourId;
    let uploadAreaRender =
      selectedTourId && selectedTourId !== "" ? (
        this.renderUploadButton(selectedTourId)
      ) : (
        <div></div>
      );
    return (
      <div>
        <Grid divided="vertically">
          <Grid.Row className="control-area-row">
            <Grid.Column>{uploadAreaRender}</Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <div className="ui form">
                <Form.Field>
                  <label>Tour</label>
                  <Select
                    name="tour"
                    onChange={this.onHandleDropdownSelect}
                    options={this.state.listTour}
                    placeholder="Choose an option"
                  />
                </Form.Field>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment inverted color="teal" size="tiny">
                Lưu ý: hình ảnh khi upload cần phải đảm bảo các tiêu chí sau để
                đảm bảo tốc độ load trang:
                <ul>
                  <li>
                    độ phân giải không vượt quá 1600px (cả chiều ngang và chiều
                    dọc).
                  </li>
                  <li>kích thước file ảnh không được vượt quá 600kb.</li>
                  <li>tên file cần phải không có khoảng trắng.</li>
                </ul>
                Có thể vào trang web <a href="https://www.iloveimg.com/">https://www.iloveimg.com/</a> để resize và nén hình ảnh cho đúng kích thước (làm online, miễn phí)
              </Segment>
              {contents}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Confirm
          className="alert-message-area"
          open={this.state.open}
          onCancel={this.close}
          onConfirm={this.onDeleteImageConfirmed}
          size="mini"
        />
      </div>
    );
  }
}
