import React from "react";
import { Form, Button, Icon, Message } from "semantic-ui-react";

export class ContentInpupt extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      id: this.props.id,
      content: this.props.content,
      type: this.props.type,
      title: this.props.title,
      description: this.props.description
    };
  }

  onProvideDateForSave = () => {
    // build object post to server
    var payload = {
      Text: this.state.content,
      Type: this.state.type,
    };

    return payload;
  };

  onHandleChange = (event) => {
    var target = event.target;
    var value = target.value;

    this.setState({
      content: value,
    });
  };

  onHandleSave = () => {
    let payload = this.onProvideDateForSave();

    // post data to api for edit
    fetch(`api/Content/${this.state.id}`, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "same-origin",
      body: JSON.stringify(payload),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 400) {
          this.setState({ loading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          this.setState({ loading: false }, () => {
            alert("update content success !");
          });
        }
      })
      .catch((error) => alert(error));
  };

  render() {
    let { type, content, title, description } = this.state;
    return (
      <div className="container">
        <Form className="config-block">
          <Form.Field required>
            <label>{title} <span><i>{description}</i></span></label>            
            <input
              required
              name={type}
              onChange={this.onHandleChange}
              value={content}
            />
            <Button color="teal" icon tiny onClick={this.onHandleSave}>
              <Icon name="save" />
            </Button>
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default ContentInpupt;
