import React from 'react'
import { Table, Button, List, Label, Icon } from 'semantic-ui-react'
import Moment from 'react-moment';

export default class BookingItem extends React.PureComponent {

  constructor(props) {
    super(props);

    let pathEditBooking = this.props.matchValue.path
    if (pathEditBooking === undefined || pathEditBooking === '' || pathEditBooking !== '/Admin/Booking/Edit') {
      pathEditBooking = '/Admin/Booking/Edit'
    }

    this.state = { path: pathEditBooking };
  }

  calculateReleaseInput = (bookingParticipants) => {
    let countInput = 0
    bookingParticipants.forEach(item => {
      if (item.isInputReleaseForm === true) {
        countInput++
      }
    })

    return countInput;
  }

  openConfirm = (event, bookingId) => {
    event.preventDefault()

    this.props.openConfirm(bookingId)
  }

  onCancelBooking = (bookingId) => {
    this.props.onCancelBooking(bookingId)
  }

  onEditBooking = (bookingId) => {
    //this.props.onEditBooking(bookingId)
    let path = `${this.state.path}/${bookingId}`
    window.open(path, '_blank')
  }

  onViewParticipantDetail = (bookingId) => {
    this.props.onHandleViewParticipant(bookingId)
  }

  renderStatus = (status) => {
    if (status === 'Paid') {
      return (
        <Label color='green'>{status}</Label>
      )
    } else if (status === 'Pending') {
      return (
        <Label basic color='grey'>{status}</Label>
      )
    }
    else if (status === 'Cancel') {
      return (
        <Label color='Grey'>{status}</Label>
      )
    }
    else if (status === 'PaidError') {
      return (
        <Label color='Red'>{status}</Label>
      )
    }
    else if (status === 'CancelPayment') {
      return (
        <Label color='Orange'>{status}</Label>
      )
    }
    else {
      return ''
    }
  }

  render() {

    let { id, bookingItem, index } = this.props
    let createdDate = new Date(bookingItem.createdDateLocalTime)
    let startDate = new Date(bookingItem.tourDetail.dateBooking)
    let endDate = new Date(bookingItem.tourDetail.dateBookingEnd)
    let startTime = new Date(bookingItem.tourDetail.tour.departureTime)
    let endTime = new Date(bookingItem.tourDetail.tour.returnTime)

    let subTotal = Number(bookingItem.bookingPrice) + Number(bookingItem.totalExtraFee) - Number(bookingItem.totalDiscountPrice)

    let createByItrek = bookingItem.createByItrek === true ? 'active' : ''

    return (
      <Table.Row key={id} className={createByItrek}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>{bookingItem.tourDetail.tour.title}</Table.Cell>
        <Table.Cell>
          <List key={`list-item-contact-${id}`}>
            <List.Item>
              <List.Icon name='calendar alternate outline' />
              <List.Content><strong>Start: </strong><Moment format="LL">{startDate}</Moment> <Moment format="LT">{startTime}</Moment></List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='clock outline' />
              <List.Content>
                <List.Content><strong>End: </strong><Moment format="LL">{endDate}</Moment> <Moment format="LT">{endTime}</Moment></List.Content>
              </List.Content>
            </List.Item>
          </List>
        </Table.Cell>
        <Table.Cell>{bookingItem.bookingNumber}</Table.Cell>
        <Table.Cell><Moment format="MMM DD, YYYY hh:mm A" date={createdDate} /></Table.Cell>
        <Table.Cell textAlign='right'>{Number(bookingItem.bookingPrice).toLocaleString('en')}</Table.Cell>
        <Table.Cell textAlign='right'>{Number(bookingItem.totalExtraFee).toLocaleString('en')}</Table.Cell>
        <Table.Cell>
          {
            bookingItem.createByItrek === true ?
              bookingItem.promoPrice !== null && bookingItem.promoPrice !== ''
                ? Number(bookingItem.totalDiscountPrice).toLocaleString('en')
                : <></>
              : bookingItem.promoCode !== null && bookingItem.promoCode !== ''
                ? <List key={`list-item-discount-${id}`}>
                  <List.Item>
                    <List.Content>Code: {bookingItem.promoCode}</List.Content>
                  </List.Item>
                  {
                    bookingItem.promoPrice === null
                      ? <List.Item>
                          <List.Content>Percent: {Number(bookingItem.promoPercent).toLocaleString('en')}% on list price </List.Content>
                        </List.Item>
                      : <List.Item>
                          <List.Content>Price: {Number(bookingItem.promoPrice).toLocaleString('en')}/ persons x {bookingItem.bookingNumParticipant}</List.Content>
                        </List.Item>
                  }
                  <List.Item>
                    <List.Content>Total: {Number(bookingItem.totalDiscountPrice).toLocaleString('en')}</List.Content>
                  </List.Item>
                </List>
                : <></>
          }

        </Table.Cell>
        <Table.Cell textAlign='right'>{Number(subTotal).toLocaleString('en')}</Table.Cell>
        <Table.Cell textAlign='right'>{Number(bookingItem.vatPrice).toLocaleString('en')}</Table.Cell>
        <Table.Cell textAlign='right'>{Number(bookingItem.total).toLocaleString('en')}</Table.Cell>
        <Table.Cell>
          <List key={`list-item-contact-${id}`}>
            <List.Item>
              <List.Icon name='user outline' />
              <List.Content>{bookingItem.billingContactFirstName} {bookingItem.billingContactLastName}</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='mail outline' />
              <List.Content>
                <a href={'mailto:' + bookingItem.billingContactEmail}>{bookingItem.billingContactEmail}</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='mobile alternate' />
              <List.Content>
                {bookingItem.billingContactPhone}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name='map marker alternate' />
              <List.Content>
                {bookingItem.conutry}
              </List.Content>
            </List.Item>
          </List>
        </Table.Cell>
        <Table.Cell>
          <Label>
            <Icon name='users' />
            {bookingItem.bookingNumParticipant}
          </Label>
        </Table.Cell>
        <Table.Cell >
          <Label>
            <Icon name='wpforms' />
            {this.calculateReleaseInput(bookingItem.bookingParticipants)} / {bookingItem.bookingNumParticipant}
          </Label>
        </Table.Cell>
        <Table.Cell textAlign='center'>{this.renderStatus(bookingItem.status)}</Table.Cell>
        <Table.Cell textAlign='center'>{bookingItem.createByItrek === true ? <Icon color='blue' name='check circle outline' /> : <></>} </Table.Cell>
        <Table.Cell className='wid-per-5' textAlign='center'>
          <Button.Group primary size='tiny'>
            <Button key={`view-${bookingItem.id}`} icon='users' data-tooltip="View detail" id={`view-${bookingItem.id}`} onClick={() => this.onViewParticipantDetail(id)} />
            <Button key={`edit-${bookingItem.id}`} icon='edit outline' data-tooltip="Edit booking" id={`edit-${bookingItem.id}`} onClick={() => this.onEditBooking(id)} />
            <Button key={`cancel-${bookingItem.id}`} icon='cancel' data-tooltip="Cancel booking" id={`cancel-${bookingItem.id}`} onClick={() => this.onCancelBooking(id)} />
            <Button key={`delete-${bookingItem.id}`} icon='trash alternate outline' data-tooltip="Delete booking" id={`delete-${bookingItem.id}`} onClick={(e) => this.openConfirm(e, id)} />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    )
  }
}
