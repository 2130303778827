import React, { Component } from "react";
import BlogEditor from "./BlogEditor";
import { authHeader } from '../../../_helpers/auth-header';

export default class Edit extends Component {
  constructor(props) {
    super(props);

    let blogId = this.props.match.params.blogId;

    this.state = {
      blogId: blogId,
      loading: true,
      blogPost: null,
      btnLoading: false,
    };
  }

  componentDidMount() {
    fetch(`api/Content/GetBlogById/${this.state.blogId}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ blogPost: data, loading: false });
      });
  }

  onProvideDataForSave = (data) => {
    // build object post to server
    var payload = {
      Text: data.text,
      Description: data.description,
      Title: data.title,
      Type: "Blog",
      ImgUrl: data.imgUrl,
      ThumbnailId: data.thumbnailId,
    };

    return payload;
  };

  onHandleSave = (data) => {
    this.setState({btnLoading: true})
    let payload = this.onProvideDataForSave(data);

    fetch(`api/Content/${this.state.blogId}`, {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 400) {
          this.setState({ btnLoading: false }, () => {
            alert(`error: ${response.status} - ${response.body.title}`);
          });
        } else {
          this.setState({ btnLoading: false }, () => {
            alert("update content success !");
          });
        }
      })
      .catch((error) => {
        this.setState({ btnLoading: false }, () => {
          alert(`error: ${error}`);
        });
      });
  };

  renderContent() {
    return (
      <BlogEditor blogPost={this.state.blogPost} onHandleSave={this.onHandleSave} btnLoading={this.state.btnLoading}></BlogEditor>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <p>
        <em>Loading...</em>
      </p>
    ) : (
      this.renderContent()
    );
    return <div>{contents}</div>;
  }
}
