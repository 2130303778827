import React from 'react'
import { Grid, Segment, Container, Form, Input } from "semantic-ui-react";
import Moment from 'react-moment';

export default class BookingBillingInfo extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      bookingInfo: this.props.bookingInfo,
      tourData: this.props.tourData,
      subTotal: this.props.bookingInfo.totalPrice + this.props.totalExtraFee,
      totalPriceToPay: this.props.bookingInfo.totalPrice,
      promoPrice: this.props.promoPrice === undefined ? 0 : this.props.promoPrice,
      promoCode: this.props.promoCode === undefined ? '' : this.props.promoCode,
      hasPromoCode: this.props.promoCode === undefined || this.props.promoCode === '' ? false : true,
    }
  }

  handleInputDiscountPriceClick = (event) => {
    event.preventDefault()
    this.setState({ promoPrice: this.state.promoPrice, hasPromoCode: true }, () => {
      this.props.onHandleInputPromoPrice({ promoCode: this.state.promoCode, promoPrice: this.state.promoPrice, hasPromoCode: true }, false)
    })
  }

  handleInputDiscountPriceChange = (e) => {
    this.setState({ promoPrice: e.target.value });
  };

  calculateTotalPriceToPay = (totalPrice, totalExtra) => {
    let subTotal = totalPrice + totalExtra
    let promoPrice = this.state.promoPrice
    let total = (subTotal) - promoPrice

    return Number(total).toLocaleString('en')
  }

  renderDiscountRow = () => {
    let isDisplay = this.state.hasPromoCode
    if (isDisplay) {
      return (
        <Grid.Row columns={2} className='lighgrey-color-row'>
          <Grid.Column>Discount (VND)</Grid.Column>
          <Grid.Column className='text-align-right'>{`- ${Number(this.state.promoPrice).toLocaleString('en')}`}</Grid.Column>
        </Grid.Row>
      )
    } else {
      return ''
    }
  }

  renderExtraItem = (item, index) => {
    return (
      <Grid.Row key={`${item.questionId}__${index}`} columns={2} className='lighgrey-color-row'>
        <Grid.Column width={11}>{`${item.answerContent} x ${item.count}`}</Grid.Column>
        <Grid.Column width={5} className='text-align-right'>{Number(item.total).toLocaleString('en')}</Grid.Column>
      </Grid.Row>
    )
  }

  renderDataExtraFee = (extraList) => {
    if (extraList.length > 0) {
      return (
        extraList.map((item, index) => {
          return this.renderExtraItem(item, index)
        })
      )
    }
    return (
      <div className='hidden'></div>
    )
  }

  renderSubTotalRow = (price, extrafee) => {
    let subTotal = Number(price) + Number(extrafee)
    if (this.state.promoPrice && !isNaN(this.state.promoPrice)) {
      subTotal = subTotal - Number(this.state.promoPrice)
    }
    return (
      <Grid.Row columns={2} className='subtotal-row'>
        <Grid.Column width={11}>SubTotal (VND)</Grid.Column>
        <Grid.Column width={5} className='text-align-right'>{Number(subTotal).toLocaleString('en')}</Grid.Column>
      </Grid.Row>
    )
  }

  render() {
    let { bookingInfo, tourData } = this.props
    let startDate = new Date(bookingInfo.selectedTourDetail.dateBooking)
    let endDate = new Date(bookingInfo.selectedTourDetail.dateBookingEnd)
    let startTime = new Date(tourData.departureTime)
    let endTime = new Date(tourData.returnTime)
    let dataExtraFee = this.renderDataExtraFee(this.props.extraList)
    let totalPrice = bookingInfo.totalPrice
    let totalExtra = this.props.totalExtraFee
    return (
      <div className='sticky-area'>
        <Container className='booking-detail-billing-summary'>
          <Segment>
            <Grid stackable>
              <Grid.Row columns={2} className='border-bottom'>
                <Grid.Column><strong>Item</strong></Grid.Column>
                <Grid.Column className='text-align-right'><strong>VND</strong></Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={11}>
                  <strong>{tourData.title} </strong><br />
                  <strong>Date:</strong> <Moment format="LL">{startDate}</Moment> <Moment format="hh:mm a">{startTime}</Moment><br />
                  <strong>Ends:</strong> <Moment format="LL">{endDate}</Moment> <Moment format="hh:mm a">{endTime}</Moment>
                </Grid.Column>
                {/* <Grid.Column className='text-align-right' width={5}>{Number(tourData.price).toLocaleString('en')}</Grid.Column> */}
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={11}>
                  {Number(tourData.price).toLocaleString('en')} VND/ person x <strong>{bookingInfo.numParticipants}  </strong>
                </Grid.Column>
                <Grid.Column className='text-align-right' width={5}>{Number(bookingInfo.totalPrice).toLocaleString('en')}</Grid.Column>
              </Grid.Row>
              {dataExtraFee}
              <Grid.Row>
                <Grid.Column>
                  <div className='ui form'>
                    <Form.Field>
                      <Input
                        action={{
                          color: 'teal',
                          content: 'Apply',
                          onClick: this.handleInputDiscountPriceClick
                        }}
                        placeholder='please input promo code...'
                        onChange={this.handleInputDiscountPriceChange}
                        value={this.state.promoPrice}
                      />
                    </Form.Field>
                  </div>

                </Grid.Column>
              </Grid.Row>
              {this.renderDiscountRow()}
              {this.renderSubTotalRow(totalPrice, totalExtra)}
              <Grid.Row columns={2} className='total-row'>
                <Grid.Column width={11}>Total (VND)</Grid.Column>
                <Grid.Column width={5} className='text-align-right'>{this.calculateTotalPriceToPay(totalPrice, totalExtra)}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    )
  }
}
