import React from 'react'
import { Button, Icon, Form, Checkbox, Header, TextArea } from "semantic-ui-react";
import { formProcess, CollaboratorStatus } from '../../../data/enum'
import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';

export default class Detail extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      isUsePercent: false,
      collaboratorSelected: this.props.collaboratorSelected
    }
  }

  changeStatusValueToBoolean = (value) => {
    return value === CollaboratorStatus.ACTIVE ? true : false
  }

  onHandleUpdate = () => {
    this.props.onHandleUpdate(this.state.collaboratorSelected.data.collaborator)
  }

  onCancel = () => {
    this.props.onCancel()
  }

  onHandleSave = (event) => {

    event.preventDefault()

    let payload = this.onProvideDataForSave()

    if (this.props.process === formProcess.NEW) {
      // post data to api for add new data
      fetch('api/Collaborator', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
      })
        .then(handleResponse)
        .then(response => {          
          alert('Create new collaborator success!')
          this.props.onHandleUpdate(response)
        })
        .catch(error => {
          alert(`error: ${error} - Something went wrong! Please try again`)
        })
    } else if (this.props.process === formProcess.EDIT) {
      fetch(`api/Collaborator/${this.props.collaboratorSelected.id}`, {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
      })
        .then(handleResponse)
        .then(response => {          
          alert('Update success!')
          this.props.onHandleUpdate(response)
        })
        .catch(error => {
          alert(`error: ${error} - Something went wrong! Please try again`)
        })
    }
  }

  onHandleChange = (event) => {
    var target = event.target
    var name = target.name
    var value = target.value

    let { collaborator } = this.state.collaboratorSelected.data
    collaborator[name] = value

    this.setState({
      ...this.state,
      collaboratorSelected: {
        data: {
          collaborator: collaborator,
        }
      }
    })
  }

  onHandleCheckbox = (event, data) => {
    var name = data.name
    var value = data.checked

    let collaboratorData = this.state.collaboratorSelected.data.collaborator
    collaboratorData[name] = value === true ? CollaboratorStatus.ACTIVE : CollaboratorStatus.INACTIVE

    this.setState({
      ...this.state,
      collaboratorSelected: {
        data: {
          collaborator: collaboratorData,
        }
      }
    })
  }

  onHandleUserPercentCheckbox = (event, data) => {
    var value = data.checked

    this.setState({
      isUsePercent: value
    })
  }

  onProvideDataForSave = () => {
    let collaborator = this.state.collaboratorSelected.data.collaborator

    // build object post to server
    var payload = {
      Name: collaborator.name,
      Email: collaborator.email,
      Phone: collaborator.phone,
      RefCode: collaborator.refCode,
      DiscountType: this.state.isUsePercent ,
      DiscountPercent: this.state.isUsePercent === true ? collaborator.discountPercent : null,
      DiscountPrice: this.state.isUsePercent === false ? collaborator.discountPrice : null,
      AccountName: collaborator.accountName,
      BankNumber: collaborator.bankNumber,
      BankName: collaborator.bankName,
      BankDepartment: collaborator.bankDepartment,
      Note: collaborator.note,
      Status: collaborator.status,
    }

    return payload
  }  

  render() {
    const { process } = this.props
    const { collaboratorSelected } = this.state
    const { collaborator } = collaboratorSelected.data
    const buttonText = process === formProcess.NEW ? 'Add' : 'Update'
    
    return (
      <div key='collaborator-detail-form'>
        <Header as='h3' dividing>
          1. Collaborator Information
        </Header>
        <Form onSubmit={this.onHandleSave} >
          <Form.Group widths='equal'>
            <Form.Input name='name' label='Name' placeholder='Name' required onChange={this.onHandleChange} value={collaborator.name} />
            <Form.Input name='phone' fluid label='Phone' placeholder='Phone' required onChange={this.onHandleChange} value={collaborator.phone} />
            <Form.Input name='email' fluid label='Email' placeholder='Email' required onChange={this.onHandleChange} value={collaborator.email} />
          </Form.Group>
          <Form.Group width={3} >
            <Form.Input name='refCode' label='Ref Code' placeholder='Ref Code' required onChange={this.onHandleChange} value={collaborator.refCode} />
          </Form.Group>
          <Form.Field>
            <Checkbox name='isUsePercent' toggle label='Use percent value' onChange={this.onHandleUserPercentCheckbox} checked={this.state.isUsePercent} />
          </Form.Field>
          <Form.Group width={3} >
            {this.state.isUsePercent 
            ? <Form.Input name='discountPercent' label='Discount Percent (%)' placeholder='%' onChange={this.onHandleChange} value={collaborator.discountPercent} />
            : <Form.Input name='discountPrice' label='Discount Price ($)' placeholder='$' onChange={this.onHandleChange} value={collaborator.discountPrice} />}
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input name='accountName' fluid label='Account Name' placeholder='Account Name' required onChange={this.onHandleChange} value={collaborator.accountName} />  
            <Form.Input name='bankNumber' fluid label='Account No.' placeholder='Account No.' required onChange={this.onHandleChange} value={collaborator.bankNumber} />
            <Form.Input name='bankName' fluid label='Bank Name' placeholder='Bank Name' onChange={this.onHandleChange} value={collaborator.bankName} />
            <Form.Input name='bankDepartment' fluid label='Bank Department' placeholder='Bank Department' onChange={this.onHandleChange} value={collaborator.bankDepartment} />
          </Form.Group>
          <Form.Field>
            <label>Note</label>
            <TextArea name='note' placeholder='Tell us more' onChange={this.onHandleChange} value={collaborator.note} />
          </Form.Field>
          <Form.Field>
            <Checkbox name='status' toggle label='Status' onChange={this.onHandleCheckbox} checked={this.changeStatusValueToBoolean(collaborator.status)} />
          </Form.Field>
          <Form.Field className='modal-control-area'>
            <Button type='submit' color='teal'>
              {buttonText} <Icon name='chevron right' />
            </Button>
            <Button onClick={this.onCancel}>
              Cancel
            </Button>
          </Form.Field>
        </Form>
      </div >
    )
  }
}
