import React, { Component } from 'react'
import { Table, Message, Header, Segment, Button } from 'semantic-ui-react'
import Moment from 'react-moment'
import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';
import _ from 'lodash'

export default class TableBookingData extends Component {

  onDownloadData = (id) => {

    let tourData =  _.find(this.props.bookingData, function(item) {
      return item.tourDetailId === id
    })

    let fileName = 'EXPORT_TOUR.xlsx'
    if (tourData) {

    }

    let payload = {
      TourDetailId: id
    }

    fetch(`api/Dashboard/ExportTourBookingInformation`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          handleResponse(response)
            .catch(error => alert(error))
        }
        else {
          return response.blob()
        }
      })
      .then((result) => {
        var blob = new Blob([result], { type: 'octet/stream' })
        let urlFile = URL.createObjectURL(blob)
        let a = document.createElement('a');
        a.href = urlFile;
        a.download = fileName
        a.style = 'display: none'
        a.click()
        a.remove()
      })
      .catch(error => alert(error));
  }

  renderTableRow = (data) => {
    if (data === undefined || data.length <= 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan='3'>
            <Message
              icon='smile outline'
              content='There is no booking in your data.'
            />
          </Table.Cell>
        </Table.Row>
      )
    }
    else {
      return data.map(item => {
        return (
          <Table.Row key={item.tourDetailId}>
            <Table.Cell>{item.tour}</Table.Cell>
            <Table.Cell><Moment format="LL">{item.startDate}</Moment></Table.Cell>
            <Table.Cell>{item.numberGuest}/{item.numberAvailables}</Table.Cell>
            <Table.Cell>{item.numberInputReleaseForm}/{item.numberGuest}</Table.Cell>
            <Table.Cell>
              <Button icon='download' data-tooltip="download detail information in excel" onClick={() => this.onDownloadData(item.tourDetailId)} />
            </Table.Cell>
          </Table.Row>
        )
      })
    }
  }

  render() {
    let data = this.props.bookingData
    return (
      <>
        <Header as='h2' attached='top'>
          {this.props.header}
        </Header>
        <Segment attached>
          <Table key={this.props.keyName} compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Tour</Table.HeaderCell>
                <Table.HeaderCell>Start date</Table.HeaderCell>
                <Table.HeaderCell>Guests</Table.HeaderCell>
                <Table.HeaderCell>Input release form</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.renderTableRow(data)}
            </Table.Body>
          </Table>
        </Segment>
      </>
    )
  }
}
