import React from "react";
import { Button, Icon, Form, Header, Checkbox } from "semantic-ui-react";
import DatePicker from "react-datepicker";

import { authHeader } from "../../../_helpers/auth-header";
import { formProcess, TourStatus } from "../../../data/enum";
import { ContentForm } from "./ContentForm";
import { CustomEditor } from "../../Common/Editor";

export default class Detail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();

    this.state = {
      tourSelected: this.props.tourSelected,
      newTourDetail: {
        date: "",
        totalSlot: 0,
        availableSlot: 0,
      },
      inProgess: false,
      priceIncluded: this.props.tourSelected.data.priceIncluded,
      priceExcluded: this.props.tourSelected.data.priceExcluded,
      pricePromo: this.props.tourSelected.data.pricePromo,
    };
  }

  changeStatusValueToBoolean = (value) => {
    return value === TourStatus.ACTIVE ? true : false;
  };

  onHandleUpdate = () => {
    this.props.onHandleUpdate(this.state.tourSelected.data);
  };

  onCancel = () => {
    this.props.onCancel();
  };

  validateDataInput = () => {
    let { data } = this.state.tourSelected;
    let isValid = true;
    let msg = "";
    if (data.title === "" || data.title === null || data.title === undefined) {
      isValid = false;
      msg += "Please input Title for this Tour";
    } else if (
      data.summary === "" ||
      data.summary === null ||
      data.summary === undefined
    ) {
      isValid = false;
      msg += "Please input summary for this Tour";
    } else if (
      data.price === "" ||
      data.price === null ||
      data.price === undefined ||
      data.price === 0 ||
      data.price === "0"
    ) {
      isValid = false;
      msg += "Please input price for this Tour";
    } else if (
      data.departureTime === "" ||
      data.departureTime === null ||
      data.departureTime === undefined
    ) {
      isValid = false;
      msg += "Please input departure time for this Tour";
    } else if (
      data.returnTime === "" ||
      data.returnTime === null ||
      data.returnTime === undefined
    ) {
      isValid = false;
      msg += "Please input return time for this Tour";
    }

    if (isValid === false) {
      alert(msg);
    }
    return isValid;
  };

  onHandleSave = (event) => {
    this.setState({ inProgess: true });

    event.preventDefault();

    let isValid = this.validateDataInput();

    if (isValid) {
      let payload = this.onProvideDataForSave();

      if (this.props.process === formProcess.NEW) {
        // post data to api for add new data
        fetch("api/Tour", {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        })
          .then((response) =>
            response
              .json()
              .then((data) => ({ status: response.status, body: data }))
          )
          .then((response) => {
            if (response.status === 400) {
              this.setState({ inProgess: false }, () => {
                alert(`error: ${response.status} - ${response.body.title}`);
              });
            } else {
              alert("Create new tour success!");
              this.setState({ inProgess: false }, () => {
                this.props.onHandleUpdate(response.body);
              });
            }
          })
          .catch((error) => alert(error));
      } else if (this.props.process === formProcess.EDIT) {
        fetch(`api/Tour/${this.state.tourSelected.id}`, {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(payload),
        })
          .then((response) =>
            response
              .json()
              .then((data) => ({ status: response.status, body: data }))
          )
          .then((response) => {
            if (response.status === 400) {
              this.setState({ inProgess: false }, () => {
                alert(`error: ${response.status} - ${response.body.title}`);
              });
            } else {
              alert("Update success!");
              this.setState({ inProgess: false }, () => {
                this.props.onHandleUpdate(response.body);
              });
            }
          })
          .catch((error) => {
            alert(`error: ${error} - Something went wrong! Please try again`);
            this.setState({ inProgess: true });
          });
      }
    }
  };

  onHandleChange = (event) => {
    var target = event.target;
    var name = target.name;
    var value = target.value;

    let { data } = this.state.tourSelected;
    data[name] = value;

    this.setState({
      tourSelected: {
        ...this.state.tourSelected,
        data: data,
      },
    });
  };

  onHandleCheckbox = (event, data) => {
    var name = data.name;
    var value = data.checked;

    let tourData = this.state.tourSelected.data;
    tourData[name] = value === true ? TourStatus.ACTIVE : TourStatus.INACTIVE;

    this.setState({
      tourSelected: {
        ...this.state.tourSelected,
        data: tourData,
      },
    });
  };

  onHandleSetDepartureTime = (date) => {
    let selectedDate = new Date(date);
    let { tourSelected } = this.state;
    tourSelected.data.departureTime = selectedDate;
    this.setState({ ...this.state, tourSelected: tourSelected });
    this.forceUpdate();
  };

  onHandleSetReturnTime = (date) => {
    let selectedDate = new Date(date);
    let { tourSelected } = this.state;
    tourSelected.data.returnTime = selectedDate;
    this.setState({ ...this.state, tourSelected: tourSelected });
    this.forceUpdate();
  };

  onProvideDataForSave = () => {
    let { data } = this.state.tourSelected;

    // build object post to server
    var payload = {
      Title: data.title,
      Summary: data.summary,
      Order: data.order,
      DurationText: data.durationText,
      DepartureTime: new Date(data.departureTime),
      ReturnTime: new Date(data.returnTime),
      Price: data.price,
      Status: data.status,
      TourDetails: data.tourDetails,
      Description: JSON.stringify(data.contents),
      DepartureInfo: data.departureInfo,
      PriceIncluded: this.state.priceIncluded,
      PriceExcluded: this.state.priceExcluded,
      PricePromo: this.state.pricePromo,
      Reviews: data.reviews,
    };

    return payload;
  };

  onUpdateContents = (content) => {
    let { contents } = this.state.tourSelected.data;

    if (!contents) {
      contents.push(content);
    } else {
      // find data in existing tour data
      let updatedItem = contents.findIndex(function (item) {
        return item.id === content.id;
      });

      if (updatedItem === -1) {
        contents.push(content);
      } else {
        contents[updatedItem] = content;
      }
    }

    this.setState({
      contents: [...contents],
    });
  };

  onUpdateEditor = (content) => {
    this.setState({
      [content.name]: content.text,
    });
  };

  removeContents = (contentsUpdated) => {
    let { contents } = this.state.tourSelected.data;
    contents = contentsUpdated;
    this.setState({
      tourSelected: {
        ...this.state.tourSelected,
        data: {
          ...this.state.tourSelected.data,
          contents: contents,
        },
      },
    });
  };

  render() {
    let { process } = this.props;
    let { tourSelected } = this.state;
    let buttonText = process === formProcess.NEW ? "Add" : "Update";
    let departureTimeDefault = tourSelected.data.departureTime
      ? new Date(tourSelected.data.departureTime)
      : null;
    let returnTimeDefault = tourSelected.data.returnTime
      ? new Date(tourSelected.data.returnTime)
      : null;
    return (
      <div>
        <Header as="h3" dividing>
          1. Tour Information
        </Header>
        <Form onSubmit={this.onHandleSave}>
          <Form.Input
            name="title"
            label="Title"
            placeholder="Title"
            required
            onChange={this.onHandleChange}
            value={tourSelected.data.title}
          />

          <Form.Group widths="equal">
            <Form.TextArea
              name="summary"
              label="Tóm tắt tour"
              placeholder="Thông tin dùng hiển thị tóm tắt tour ngoài trang chủ"
              required
              onChange={this.onHandleChange}
              value={tourSelected.data.summary}
            />
            <Form.TextArea
              name="departureInfo"
              label="Thông tin khởi hành"
              placeholder="Thông tin phần khởi hành (gồm phương tiện đi lại, số khách tối đa, đón trả khách, ...). Mỗi thông tin viết theo dạng <Loại>: <thông tin> và xuống dòng với mỗi loại."
              onChange={this.onHandleChange}
              value={tourSelected.data.departureInfo}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              type="number"
              name="price"
              label="Price"
              placeholder="Price"
              required
              onChange={this.onHandleChange}
              value={tourSelected.data.price}
            />
            <CustomEditor
              name="priceIncluded"
              text={tourSelected.data.priceIncluded}
              onUpdateEditor={this.onUpdateEditor}
            ></CustomEditor>
            <CustomEditor
              name="priceExcluded"
              text={tourSelected.data.priceExcluded}
              onUpdateEditor={this.onUpdateEditor}
            ></CustomEditor>
            <CustomEditor
              name="pricePromo"
              text={tourSelected.data.pricePromo}
              onUpdateEditor={this.onUpdateEditor}
            ></CustomEditor>
          </Form.Group>
          <Form.Group>
            <Form.Input
              key="tour-order"
              type="number"
              required
              name="order"
              value={tourSelected.data.order}
              label="Thứ tự hiển thị"
              placeholder="Thứ tự hiển thị trên list tour"
              onChange={this.onHandleChange}
            />
            <Form.Field>
              <Checkbox
                name="status"
                toggle
                label="Status (Active/InActive)"
                onChange={this.onHandleCheckbox}
                checked={this.changeStatusValueToBoolean(
                  tourSelected.data.status
                )}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>            
            <Form.Input
              name="durationText"
              label="Duration Text"
              placeholder="Duration show for user"
              required
              onChange={this.onHandleChange}
              value={tourSelected.data.durationText}
            />
            <Form.Field required>
              <label>Departure Time</label>
              <DatePicker
                name="departureTime"
                selected={departureTimeDefault}
                onChange={(date) => this.onHandleSetDepartureTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="hh:mm aa"
                autoComplete="off"
                placeholderText="Click to select a time"
              />
            </Form.Field>
            <Form.Field required>
              <label>Return Time</label>
              <DatePicker
                name="returnTime"
                selected={returnTimeDefault}
                onChange={(date) => this.onHandleSetReturnTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="hh:mm aa"
                autoComplete="off"
                placeholderText="Click to select a time"
              />
            </Form.Field>
          </Form.Group>          
          <Form.TextArea
            name="reviews"
            label="Thông tin reviews trên facebook (Mã embed copy từ facebook review. Mỗi embed code cần xuống dòng.)"
            placeholder="Mã embed copy từ facebook review. Mỗi embed code cần xuống dòng."
            onChange={this.onHandleChange}
            value={tourSelected.data.reviews}
          />
          <Header as="h4" dividing>
            2. Tour Detail Information
          </Header>

          <ContentForm
            contents={tourSelected.data.contents}
            onUpdateContents={this.onUpdateContents}
            removeContents={this.removeContents}
          ></ContentForm>

          <Form.Field className="modal-control-area">
            <Button
              type="submit"
              color="teal"
              className={`${this.state.inProgess ? "loading disabled " : ""}`}
            >
              {buttonText} <Icon name="chevron right" />
            </Button>
            <Button onClick={this.onCancel}>Cancel</Button>
          </Form.Field>
        </Form>
      </div>
    );
  }
}
