import React from 'react'
import { Grid, Button, Header, Segment, Icon, Modal } from 'semantic-ui-react'
import List from './List'
import Detail from './Detail'
import { formProcess, CollaboratorStatus } from '../../../data/enum'
import FormModal from '../../Common/FormModal'
import _ from 'lodash'
import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';
import Utils from '../../Common/Utils'

export default class Index extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      commissionData: null,
      collaboratorData: [],
      loading: true,
      commissionValueInput: 0,
      deleteConfirmOpen: false,
      deleteIdSelected: '',
      confirmProcessing: false,
    }
  }

  openConfirm = (id) => this.setState({ deleteConfirmOpen: true, deleteIdSelected: id, confirmProcessing: false })
  closeConfirm = () => this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false })

  componentDidMount() {
    this.getCollaboratorData()
  }

  getCollaboratorData = () => {
    fetch('api/Collaborator/Get', {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(response => {
        this.setState({
          collaboratorData: response.data.collaboratorsData,
          commissionData: response.data.commissionData,
          commissionValueInput: response.data.commissionData === null || response.data.commissionData === undefined ? 0 : response.data.commissionData.commissionValue,
          loading: false
        })
      })
  }

  provideDataForQuery = () => {
    // TODO add code process for seach feature
    let payloadResource = {
      collaboratorId: '',
      type: null,
      status: null
    }

    return payloadResource;
  }

  onHandleEditData = (id) => {

    //get detail data from db 
    fetch(`api/Collaborator/Get/${id}`, {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(response => {
        let dataResponse = response.data
        let dataInit = {
          collaborator: dataResponse.collaborator,
          commissionData: dataResponse.commissionData,
        }
        this.setState({
          openModal: true,
          formProcess: formProcess.EDIT,
          collaboratorSelected: { id: id, data: dataInit },
          commissionData: dataResponse.commissionData
        }, () => { this.forceUpdate() })
      })
  }

  onHandleAddNew = () => {
    this.setState({
      openModal: true, formProcess: formProcess.NEW, collaboratorSelected: {
        id: '',
        data: {
          collaborator: {
            name: '',
            phone: '',
            refCode: '',
            discountType: true,
            discountPercent: '',
            discountPrice: 200000,
            status: CollaboratorStatus.ACTIVE
          },
          commissionData: this.state.commissionData,
        }
      }
    })
  }

  onHandleChangeCommission = (event) => {
    let target = event.target
    let value = target.value

    this.setState({ commissionValueInput: value })
  }

  onHandleUpdateCommissionValue = () => {

    let payload = {
      Id: this.state.commissionData === null || this.state.commissionData === undefined ? Utils.createGuid() : this.state.commissionData.id,
      CommissionValue: this.state.commissionValueInput
    }

    fetch(`api/Collaborator/PutCommissionAsync/Update`, {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(response => {
        alert('Update commission success!')
        this.getCollaboratorData()
      })
      .catch(error => {
        alert(`error: ${error} - Something went wrong! Please try again`)
      });

  }

  onHandleUpdate = (data) => {
    let collaboratorData = [...this.state.collaboratorData]

    // find data in existing tour data
    let updatedItem = _.findIndex(collaboratorData, function (item) { return item.id === data.id })

    if (updatedItem === -1) {
      collaboratorData.push(data)
    } else {
      collaboratorData[updatedItem] = data
    }

    this.onHandleCloseModal(true, collaboratorData)
  }

  onHandleCloseModal = (isUpdateData, data) => {
    if (isUpdateData) {
      let collaboratorData = [...this.state.collaboratorData]
      collaboratorData = data
      this.setState({
        openModal: false, formProcess: formProcess.NEW,
        collaboratorSelected: {
          id: '',
          data: {}
        },
        collaboratorData: collaboratorData,
        loading: true
      })
      this.forceUpdate()
    } else {
      this.setState({
        openModal: false, formProcess: formProcess.NEW, collaboratorSelected: {
          id: '',
          data: {}
        }
      })
    }
  }

  onCancel = () => {
    this.onHandleCloseModal()
  }

  onToggleCollaborator = (id) => {

    let payload = {
      TourId: id
    }

    fetch(`api/Collaborator/ToggleCollaboratorStatusAsync`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(data => {
        this.getCollaboratorData(data)
      })
      .catch(error => {
        alert(`error: ${error} - Something went wrong! Please try again`)
      })
  }

  onDeleteConfirm = () => {

    this.setState({ confirmProcessing: true })

    let payload = {
      CollaboratorId: this.state.deleteIdSelected
    }

    fetch(`api/Collaborator/DeleteCollaboratorAsync`, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false }, () => {
          this.getCollaboratorData()
        })

      })
      .catch(error => {
        alert(`error: ${error} - Something went wrong! Please try again`)
        this.setState({ deleteConfirmOpen: false, deleteIdSelected: '', confirmProcessing: false })
      });
  }

  render() {
    let { collaboratorData, loading } = this.state
    let commissionValue = this.state.commissionValueInput
    return (
      <Grid key='collaborator-index' divided='vertically'>
        <Grid.Row className='control-area-row'>
          <Grid.Column>
            <Button color='teal' key='btnAddNew' icon labelPosition='left' onClick={this.onHandleAddNew} >
              Add new
                <Icon name='add' />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as='h3' dividing>
              Commission Price
              <Header.Subheader>
                This value is applied for all collaborators
              </Header.Subheader>
            </Header>
            <Segment basic>
              <div className='ui form'>
                <div className='inline field'>
                  <label>Commission</label>
                  <div className='ui input'>
                    <input key='commissionValue' type='text' name='commissionValue' placeholder='Enter commission...' value={commissionValue} onChange={this.onHandleChangeCommission} />
                    <Button color='teal' key='btnClickUpdateCommission' onClick={this.onHandleUpdateCommissionValue}>Update</Button>
                  </div>
                </div>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as='h3' dividing>
              List collaborators
            </Header>
            <List key='collaboratorList'
              className='collaborator-list'
              loading={loading}
              collaboratorData={collaboratorData}
              onHandleEditData={this.onHandleEditData}
              onToggleCollaborator={this.onToggleCollaborator}
              openConfirm={this.openConfirm}></List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <FormModal
            open={this.state.openModal}
            size='fullscreen'
            closeOnDimmerClick={false}
            header='Add/Update'
            onClose={this.onHandleCloseModal}
          >
            <Detail process={this.state.formProcess} collaboratorSelected={this.state.collaboratorSelected} onHandleUpdate={this.onHandleUpdate} onCancel={this.onCancel} />
          </FormModal>
          <Modal size='mini' open={this.state.deleteConfirmOpen}
              onClose={this.closeConfirm}
              className='alert-message-area'
              closeOnEscape={false}
              closeOnDimmerClick={false}
            >
              <Modal.Header>Delete Your Booking</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to delete ?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} negative onClick={this.closeConfirm}>No</Button>
                <Button className={this.state.confirmProcessing === true ? 'loading disabled' : ''} positive onClick={this.onDeleteConfirm}>Yes</Button>
              </Modal.Actions>
            </Modal>
        </Grid.Row>
      </Grid>
    )
  }
}
