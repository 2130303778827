import React, { Component } from 'react'
import { Grid, Table, Message, Segment, Dimmer, Loader, Form, Select, Header, List, Label } from 'semantic-ui-react'
import './BookingList.scss'
import { authHeader } from '../../../_helpers/auth-header';
import Moment from 'react-moment';

export default class Index extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedTourIdFilter: '',
      listTour: [],
      listTourDropdownInput: [],
      bookingData: [],
      loading: true,
    }
  }

  componentDidMount() {
    fetch('api/Tour/AmGetToursList', {
      method: 'GET',
      headers: authHeader()
    })
      .then(response => response.json())
      .then(data => {
        let listTour = []
        let listTourDropdownInput = []
        // add 2 option to select all value & general value
        listTour.push({
          key: '5bb2e8ba-912f-4a57-bec3-9218c0e78b43',
          text: 'All',
          value: '5bb2e8ba-912f-4a57-bec3-9218c0e78b43'
        })

        if (data && data.length > 0) {
          data.forEach(element => {
            let tourItem = {
              key: element.id,
              text: element.title,
              value: element.id
            }
            listTour.push(tourItem)
            listTourDropdownInput.push(tourItem)
          });
        }
        this.setState({ listTour: listTour, listTourDropdownInput: listTourDropdownInput, loading: false });
      });
  }

  onHandleDropdownSelect = (event, data) => {
    var name = data.name
    var value = data.value

    if (name === 'tourfilter') {

      if (value !== this.state.selectedTourIdFilter) {
        this.setState({
          selectedTourIdFilter: value,
          loading: true,
        })

        //this.resetDataInput()
        this.getBookingData(value)
      }
    }
  }

  getBookingData = (tourId) => {
    fetch(`api/Booking/GetBookingsView/${tourId}`)
      .then(response => response.json())
      .then(data => {
        this.setState({ bookingData: data, loading: false });
      })
      .catch((error) => {
        alert(error)
        this.setState({ loading: false })
      });
  }

  renderParticipantRowItem = (item) => {
    return (
      <Table.Row>
        <Table.Cell>
          {item.fullName}
        </Table.Cell>
        <Table.Cell>
          <Moment format="MMM DD, YYYY" date={new Date(item.dob)} />
        </Table.Cell>
        <Table.Cell>
          {item.gender}
        </Table.Cell>
        <Table.Cell>
          {item.passport}
        </Table.Cell>
        <Table.Cell>
          {item.nationality}
        </Table.Cell>
        <Table.Cell>
          {item.phone}
        </Table.Cell>
        <Table.Cell>
          {item.email}
        </Table.Cell>
        <Table.Cell className='wid-per-5' textAlign='center'>Release form</Table.Cell>
      </Table.Row>
    )
  }

  renderParticipantsRows = (listItem) => {
    if (listItem) {
      let listRender = []
      for (var i = 1; i < listItem.length; i++) {
        listRender.push(this.renderParticipantRowItem(listItem[i]))
      }
      return listRender
    }
  }

  renderDataRow = (item, index) => {
    let createdDate = new Date(item.createdDateLocalTime)
    let startDate = new Date(item.tourDetail.dateBooking)
    let endDate = new Date(item.tourDetail.dateBookingEnd)
    let startTime = new Date(item.tourDetail.tour.departureTime)
    let endTime = new Date(item.tourDetail.tour.returnTime)
    let participantRow = this.renderParticipantsRows(item.bookingParticipants)
    return (
      <>
        <Table.Row>
          <Table.Cell className='wid-per-5' rowSpan={item.bookingNumParticipant}>
            <List key={`list-item-contact-${item.id}`}>
              <List.Item>
                <List.Icon name='calendar alternate outline' />
                <List.Content><strong>Start Date: </strong><Moment format="LL">{startDate}</Moment> <Moment format="LT">{startTime}</Moment></List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='clock outline' />
                <List.Content>
                  <List.Content><strong>End Date: </strong><Moment format="LL">{endDate}</Moment> <Moment format="LT">{endTime}</Moment></List.Content>
                </List.Content>
              </List.Item>
            </List>
          </Table.Cell>
          <Table.Cell className='wid-per-5' rowSpan={item.bookingNumParticipant}>{item.bookingNumber}</Table.Cell>
          <Table.Cell className='wid-per-8' rowSpan={item.bookingNumParticipant}><Moment format="MMM DD, YYYY hh:mm a" date={createdDate} /></Table.Cell>
          <Table.Cell rowSpan={item.bookingNumParticipant}>{Number(item.total).toLocaleString('en')}</Table.Cell>
          <Table.Cell rowSpan={item.bookingNumParticipant}>
            <List key={`list-item-contact-${item.id}`}>
              <List.Item>
                <List.Icon name='user outline' />
                <List.Content>{item.billingContactFirstName} {item.billingContactLastName}</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='mail outline' />
                <List.Content>
                  <a href={'mailto:' + item.billingContactEmail}>{item.billingContactEmail}</a>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='mobile alternate' />
                <List.Content>
                  {item.billingContactPhone}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='map marker alternate' />
                <List.Content>
                  {item.conutry}
                </List.Content>
              </List.Item>
            </List>
          </Table.Cell>
          <Table.Cell className='wid-per-2' rowSpan={item.bookingNumParticipant}>{item.status === 'Active' ? <Label basic color='blue'>{item.status}</Label> : <Label basic color='grey'>{item.status}</Label>}</Table.Cell>
          <Table.Cell>{item.bookingParticipants[0].fullName}</Table.Cell>
          <Table.Cell><Moment format="MMM DD, YYYY" date={new Date(item.bookingParticipants[0].dob)} /></Table.Cell>
          <Table.Cell>{item.bookingParticipants[0].gender}</Table.Cell>
          <Table.Cell>{item.bookingParticipants[0].passport}</Table.Cell>
          <Table.Cell>{item.bookingParticipants[0].nationality}</Table.Cell>
          <Table.Cell>{item.bookingParticipants[0].phone}</Table.Cell>
          <Table.Cell>{item.bookingParticipants[0].email}</Table.Cell>
          <Table.Cell className='wid-per-5' textAlign='center'>Release form</Table.Cell>
        </Table.Row>
        {participantRow}
      </>
    )
  }

  renderBookingList = (listBooking) => {
    if (listBooking !== undefined && listBooking.length > 0) {
      return (
        <Table key='participant-list' celled structured compact className='custom-table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='wid-per-5' rowSpan='2'>Date</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5' rowSpan='2'>Booking number</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-8' rowSpan='2'>Created date</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>Total Price</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>Billing Contact</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-2' rowSpan='2'>Status</Table.HeaderCell>
              <Table.HeaderCell colSpan='8'>Participants</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Full Name</Table.HeaderCell>
              <Table.HeaderCell>DoB</Table.HeaderCell>
              <Table.HeaderCell>Gender</Table.HeaderCell>
              <Table.HeaderCell>Passport Number</Table.HeaderCell>
              <Table.HeaderCell>Nationality</Table.HeaderCell>
              <Table.HeaderCell>Phone/ Whatsapp</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell className='wid-per-5' textAlign='center'>Release form</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              listBooking.map((item, index) => {
                return this.renderDataRow(item, index)
              })
            }
          </Table.Body>
        </Table>
      )
    }
    else {
      return (
        <Message
          icon='cogs'
          header='There is no Bookink data in selected tour.'
          content='You can create new by click button [Create new].'
        />
      )
    }
  }

  render() {
    let contents = this.state.loading
      ? <Segment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Segment>
      : this.renderBookingList(this.state.bookingData)

    return (
      <div key='adm-booking-screen' className='Booking-screen'>
        <Grid divided='vertically'>
          <Grid.Row className='control-area-row'>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='3'>
            <Grid.Column>
              <div className='ui form'>
                <Form.Field>
                  <label>Select tour to view question below</label>
                  <Select
                    key='booking-tourfilter'
                    name='tourfilter'
                    onChange={this.onHandleDropdownSelect}
                    options={this.state.listTour}
                    placeholder='Choose an option'
                    selection
                  />
                </Form.Field>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h3' dividing>List Booking</Header>
              {contents}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}
