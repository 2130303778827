const bookingStep = {
  SELECT_TOUR_DETAIL: 1,
  INPUT_BOOKING_DETAIL: 2,
  CONFIRM: 3,
  PAYMENT: 4
}

const ObjectState = {
  UNCHANGED: 0,
  ADDED: 1,
  MODIFIED: 2,
  DELETE: 3,
}

const formProcess = {
  NEW: 1,
  EDIT: 2,
  READONLY: 3
}

const CommonStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}


const FAQStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

const TourStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

const CollaboratorStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
}

const FAQType = {
  GENERAL: 'General',
  SPECIFICATION: 'Specification'
}

module.exports = {
  bookingStep,
  formProcess,
  FAQStatus,
  FAQType,
  TourStatus,
  CollaboratorStatus,
  CommonStatus,
  ObjectState
}

