import React, { PureComponent } from "react";
import {
  Form,
  Checkbox,
  Divider,
  Segment,
  Icon,
  Button,
} from "semantic-ui-react";
import { Editor } from "@tinymce/tinymce-react";

export class ContentItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      title: this.props.contentData.title,
      description:
        this.props.initialValue ?? this.props.contentData.description,
      isCollapse: this.props.contentData.isCollapse,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: this.props.initialValue ?? this.props.contentData.description,
      });
    }
  }

  onHandleCheckbox = (event, data) => {
    let value = data.checked;
    this.setState(
      {
        isCollapse: value,
      },
      () => {
        this.props.onUpdateContents(this.state);
      }
    );
  };

  onHandleChange = (event) => {
    let target = event.target;
    let name = target.name;
    let value = target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.onUpdateContents(this.state);
      }
    );
  };

  handleEditorChange = (e) => {
    let description = e.target.getContent();
    this.setState(
      {
        description: description,
      },
      () => {
        this.props.onUpdateContents(this.state);
      }
    );
  };

  toggle = () => {
    let headerText = !this.state.open ? "-" : "+";
    this.setState({ open: !this.state.open, headerText: headerText });
  };

  remove = (event) => {
    event.preventDefault();
    this.props.removeContent(this.state.id);
  };

  render() {
    return (
      <>
        <div
          key={`tour-detail-content-${this.state.id}`}
          className="tour-detail-content-item"
        >
          <input
            id={`collapsible-${this.state.id}`}
            className="toggle"
            type="checkbox"
          />
          <label htmlFor={`collapsible-${this.state.id}`} className="lbl-toggle">
            <h4 className="header">{this.state.title}</h4>
          </label>
          <div className="collapsible-content custom-content-format">
            <Segment attached>
              <div className="content-item">
                <Form.Group>
                  <Form.Field>
                    <Checkbox
                      name="isCollapse"
                      toggle
                      label="Định dạng nội dung theo dạng bấm tiêu đề hiện ra nội dung"
                      onChange={this.onHandleCheckbox}
                      checked={this.state.isCollapse}
                    />
                  </Form.Field>
                  <Button onClick={this.remove} floated="right" size="small">
                    <Icon name="trash alternate outline" /> Xóa nội dung
                  </Button>
                </Form.Group>

                <Form.Input
                  name="title"
                  label="Tiêu đề"
                  placeholder="Tiêu đề của nội dung"
                  onChange={this.onHandleChange}
                  value={this.state.title}
                />
                <div className="required field">
                  <label>Nội dung</label>
                  <Editor
                    name="descriptionContent"
                    apiKey="s41n8a0vsndmme1hzfn7gocq4xxecw3t5a15nffk8jw7537n"
                    cloudChannel="dev"
                    initialValue={this.props.contentData.description}
                    value={this.state.value}
                    key={`editor-content-${this.props.id}`}
                    init={{
                      plugins:
                        "link lists image charmap hr anchor imagetools code media mediaembed table paste textcolor colorpicker textpattern",
                      toolbar: [
                        "undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table tabledelete | link image media | code",
                      ],
                      min_height: 300,
                    }}
                    onChange={this.handleEditorChange}
                  />
                </div>
              </div>
            </Segment>
          </div>
        </div>
        <Divider></Divider>
      </>
    );
  }
}

export default ContentItem;
