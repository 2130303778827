import React from 'react'
import { Header, Segment, Container } from "semantic-ui-react"
import _ from 'lodash'

import BookingParticipantForm from './BookingParticipantForm'


export default class BookingParticipant extends React.PureComponent {

  onHandleInputBookingParticipant = (data, qna) => {
    this.props.onHandleInputBookingParticipant(data, qna)
  }

  openErrorModal = (mess) => {
    this.props.openErrorModal(mess)
  }

  buildForm = (i) => {
    let existItem = null
    if (this.props.dataParticipant) {
      let keyName = `part__${i + 1}`
      existItem = _.find(this.props.dataParticipant, function (item) {
        return item.key === keyName
      })
    }

    return (
      <BookingParticipantForm
        participantInfo={existItem}
        bookingSelectedDate={this.props.bookingSelectedDate}
        tourQnA={this.props.tourData.tourQuestions}
        key={`part__${i + 1}`} partKey={`part__${i + 1}`}
        partIndex={i}
        onHandleInputBookingParticipant={this.onHandleInputBookingParticipant}
        openErrorModal={this.openErrorModal}></BookingParticipantForm>
    )
  }

  generateParticipantsForm = (numParticipants) => {
    let participantForms = []
    for (let i = 0; i < numParticipants; i++) {
      participantForms.push(this.buildForm(i))
    }
    return participantForms
  }

  render() {

    let { numParticipants } = this.props

    return (
      <div>
        <Container className='booking-detail-participant-information'>
          <Header as='h3' dividing>Booking Details
              <Header.Subheader>Step 1 of 2</Header.Subheader>
          </Header>
          <Segment vertical>
            {this.generateParticipantsForm(numParticipants)}
          </Segment>
        </Container>
      </div>
    )
  }
}
