import React, { Component } from 'react'
import { Grid, Table, Button, Header, Segment, Dimmer, Loader, Container, Modal, Form } from 'semantic-ui-react'
import _ from 'lodash'
import Moment from 'react-moment';

import { authHeader } from '../../../_helpers/auth-header';
import { handleResponse } from '../../../_helpers/handle-response';
import BookingParticipantForm from './EditComponent/BookingParticipantForm'
export default class Edit extends Component {
  constructor(props) {
    super(props)

    let bookingId = this.props.match.params.bookingId

    this.state = {
      bookingId: bookingId,
      loading: true,
      bookingData: null,
      hasErrorAlert: false,
      errorMessage: '',
      inProgess: false
    }
  }

  openErrorModal = (mess) => this.setState({ ...this.state, hasErrorAlert: true, errorMessage: mess })
  closeErrorModal = () => this.setState({ hasErrorAlert: false, errorMessage: '' })

  componentDidMount() {
    this.getBookingData()
  }

  getBookingData = () => {
    fetch(`api/Booking/GetBookingParticipantDetail/${this.state.bookingId}`, {
      method: 'GET',
      headers: authHeader()
    })
      .then(handleResponse)
      .then(data => {
        this.setState({ ...this.state, bookingData: data, loading: false })
      });
  }

  onHandleInputBookingParticipant = (data, qna) => {
    let { bookingParticipants } = this.state.bookingData

    let indexPart = _.findIndex(bookingParticipants, ['id', data.id])

    //let dropDownQnAHasFee = _.filter(qna, function (o) { return o.isDropdown && (o.fee !== null && o.fee !== undefined && !isNaN(o.fee) && o.fee > 0) })

    let item = bookingParticipants[indexPart]
    //item.qna = qna
    //item.dropDownQnAHasFee = dropDownQnAHasFee

    item.dob = new Date(data.dob)
    item.email = data.email
    item.fullName = data.fullName
    item.gender = data.gender
    item.nationality = data.nationality
    item.passport = data.passport
    item.phone = data.phone
    item.emergencyContactName = data.emergencyContactName
    item.emergencyContactNumber = data.emergencyContactNumber
    item.emergencyContactEmail = data.emergencyContactEmail
    item.insuranceName = data.insuranceName
    item.insuranceContactNumber = data.insuranceContactNumber
    item.insurancePolicyNumber = data.insurancePolicyNumber

    this.setState({
      ...this.state.bookingData,
      bookingParticipants: [...bookingParticipants]
    })
  }

  calculateSubTotal = (promo, total, extra) => {
    let subtotal = total + extra - promo
    return Number(subtotal).toLocaleString('en')
  }

  updateBooking = () => {
    this.setState({ inProgess: true })

    let payload = this.provideDataForSave()

    fetch(`api/Booking/AdUpdateBooking/${this.state.bookingId}`, {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify(payload)
    })
      .then(handleResponse)
      .then(response => {
        alert('Update success!')
        this.setState({ inProgess: false }, () => { this.getBookingData() })
      })
      .catch(error => {
        alert(`error: ${error} - Something went wrong! Please try again`)
        this.setState({ inProgess: false })
      });
  }

  provideDataForSave = () => {
    let payload = {
      Id: this.state.bookingId,
      BookingParticipants: this.state.bookingData.bookingParticipants
    }

    return payload
  }

  renderParticipantsData = (partData) => {
    return partData.map((item, index) => {
      return (
        <BookingParticipantForm
          participantInfo={item}
          bookingSelectedDate={new Date(this.state.bookingData.tourDetail.dateBooking)}
          tourQnA={null}
          key={`part__${index + 1}`} partKey={`part__${index + 1}`}
          partIndex={index}
          onHandleInputBookingParticipant={this.onHandleInputBookingParticipant}
          openErrorModal={this.openErrorModal}>
        </BookingParticipantForm>
      )
    })
  }

  renderTourInformation = (bookingData) => {
    if (bookingData !== null && bookingData !== undefined) {
      //let QnARowPayment = this.renderAnswerQuestionSelectedList()
      return (
        <React.Fragment>

          <Grid.Row>
            <Grid.Column width={8}>
              <Header as='h3' divided>
                Participants list
          </Header>
              <Segment className='list-participants-booking'>
                {this.renderParticipantsData(bookingData.bookingParticipants)}
              </Segment>
            </Grid.Column>
            <Grid.Column floated='right' width={5}>
              <Header as='h3' attached='top'>
                Booking Information
              </Header>
              <Segment attached>
                <Grid stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h4'>
                        Tour Information
                      </Header>
                      <Segment>
                        <Table className='table-list' basic='very' compact='very'>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Title
                            </Table.Cell>
                              <Table.Cell>
                                {bookingData.tourDetail.tour.title}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Duration
                            </Table.Cell>
                              <Table.Cell>
                                {bookingData.tourDetail.tour.duration}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Duration Text
                            </Table.Cell>
                              <Table.Cell>
                                {bookingData.tourDetail.tour.durationText}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Departure Time
                            </Table.Cell>
                              <Table.Cell>
                                <Moment format="hh:mm a" date={bookingData.tourDetail.tour.departureTime}></Moment>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Return Time
                            </Table.Cell>
                              <Table.Cell>
                                <Moment format="hh:mm a" date={bookingData.tourDetail.tour.returnTime}></Moment>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Segment>
                      <Header as='h4'>
                        Booking Information Detail
                      </Header>
                      <Segment>
                        <Table className='table-list' basic='very' compact='very'>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Booking Number
                            </Table.Cell>
                              <Table.Cell>
                                {bookingData.bookingNumber}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Customer booking at
                            </Table.Cell>
                              <Table.Cell>
                                <Moment format="MMM DD, YYYY hh:mm a" date={bookingData.createdDate}></Moment>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Participants
                            </Table.Cell>
                              <Table.Cell>
                                {bookingData.bookingNumParticipant} guests
                            </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Start Date
                            </Table.Cell>
                              <Table.Cell>
                                <Moment format="MMM DD, YYYY" date={bookingData.tourDetail.dateBooking} /> <Moment format="hh:mm a" date={bookingData.tourDetail.tour.departureTime}></Moment>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                End Date
                            </Table.Cell>
                              <Table.Cell>
                                <Moment format="MMM DD, YYYY" date={bookingData.tourDetail.dateBookingEnd} /> <Moment format="hh:mm a" date={bookingData.tourDetail.tour.returnTime}></Moment>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Segment>
                      <Header as='h4'>
                        Payment Information Detail
                      </Header>
                      <Segment>
                        <Table className='table-list' basic='very' compact='very'>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                {Number(bookingData.tourPrice).toLocaleString('en')}  VND/ person x <strong>{bookingData.bookingNumParticipant}</strong>
                              </Table.Cell>
                              <Table.Cell className='text-align-right'>
                                {Number(bookingData.bookingPrice).toLocaleString('en')}
                              </Table.Cell>
                            </Table.Row>
                            {/* {QnARowPayment} */}
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                              Promo code {bookingData.promoCode} (VND)
                            </Table.Cell>
                              <Table.Cell className='text-align-right'>
                                {` - ${Number(bookingData.promoPrice).toLocaleString('en')}`}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Sub-Total (VND)
                            </Table.Cell>
                              <Table.Cell className='text-align-right'>
                                {this.calculateSubTotal(bookingData.promoPrice, bookingData.bookingPrice, bookingData.totalExtraFee)}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                VAT (10%)
                            </Table.Cell>
                              <Table.Cell className='text-align-right'>
                                {Number(bookingData.vatPrice).toLocaleString('en')}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell className='tablel-row-title'>
                                Total Payment (VND)
                            </Table.Cell>
                              <Table.Cell className='text-align-right'>
                                {Number(bookingData.total).toLocaleString('en')}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      )
    } else {
      return ''
    }
  }

  render() {
    let contents = this.state.loading
      ? <Container fluid>
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      </Container>
      : this.renderTourInformation(this.state.bookingData)

    return (
      <div key='adm-edit-booking-screen' className='edit-booking-screen'>
        <Form onSubmit={this.updateBooking}>
          <Grid divided='vertically'>
            <Grid.Row className='control-area-row'>
              <Grid.Column>
                <Button positive>Save booking</Button>
              </Grid.Column>
            </Grid.Row>
            {contents}
          </Grid>
        </Form>
        <Modal size={'mini'} open={this.state.hasErrorAlert} onClose={this.closeErrorModal} className='alert-message-area' closeOnDimmerClick={false}>
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <p>{this.state.errorMessage}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={this.closeErrorModal}>OK</Button>
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}
