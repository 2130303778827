import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LayoutAdmin } from "../../components/Admin/LayoutAdmin";
import { authenticationService } from "../../_services/authentication.service";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        authenticationService.logout();
        return (
          <Redirect
            to={{ pathname: "/Admin/Login", state: { from: props.location } }}
          />
        );
      }

      if (
        currentUser.role === undefined ||
        currentUser.role === null ||
        currentUser.role === "" ||
        currentUser.role !== "ADMIN"
      ) {
        authenticationService.logout();
        return (
          <Redirect
            to={{ pathname: "/Admin/Login", state: { from: props.location } }}
          />
        );
      }

      // authorised so return component
      //return <Component {...props} />
      return (
        <LayoutAdmin>
          <Component {...props} />
        </LayoutAdmin>
      );
    }}
  />
);
