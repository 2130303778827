import React, { Component } from 'react'
import { Table, Button, Label } from 'semantic-ui-react'
import Moment from 'react-moment';

export default class CollaboratorCommissionItem extends Component {

  openConfirm = (event, collaboratorCommissionId) => {
    event.preventDefault()
    this.props.openConfirm(collaboratorCommissionId)
  }

  renderStatus = (status) => {
    if (status === 'Paid') {
      return (
        <Label color='green'>{status}</Label>
      )
    } else if (status === 'Pending') {
      return (
        <Label basic color='grey'>{status}</Label>
      )
    }
    else if (status === 'Cancel') {
      return (
        <Label color='Grey'>{status}</Label>
      )
    }
    else {
      return ''
    }
  }

  render() {
    let { id, dataItem, index } = this.props
    let paymentCommissionDate = dataItem.paymentCommissionDate !== undefined && dataItem.paymentCommissionDate !== null && dataItem.paymentCommissionDate !== ''
      ? new Date(dataItem.paymentCommissionDate)
      : ''    

    return (
      <Table.Row key={id}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>{dataItem.collaborator.name}</Table.Cell>  
        <Table.Cell>{dataItem.collaborator.email}</Table.Cell>      
        <Table.Cell>{dataItem.collaborator.phone}</Table.Cell>
        <Table.Cell>{dataItem.collaborator.accountName}</Table.Cell>
        <Table.Cell>{dataItem.collaborator.bankNumber}</Table.Cell>
        <Table.Cell>{dataItem.collaborator.bankName}</Table.Cell>
        <Table.Cell>{dataItem.collaborator.bankDepartment}</Table.Cell>
        <Table.Cell textAlign='right'>{Number(dataItem.commission).toLocaleString('en')}</Table.Cell>        
        <Table.Cell>{paymentCommissionDate === null ? <></> : <Moment format="MMM DD, YYYY hh:mm A" date={paymentCommissionDate} />}</Table.Cell>    
        <Table.Cell>{dataItem.paymentCommissionNote}</Table.Cell>
        <Table.Cell textAlign='center'>{this.renderStatus(dataItem.status)}</Table.Cell>
        <Table.Cell className='wid-per-5' textAlign='center'>
          <Button.Group primary size='tiny'>            
            <Button key={`mark-paid-${id}`} icon='clipboard check' data-tooltip="Mark as Paid" id={`mark-paid-${id}`} onClick={(e) => this.openConfirm(e, id)} />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    )
  }
}
